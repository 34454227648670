import { Injectable } from '@angular/core';

import { CommsService } from '@services/comms/comms.service';
import { Module, SubscriberService } from '@services/subscriber/subscriber.service';
import { UserdataService } from '@services/userdata/userdata.service';
import {
  cloneDeep,
  each,
  every,
  filter,
  find,
  findLast,
  get,
  has,
  includes,
  intersection,
  isEmpty,
  isUndefined,
  reject,
  uniq,
  values
} from 'lodash';
import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';
import { ObjectItem, UpdateObjectParams, UpdateMultipleObjectParams, ObjectLifecycle, ObjectPhase, ObjectPhaseRule, ObjectState, ObjectLifecycleCollection, ObjectTransition } from './objects.interfaces';
import { Permission } from '@services/permissions/permissions.service';


import { NGXLogger } from 'ngx-logger';


@Injectable({
  providedIn: 'root'
})
export class ObjectsService {
  public filterObject: any = {};
  private objectsByType: IObjectStringKeyMap<IObjectStringKeyMap<ObjectItem>> = {};
  private lastRequest: number;

  private lifecycles: {
    data: IObjectStringKeyMap<ObjectLifecycleCollection>,
    lastUpdate: number | null;
  } = {
      data: {
        [ObjectLifecycle.Review]: {
          modules: [ Module.CONTENT, Module.CONTENT_REVIEW],
          usesRevision: true,
          restrictions: {
            // only a superadmin can archive
            [ObjectTransition.Archive]: {
              permissions: [
                Permission.SuperAdmin
              ]
            }
          },
          phases:
          {
            [ObjectPhase.Draft]: {
              description: 'CONTENT.Draft',
              type: ObjectState.Inactive,
              transitions: [
                ObjectTransition.Archive,
                ObjectTransition.StartReview
              ]
            },
            [ObjectPhase.InReview]:
            {
              description: 'CONTENT.InReview',
              type: ObjectState.Inactive,
              transitions: [
                ObjectTransition.Reject,
                ObjectTransition.Approve,
                ObjectTransition.Withdraw,
                ObjectTransition.Archive
              ]
            },
            [ObjectPhase.Rejected]:
            {
              description: 'CONTENT.Rejected',
              type: ObjectState.Deleted,
              transitions: [
                ObjectTransition.Archive
              ]
            },
            [ObjectPhase.Approved]:
            {
              description: 'CONTENT.Approved',
              type: ObjectState.Inactive,
              transitions: [
                ObjectTransition.Schedule,
                ObjectTransition.Publish,
                ObjectTransition.Withdraw,
                ObjectTransition.Archive
              ]
            },
            [ObjectPhase.Pending]:
            {
              description: 'CONTENT.Pending',
              type: ObjectState.Inactive,
              transitions: [
                ObjectTransition.Publish,
                ObjectTransition.Withdraw,
                ObjectTransition.Archive
              ]
            },
            [ObjectPhase.Published]:
            {
              description: 'CONTENT.Published',
              type: ObjectState.Active,
              transitions: [
                ObjectTransition.Supersede,
                ObjectTransition.Withdraw,
                ObjectTransition.Deprecate,
                ObjectTransition.Archive
              ]
            },
            [ObjectPhase.Deprecated]:
            {
              description: 'CONTENT.Deprecated',
              type: ObjectState.Active,
              transitions: [
                ObjectTransition.Withdraw,
                ObjectTransition.Supersede,
                ObjectTransition.Archive
              ]
            },
            [ObjectPhase.Superseded]:
            {
              description: 'CONTENT.Superseded',
              type: ObjectState.Inactive,
              transitions: [
                ObjectTransition.Archive
              ]
            },
            [ObjectPhase.Withdrawn]:
            {
              description: 'CONTENT.Withdrawn',
              type: ObjectState.Inactive,
              transitions: [
                ObjectTransition.Archive
              ]
            },
          },
          transitions:
          {
            "0": {
              description: 'CONTENT.Add_Revision',
              target: ObjectPhase.Draft
            },
            [ObjectTransition.StartReview]:
            {
              description: 'CONTENT.Start_Review',
              target: ObjectPhase.InReview
            },
            [ObjectTransition.Approve]: {
              description: 'CONTENT.Approve',
              target: ObjectPhase.Approved
            },
            [ObjectTransition.Reject]: {
              description: 'CONTENT.Reject',
              target: ObjectPhase.Rejected
            },
            [ObjectTransition.Schedule]: {
              description: 'CONTENT.Schedule_Publication',
              target: ObjectPhase.Pending
            },
            [ObjectTransition.Publish]: {
              description: 'CONTENT.Publish',
              target: ObjectPhase.Published
            },
            [ObjectTransition.Supersede]: {
              description: 'CONTENT.Supersede',
              target: ObjectPhase.Superseded
            },
            [ObjectTransition.Withdraw]: {
              description: 'CONTENT.Withdraw',
              target: ObjectPhase.Withdrawn
            },
            [ObjectTransition.Deprecate]: {
              description: 'CONTENT.Deprecate',
              target: ObjectPhase.Deprecated
            },
            [ObjectTransition.Archive]: {
              description: 'CONTENT.Archive',
              target: ObjectPhase.Archived
            },
          }
        },
        [ObjectLifecycle.Basic]: {
          modules: [ Module.CONTENT ],
          usesRevision: false,
          restrictions: {
            // only a superadmin can archive
            [ObjectPhase.Archived]: {
              permissions: [
                Permission.SuperAdmin
              ]
            }
          },
          transitions: {
            0: {
              description: 'CONTENT.Add_Item',
              target: ObjectPhase.Inactive
            },
            [ObjectTransition.Activate]:
            {
              description: 'CONTENT.Activate',
              target: ObjectPhase.Active
            },
            [ObjectTransition.Deactivate]: {
              description: 'CONTENT.Deactivate',
              target: ObjectPhase.Inactive
            },
            [ObjectTransition.Archive]: {
              description: 'CONTENT.Archive',
              target: ObjectPhase.Archived
            }
          },
          phases: {
            [ObjectPhase.Inactive]:
            {
              description: 'CONTENT.Inactive',
              type: ObjectState.Inactive,
              transitions: [
                ObjectTransition.Activate,
                ObjectTransition.Archive
              ]
            },
            [ObjectPhase.Active]:
            {
              description: 'CONTENT.Active',
              type: ObjectState.Active,
              transitions: [
                ObjectTransition.Deactivate,
                ObjectTransition.Archive
              ]
            },
          },
        },
      },
      lastUpdate: Date.now()
    };

  constructor(
    private comms: CommsService,
    private subscriber: SubscriberService,
    private userdataService: UserdataService,
    private events: Events,
    private logger: NGXLogger
  ) { }

  /**
   *
   * @param objectID  the ID of the object from the object_map table
   * @param thumbnail whether or not to request the thumbnail version
   *
   */
  public URI(objectID: number, thumbnail: boolean = false, oldStyle: boolean = false) {
    let cmd = '';
    const token = this.comms.token || 'none';
    let ret = '';

    if (oldStyle) {
      cmd = this.comms.serviceURL.replace('corvex.cgi', 'getObject.cgi');

      ret = cmd +
        '?cmd=getObject&token=' + this.comms.token +
        '&subscriberID=' + this.subscriber.subInfo.subscriberID +
        '&objectID=' + objectID;

      if (thumbnail) {
        ret += '&thumb=1';
      }
    } else {
      cmd = this.comms.serviceURL.replace('api/v2', '');
      ret = `${cmd}objects/${this.subscriber.subInfo.subscriberID}/${token}/${objectID}`;
      if (thumbnail) {
        ret += '/thumb';
      }
    }
    return ret;
  }

  public removeObject(id, revision?: string) {
    const fData = { cmd: 'deleteObject', objectID: id };
    if (!isUndefined(revision)) {
      fData[revision] = revision;
    }
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public refresh(): Promise<IObjectStringKeyMap<ObjectItem[]>> {
    const requestData = {
      cmd: 'getObjectList',
      lastRequest: this.lastRequest,
      types: ['content'],
      states: JSON.stringify(['active', 'inactive']),
      includeDisabled: false,
      incremental: 1
    };

    return this.comms.sendMessage(requestData, false, false).then((data) => {
      const objects = get(data, 'result.objects') || [];

      if (data?.reqStatus === 'OK') {
        this.updateCache(data);
      }

      return objects;
    });
  }

  public updateCache(data) {
    each(data?.result?.objects, (object: ObjectItem) => {
      if (!this.objectsByType[object.type]) {
        this.objectsByType[object.type] = {};
      }
      this.objectsByType[object.type][object.objectID] = object;
    });

    each(data?.result?.removed, (object: ObjectItem) => {
      const targetObject = this.getObjectById(object.objectID);

      if (targetObject) {
        delete this.objectsByType[targetObject.type][targetObject.objectID];
      }
    });

    this.lastRequest = data.result.timestamp;
    this.events.publish('ccs:objectsUpdate', true);
  }

  public getObjectList(types, includeDisabled, filters?: any) {
    const fData = {
      cmd: 'getObjectList',
      types: JSON.stringify(types || []),
      includeDisabled,
      ...filters
    };

    return this.comms.sendMessage(fData).then((data) => {
      if (data?.reqStatus === 'OK') {
        this.updateCache(data);
      }
      return get(data, 'result.objects') || [];
    });
  }

  public updateObject(params: UpdateObjectParams | UpdateMultipleObjectParams): Promise<any> {
    const requestParams = {
      cmd: 'updateObject',
      ...params
    };

    return this._updateAndRefresh(requestParams, () => this.refresh());
  }

  public getCachedObjectsByType(group: string, translated?: boolean): IObjectStringKeyMap<ObjectItem> {
    let ret = this.objectsByType?.[group];
    if (ret) {
      if (translated) {
        let translatedRet: IObjectStringKeyMap<ObjectItem> = {};
        const userLang = this.userdataService.getLanguage();
        each(ret, item => {
          const translatedLanguage: any = findLast(get(item, 'translations.objects'), { language: userLang });
          if (translatedLanguage) {
            translatedRet[item.objectID] = translatedLanguage;
          } else {
            translatedRet[item.objectID] = item;
          }
        });
        ret = translatedRet;
      }
    }
    return ret;
  }

  public getCachedObjectById(id: number, alias?: string, translated?: boolean): ObjectItem {
    let object: ObjectItem;

    if (alias) {
      object = this.objectsByType?.[alias]?.[id];
    } else {
      object = this.getObjectById(id);
    }

    if (translated) {
      const translatedLanguage: any = findLast(get(object, 'translations.objects'), { language: this.userdataService.getLanguage() });

      if (translatedLanguage) {
        object = this.getCachedObjectById(translatedLanguage.value, alias);
      }
    }

    return object;
  }

  public getCachedObjectByIds(ids: number[], onlyActive?: boolean): ObjectItem[] {
    const items: ObjectItem[] = [];

    each(uniq(ids), (id) => {
      const item = this.getObjectById(id);

      if (onlyActive && get(item, 'state') === 'active' || !onlyActive) {
        items.push(item);
      }
    });

    return items;
  }

  public getCachedObjectByAlias(alias: string): ObjectItem[] {
    return cloneDeep(values(this.objectsByType[alias])) || [];
  }

  public getIconByType(mediaType: string): string {
    return this.getIconByBaseType(this.getObjectType(mediaType));
  }

  public getIconByBaseType(baseType: string = 'other'): string {
    const iconMap = {
      pdf: 'pdf.svg',
      image: 'image.svg',
      video: 'video.svg',
      audio: 'audio.svg',
      word: 'word-file-type-svgrepo-com.svg',
      excel: 'excel-file-type-svgrepo-com.svg',
      ppt: 'ppt-file-type-svgrepo-com.svg',
      html: 'html-file-type-svgrepo-com.svg',
      other: 'other-file-type-svgrepo-com.svg',
      csv: 'text/csv'
    };

    return iconMap[baseType] ? `assets/icons/media_types/${iconMap[baseType]}` : null;
  }

  public getObjectType(mediaType: string) {
    let type: 'image' | 'pdf' | 'video' | 'audio' | 'word' | 'excel' | 'ppt' | 'csv' | 'other' = 'other';

    const map: any = {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
      'application/vnd.ms-excel': 'excel',
      'application/xhtml+xml': 'html',
      'application/html': 'html',
      'text/html': 'html',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
      'application/msword': 'word',
      'text/csv': 'csv',
      'application/pdf': 'pdf'
    };

    if (includes(mediaType, 'video/')) {
      type = 'video';
    } else if (includes(mediaType, 'audio/')) {
      type = 'audio';
    } else if (includes(mediaType, 'image/')) {
      type = 'image';
    } else {
      if (has(map, mediaType)) {
        type = map[mediaType];
      }
    }
    return type;
  }

  public filterData(data) {
    data = reject(data, 'translationOf');

    if (isEmpty(this.filterObject)) {
      return data;
    } else {
      return filter(data, (dataItem) => this.checkFilter(dataItem));
    }
  }

  public getActiveLifecyles(): ObjectLifecycleCollection[] {
    const ret = [];
    each(this.lifecycles.data, (item, name) => {
      if (item?.modules) {
        let matched = 1;
        each(item.modules, module => {
          if (!this.subscriber.usesModule(module, true)) {
            matched = 0;
          }
        });
        if (matched) {
          ret.push(item);
        }
      } else {
        ret.push(item);
      }
    });
    return ret;
  }

  public getLifecycle(name: ObjectLifecycle, checkActive: boolean = false): ObjectLifecycleCollection {
    if (has(this.lifecycles.data, name)) {
      const item = this.lifecycles.data[name];
      if (checkActive) {
        let active = 1;
        if (item?.modules) {
          each(item.modules, module => {
            if (!this.subscriber.usesModule(module, true)) {
              active = 0;
            }
          });
        }
        if (active) {
          return item;
        }
      } else {
        return item;
      }
    } else {
      return;
    }
  }

  public getPhaseRule(name: ObjectLifecycle, phase: ObjectPhase) {
    const lc = this.getLifecycle(name);
    if (lc) {
      const thisPhase = get(lc.phases, phase);
      if (thisPhase) {
        return thisPhase;
      }
    }
  }

  public getTransition(name: ObjectLifecycle, transition: ObjectTransition) {
    const lc = this.getLifecycle(name);
    if (lc) {
      const thisTransition = get(lc.transitions, transition);
      if (thisTransition) {
        return thisTransition;
      }
    }
  }

  /**
   * getActions - return a list of action IDs and descriptions for use in menus
   *
   * @param name an optional lifecycle name.  If omitted, returns a list of actions for all active lifecycles.
   */
  public getActions(name?: ObjectLifecycle): any {
    let ret = [];
    const lifecycles = name ? [ this.getLifecycle(name, true) ] : this.getActiveLifecyles();
    each(lifecycles, cycle => {
      const t = cycle.transitions;
      each(t, (rule, name) => {
        if (!find(ret, { id: name } ) ) {
          ret.push({ id: name, description: rule.description });
        }
      });
    });
    return ret;
  }

  /**
   * getPhases - return a list of action IDs and descriptions for use in menus
   *
   * @param name an optional lifecycle name.  If omitted, returns a list of phases for all active lifecycles.
   */
  public getPhases(name?: ObjectLifecycle): any {
    let ret = [];
    const lifecycles = name ? [ this.getLifecycle(name, true) ] : this.getActiveLifecyles();
    each(lifecycles, cycle => {
      const t = cycle.phases;
      each(t, (rule, name) => {
        if (!find(ret, { id: name } ) ) {
          ret.push({ id: name, description: rule.description });
        }
      });
    });
    return ret;
  }

  private checkFilter(dataItem: any): boolean {
    const keyMap = {
      permissions: 'permissionLevel',
      locations: 'locations',
      roles: 'roles',
      shifts: 'shift',
      certifications: 'certifications'
    };

    return every(keyMap, (filterKey: string, dataKey: string) => {
      const filter = this.filterObject[filterKey];
      const dataByKey = dataItem[dataKey];
      const matched = intersection(filter, dataByKey).length > 0;
      const dataIsEmpty = dataByKey.length === 0;

      return filter?.length ? (dataIsEmpty || matched) : true;
    });
  }

  private getObjectById(id: number): ObjectItem {
    let object: ObjectItem;

    each(this.objectsByType, (objectsByType) => {
      if (objectsByType[id]) {
        object = objectsByType[id];
        return;
      }
    });

    return cloneDeep(object);
  }

  private _updateAndRefresh(fData, updateHandler): Promise<any> {
    const cmdOpts = cloneDeep(fData);

    return new Promise((resolve, reject) => {
      this.comms.sendMessage(cmdOpts, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            updateHandler()
              .then((ores) => {
                resolve(data);
              })
              .catch((oerr) => {
                this.logger.error('update failed: ' + oerr);
              });
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          this.logger.error('update failed: ' + err);
          reject(err);
        });
    });
  }
}
