import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, ElementRef } from '@angular/core';

import { BaseDetailsPage } from './../abstractDetails/abstractBaseDetails.page';
import {
  CertificationsService,
  FormBuilderService,
  FormField,
  LoadingService,
  ObjectsService,
  PermissionsService,
  PopoverService,
  RolesService,
  SettingsService,
  ShiftService,
  UserdataService,
  UserService,
  UtilsService,
  FilterElementService,
  ObjectItem
} from '@services';
import { awaitHandler } from '@utils/awaitHandler';
import { TranslateService } from '@ngx-translate/core';
import { IObjectStringKeyMap } from '@shared/models';
import { cloneDeep, each, filter, find, flatten, get, has, includes, isArray, isEmpty, isNumber, map, sortBy } from 'lodash';

@Component({
  selector: 'app-content',
  templateUrl: './content.page.html',
  styleUrls: ['./content.page.scss'],
})
export class ContentPage extends BaseDetailsPage {

  public title: string = this.translate.instant('MGMT_DETAILS.Add_Item');
  protected routeIdParameterName = 'id';
  protected newForm: any = {
    id: 'contentAddForm',
    cancelButton: 'contentAddCancel',
    saveButton: 'contentAddSave'
  };
  protected editForm: any = {
    id: 'contentEditForm',
    cancelButton: 'contentEditCancel',
    saveButton: 'contentEditSave',
    deleteButton: 'contentEditDelete'
  };
  private locations = this.userService.getUserLocations(this.userDataService.locations, false);
  private objectData: ObjectItem;
  private mediaConfig = {
    options: {
      dynamicFormData: () => {
        const contentField: any = find(this.formConfig.fields, {name: 'contentObject'});
        return contentField.mediaConfig.options.relatedData;
      },
      relatedData: {
        originalData: null,
        mediaType: null
      },
      formData: {
        type: 'content',
        subtype: null
      },
      allowedTypes: "*",
      acceptFiles: '',
      // acceptFiles: this.imageUploaderService.convertTypesToString({
      //   image: ['gif', 'jpeg', 'jpg', 'png'],
      //   application: ['pdf'],
      //   audio: ['mp4', 'm4a'],
      //   video: ['mp4']
      // })
      onSelect: (files) => {
        // get the info about the selected file(s) kk
        if (files?.length) {
          let name = files[0]?.name;
          if (name) {
            // this is the original name of the file - send that along
              const contentField: any = find(this.formConfig.fields, {name: 'contentObject'});
              contentField.mediaConfig.options.relatedData.originalName = name;
              contentField.mediaConfig.options.relatedData.mediaType = files[0].type;

              if (this.messageID) {
                this.formBuilderService.markFieldAsChanged(`#${this.editForm.id}`, 'contentObject', '', name);
              }
          }
        }
      }
    },
    events: {
      onSuccess: (data) => {
        this.toggleFormControls();
        this.objectData = data;
        this.customValidate.validateFailed('success');

        if (!this.messageID) {
          const field = find(this.formConfig.fields, {name: 'translation_contentObject'});
          this.formBuilderService.updateFields(this.formConfig, [field], data, this.formId);
        }
      },

      onError: () => {
        this.toggleFormControls();
        this.customValidate.validateFailed('error');
      }
    }
  };
  protected formConfig: any = {
    autocomplete: false,
    canClear: true,
    containers: true,
    prefix: 'contentAdd',
    del: null,
    save: this.translate.instant('MGMT_DETAILS.Add_Item'),
    cancel: this.translate.instant('SHARED.Cancel'),
    fields: [
      {
        required: true,
        inputtype: 'verbatim',
        name: 'description',
        title: this.translate.instant('MGMT_DETAILS.Title'),
        type: 'text',
        size: 20
      },
      {
        inputtype: 'verbatim',
        name: 'translation_description',
        title: this.translate.instant('MGMT_DETAILS.Title_Translation'),
        type: 'textTranslations',
        size: 20,
      },
      {
        required: true,
        name: 'contentObject',
        title: this.translate.instant('CONTENT.Module_Name'),
        type: 'media',
        extensions: ['image', 'pdf', 'audio', 'video', 'other'],
        mediaConfig: cloneDeep(this.mediaConfig),
        multiple: false,
        addButton: this.translate.instant('SHARED.Upload_Image'),
        updateButton: this.translate.instant('SHARED.Replace_Image'),
        imageSize: '150px',
        hideFieldsOnValue: {
          select: [
            {
              hideClasses: ['translation-object'],
              valueFunction: (val) => !val
            }
          ]
        }
      },
      {
        containerClass: 'translation-object',
        name: 'translation_contentObject',
        title: this.translate.instant('MGMT_DETAILS.Content_Translation'),
        type: 'mediaTranslations',
        extensions: ['image', 'pdf', 'audio', 'video', 'other'],
        mediaConfig: Object.assign({}, this.mediaConfig, {
          events: {
            onSuccess: () => this.toggleFormControls(),
            onError: () => this.toggleFormControls(),
            onSubmit: () => this.toggleFormControls(false),
            onRemove: async (id: number) => {
              if (!this.messageID) {
                this.loadingService.enable();
                await awaitHandler(this.objectsService.removeObject(id));
                this.loadingService.disable();
              }
            },
          }
        }),
        multiple: false,
        addButton: this.translate.instant('SHARED.Upload_Image'),
        updateButton: this.translate.instant('SHARED.Replace_Image'),
        imageSize: '150px'
      },
      {
        title: this.translate.instant('SHARED.Tags'),
        name: 'tags',
        type: 'selectmenu',
        multiple: true,
        tags: true,
        options: [],
        canClear: false,
        valueProperty: 'tagID',
        func: (item: any) => item.tag,
        placeholder: this.translate.instant('SHARED.Add_Tags')
      },
      {
        name: 'state',
        title: this.translate.instant('MGMT_DETAILS.Content_available'),
        type: 'flipswitch',
        value: 1,
        default: 1
      },
      {
        labelClass: 'body-copy-bold',
        title: 'MGMT_DETAILS.Limit_Content_Access',
        name: 'limit_access',
        type: 'flipswitch',
        onText: this.translate.instant('SHARED.On'),
        offText: this.translate.instant('SHARED.Off'),
        value: 1,
        hideFieldsOnValue: {
          select: [{
            alwaysHide: true,
            hideClasses: ['access'],
            value: 0,
            // valueFunction: (val) => {
            //   return val.length === 0;
            // }
            showClassesFunc: (value) => {
              const showClasses = [];
              if (value) {
                showClasses.push('access');
              }

              return showClasses;
            }
          }]
        }
      },
      {
        containerClass: 'access',
        title: this.translate.instant('SHARED.Locations'),
        name: 'locations',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Locations'),
        multiple: true,
        valueProperty: 'locationID',
        options: this.locations,
        func: (ref) => ref.name,
        onChange: () => this.onLocationChange()
      },
      {
        containerClass: 'access',
        title: this.translate.instant('SHARED.TEAMS'),
        name: 'teams',
        type: 'selectmenu',
        placeholder: this.translate.instant('SHARED.All_Teams'),
        multiple: true,
        originalOrder: true,
        options: []
      },
      {
        containerClass: 'access',
        name: 'shifts',
        title: this.translate.instant('SHARED.Shift'),
        type: 'selectmenu',
        placeholder: this.translate.instant('OTable.All_Shifts'),
        multiple: true,
        originalOrder: true,
        options: []
      },
      {
        containerClass: 'access',
        name: 'roles',
        title: this.translate.instant('LAYOUT.Roles'),
        placeholder: this.translate.instant('SHARED.All_Roles'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'roleID',
        options: 'this.roleService.roles',
        test: (ref) => !get(ref, 'disabledAt'),
        func: (role: any) => this.roleService.roleName(role)
      },
      {
        containerClass: 'access',
        name: 'permissions',
        title: this.translate.instant('MGMT_DETAILS.Permissions'),
        placeholder: this.translate.instant('SHARED.All_Permissions'),
        type: 'selectmenu',
        multiple: true,
        options: cloneDeep(this.permissionService.permissions),
        test: (ref) => {
          ref.description = this.translate.instant(ref.description);
          if (ref.id === 'corvex') {
            return get(this.userDataService.Permissions, 'corvex');
          } else if (ref.id === 'sadmin') {
            return get(this.userDataService.Permissions, 'sadmin') || get(this.userDataService.Permissions, 'corvex');
          } else {
            return true;
          }
        }
      },
      {
        containerClass: 'access',
        title: this.translate.instant('SHARED.Certifications'),
        name: 'certifications',
        placeholder: this.translate.instant('SHARED.All_Certifications'),
        type: 'selectmenu',
        multiple: true,
        valueProperty: 'certificationID',
        options: this.certificationsService.certifications,
        func: (certification) => this.formBuilderService.certificationName(certification),
        test: (certification) => !get(certification, 'disabledAt')
      },
    ]
  };

  constructor(
    protected route: ActivatedRoute,
    protected formBuilderService: FormBuilderService,
    protected elementRef: ElementRef,
    protected popoverService: PopoverService,
    protected location: Location,
    protected loadingService: LoadingService,
    protected translate: TranslateService,
    private settingsService: SettingsService,
    private userDataService: UserdataService,
    private objectsService: ObjectsService,
    private userService: UserService,
    private roleService: RolesService,
    private permissionService: PermissionsService,
    private shifts: ShiftService,
    private certificationsService: CertificationsService,
    private utils: UtilsService,
    private filterService: FilterElementService
  ) {
    super(route, formBuilderService, elementRef, popoverService, location, loadingService, translate);
  }

  protected deleteHandler: any = () => this.objectsService.removeObject(this.objectData.objectID, this.objectData?.revision);

  protected updateHandler: any = async (formData: any) => {
    await awaitHandler(this.savePendingAttachments());
    await this.settingsService.encodeTags(formData, 'tags');
    return this.updateObject(formData);
  };

  protected addHandler: any = async (formData: any) => {
    await this.settingsService.encodeTags(formData, 'tags');
    return this.settingsService.handleNewTagsByForm(formData, (data) => this.updateObject(data));
  };

  protected getData() {
    const folderID = +get(this.route.snapshot.queryParams, 'folderID');
    let data: any = { folderID };

    if (this.messageID) {
      this.objectData = <any>this.objectsService.getCachedObjectById(+this.messageID, 'content');
      data = this.objectData;

      if (data.objectID) {
        data.contentObject = data.objectID;
      }

      if (!isNumber(data.state)) {
        data.state = data.state === 'active' ? 1 : 0;
      }

      this.utils.decodeTranslations(this.objectData, ['translation_description'], ['description', 'translation_contentObject']);
      this.decodeTranslationsObjects();

      let limited = 0;
      each(['permissions', 'locations', 'roles', 'certifications', 'shifts', 'teams'], (key) => {
        if (has(data, key) && isArray(data[key]) && data[key].length)  {
          limited = 1;
        }
      });
      data.limit_access = limited;
    }

    return data;
  }

  protected prepareFormConfig(): void {
    if (this.messageID) {
      this.title = this.translate.instant('MGMT_DETAILS.Edit_Item');

      this.formConfig.prefix = 'contentEdit';
      this.formConfig.save = this.translate.instant('SHARED.Save_Changes');
      this.formConfig.del = this.translate.instant('MGMT_DETAILS.Delete_Content');

      this.deletePopoverConfig.title = this.translate.instant('MGMT_DETAILS.Delete_Content');
      this.deletePopoverConfig.description = this.translate.instant('MGMT_DETAILS.Content_Delete_Popover_Description');

      this.updatePopoverConfig.title = this.translate.instant('MGMT_DETAILS.Update_Content');
      this.updatePopoverConfig.description = this.translate.instant('MGMT_DETAILS.Content_Update_Popover_Description');

      this.enableLocalMediaMode('contentObject');
      this.enableLocalMediaMode('translation_contentObject');
    }
  }

  protected async prepareDataAsync(): Promise<any> {
    const customTagsHandler: Promise<any> = isEmpty(this.settingsService.customTags.data) ?
      this.settingsService.getCustomTags() : Promise.resolve();
    const objectsHandler: Promise<any> = isEmpty(this.objectsService.getCachedObjectByAlias('content')) ?
      this.objectsService.getObjectList(['content'], false) : Promise.resolve();

    await this.loadingService.enable();
    await awaitHandler(Promise.all([customTagsHandler, objectsHandler]));
    this.loadingService.disable();

    const tagField: any = find(this.formConfig.fields, {name: 'tags'});
    tagField.options = sortBy(this.settingsService.customTags.data, 'tag');

    const translationContentObjectField = find(this.formConfig.fields, {name: 'translation_contentObject'});
    const contentField = find(this.formConfig.fields, {name: 'contentObject'});

    if (this.messageID) {
      this.objectData = this.objectsService.getCachedObjectById(+this.messageID, 'content') || {} as ObjectItem;

      if (this.objectData.objectID) {
        contentField.mediaConfig.options.formData.updates = this.objectData.objectID;
        translationContentObjectField.mediaConfig.options.formData.folderID = this.objectData.folderID;
      }
    } else {
      translationContentObjectField.mediaConfig.options.formData.folderID = +get(this.route.snapshot.queryParams, 'folderID');
      translationContentObjectField.mediaConfig.options.formData.state = 'inactive';
      contentField.mediaConfig.options.formData.state = 'inactive';
    }
  }

  protected onFinish(): void {
    this.onLocationChange();
  }

  private updateObject(formData) {
    this.prepareFormData(formData);
    return this.objectsService.updateObject(formData);
  }

  private enableLocalMediaMode(fieldName: string): void {
    const targetField = <FormField>find(this.formConfig.fields, {name: fieldName});
    targetField.mediaConfig.options.autoSubmit = false;
    targetField.mediaConfig.options.showCancel = true;
  }

  private prepareFormData(formData): any {
    const folderID = +get(this.route.snapshot.queryParams, 'folderID');
    const convertArrayToJSON = (key: string): void => {
      const items = map(flatten([formData[key]]), (arrayItem: string | number) => isNaN(+arrayItem) ? arrayItem : +arrayItem);
      formData[key] = JSON.stringify(filter(items));
    };

    this.utils.encodeTranslations(formData, ['translation_description'], ['description']);

    formData.type = 'content';
    formData.objectID = this.objectData.objectID;
    formData.objectUUID = this.objectData.objectUUID;
    formData.state = formData.state ? 'active' : 'inactive';
    each(['permissions', 'tags', 'locations', 'roles', 'certifications', 'shifts', 'teams'], (key) => convertArrayToJSON(key));
    delete formData.tagIDs;

    if (folderID) {
      formData.folderID = folderID;
    }

    return formData;
  }

  private onLocationChange(): void {
    const locationField = <any>find(this.formConfig.fields, {name: 'locations'});
    let locations = filter(map(locationField.getValue(), Number));

    if (isEmpty(locations)) {
      locations = map(this.locations, 'locationID');
    }

    this.updateShiftsBy(locations);
    this.updateTeamsBy(locations);
  }

  private updateTeamsBy(locationsIds: number[]) {
    const fieldName = 'teams';
    const fieldId = `#${this.formConfig.prefix}${fieldName}`;
    const fieldConfig = <any>find(this.formConfig.fields, {name: fieldName});
    const selection: string[] | string = <string[] | string>fieldConfig.getValue();
    const fieldData: any = {};
    if (fieldConfig.multiple) {
      fieldData[fieldName] = isArray(selection) ? map(selection, Number) : [+selection];
    } else {
      fieldData[fieldName] = selection;
    }
    fieldConfig.options = this.filterService.buildTeamMenu(locationsIds, false).dropDownOptions;
    this.formBuilderService.replaceSelectOptionsWith(fieldId, this.formConfig, fieldConfig, fieldData);
  }

  private updateShiftsBy(locationsIds: number[]) {
    const fieldName = 'shifts';
    const fieldId = `#${this.formConfig.prefix}${fieldName}`;
    const fieldConfig = <any>find(this.formConfig.fields, {name: fieldName});
    const selectedIds = fieldConfig.getValue();

    const locations = map(locationsIds, (locationId) => this.userService.getLocation(locationId));
    fieldConfig.options = this.shifts.getSelectMenuObjectsByLocations(locations);
    this.formBuilderService.replaceSelectOptionsWith(fieldId, this.formConfig, fieldConfig, {shifts: selectedIds});
  }

  private decodeTranslationsObjects() {
    each(get(this.objectData, 'translations.objects'), (objectTranslation: IObjectStringKeyMap<any>) => {
      if (objectTranslation.language) {
        this.objectData[`translation_contentObject_${objectTranslation.language}`] = objectTranslation.value;
      }
    });
  }

  private savePendingAttachments() {
    const mediaFields = filter(this.formConfig.fields, (field) => includes(['media', 'mediaTranslations'], field.type));
    const imageUpdateHandlers: Promise<any>[] = [];

    each(mediaFields, (mediaField) => {
      mediaField?.mediaConfig?.startUpload && mediaField.mediaConfig.startUpload();

      if (mediaField.type === 'mediaTranslations') {
        const data = this.getData();
        each(data?.translations?.objects, (object) => {
          const languageField = find(mediaField.mediaConfig?.languageFieldsReference, (field) => {
            return field?.mediaConfig?.options?.formData?.language === object.language;
          });
          const imageInstance = languageField?.mediaConfig?.imageInstance;
          const fileIsReplaced = (imageInstance?.selectedFiles === 0 && imageInstance?.fileCounter === 1) || imageInstance?.fileCounter > 1;

          if (fileIsReplaced && object?.value) {
            imageUpdateHandlers.push(this.objectsService.removeObject(object.value));
          }
        });
      }
    });

    return Promise.all(imageUpdateHandlers);
  }

}
