import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AccountsService, CertificationsService, Feature, FilterElementService, FoldersDataService, FormBuilderService, FormField, Module, PermissionsService, RolesService, ShiftService, SubscriberService, TeamsService, UserdataService, UserService } from '@services';
import { PropertySubject } from '@services/property/property-model.interfaces';
import { IFolder } from '@modules/management/modules/folders/model/folders.interfaces';
import { ContentListComponent } from '@modules/management/pages/details/content/components';
import { PropertyPickerComponent } from '@modules/management/modules/property-picker/components';
import {
  PropertyPickerConfig,
  SelectedProperty
} from '@modules/management/modules/property-picker/model/property-picker.interfaces';

import { cloneDeep, each, filter, find, get, has, isArray, isEmpty, map, reject } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export enum FolderDataType {
  ASSET = 'asset',
  PROPERTY = 'property',
  NOTIFICATION_RULE = 'notification_rule',
  RELAY = 'relay',
  CONTENT = 'content'
}

@Injectable({
  providedIn: 'root'
})
export class FoldersService {
  private accounts = this.accountService.getUserlist(this.userdataService.locations);
  private locations = this.userService.getUserLocations(this.userdataService.locations, false);

  constructor(
    private navController: NavController,
    private foldersDataService: FoldersDataService,
    private subscriber: SubscriberService,
    private translate: TranslateService,
    private accountService: AccountsService,
    private roleService: RolesService,
    private certificationsService: CertificationsService,
    private permissionService: PermissionsService,
    private userdataService: UserdataService,
    private userService: UserService,
    private filterService: FilterElementService,
    private formBuilderService: FormBuilderService,
    private shifts: ShiftService,
    private teamsService: TeamsService
  ) {}

  public goToFolder(link: string, folderId: number): void {
    this.navController.navigateForward([`pages/management/${link}`, {folderId}], { animated: false });
  }

  public filterDataByType(type: FolderDataType, folderData: IFolder) {
    if (type === FolderDataType.ASSET) {
      const parentProperties = this.getParentIds(folderData);

      if (parentProperties.length && folderData?.selectedProperties?.length) {
        folderData.selectedProperties = reject(folderData.selectedProperties, (property) => {
          if (property.propertyID) {
            return !!find(parentProperties, {propertyID: property.propertyID});
          } else if (property.propertyFolderID) {
            return !!find(parentProperties, {propertyFolderID: property.propertyFolderID});
          }
        });
      }
    }
    if (type === FolderDataType.CONTENT) {
      // load up inheritance data if appropriate
    }
  }

  public getAdditionFields(type: FolderDataType, folderData?: IFolder): FormField[] {
    if (type === FolderDataType.ASSET) {
      return [
        {
          type: 'customElement',
          name: 'selectedProperties',
          component: PropertyPickerComponent,
          module: Module.PROPERTIES,
          inputs: {
            pickerConfig: <PropertyPickerConfig>{
              type: PropertySubject.Asset
            },
            parentIds: this.getParentIds(folderData)
          }
        },
        {
          type: 'customElement',
          name: 'contentItems',
          component: ContentListComponent,
          module: Module.CONTENT
        }
      ];
    }
    if (type === FolderDataType.CONTENT) {
      // if document control is enabled, we need document control attributes
      const ret: any[] = [];
      let disabled = false;
      if (folderData?.parentAttributes) {
        // there is a parent
        if (has(folderData.parentAttributes, 'settings_inherited')) {
          if (folderData.parentAttributes.settings_inherited) {
            disabled = true;
            // ret.push(
            // {
            //   containerClass: 'review type-selector',
            //   title: 'CONTENT.Inherited',
            //   type: 'subtitle',
            // });
          }
        }
      }

      if (folderData?.creator) {
        ret.push(
        {
          labelClass: 'body-copy-bold',
          title: this.translate.instant('CONTENT.Folder_Only_Editable_By_Owner') + ": (" + this.accountService.fullUserName(+folderData?.creator) + ")",
          tooltip: 'CONTENT.Folder_Editable_Description',
          name: 'owner_only',
          type: 'flipswitch',
          onText: this.translate.instant('SHARED.On'),
          offText: this.translate.instant('SHARED.Off'),
          value: 1,
        });
      } else {
        ret.push({
          labelClass: 'body-copy-bold',
          title: this.translate.instant('CONTENT.Folder_Only_Editable_By_Owner') + ": (" + this.accountService.fullUserName(+folderData?.creator) + ")",
          tooltip: 'CONTENT.Folder_Editable_Description',
          name: 'owner_only',
          type: 'flipswitch',
          onText: this.translate.instant('SHARED.On'),
          offText: this.translate.instant('SHARED.Off'),
          value: 1,
        });
      }

      ret.push(
        {
          labelClass: 'body-copy-bold',
          title: 'MGMT_DETAILS.Limit_Content_Access',
          name: 'limit_access',
          type: 'flipswitch',
          onText: this.translate.instant('SHARED.On'),
          offText: this.translate.instant('SHARED.Off'),
          value: 1,
          hideFieldsOnValue: {
            select: [{
              alwaysHide: true,
              hideClasses: ['access'],
              value: 0,
              // valueFunction: (val) => {
              //   return val.length === 0;
              // }
              showClassesFunc: (value) => {
                const showClasses = [];
                if (value) {
                  showClasses.push('access');
                }

                return showClasses;
              }
            }]
          }
        },
        {
          title: this.translate.instant('SHARED.Locations'),
          containerClass: 'access',
          name: 'access_locations',
          type: 'selectmenu',
          placeholder: this.translate.instant('SHARED.All_Locations'),
          multiple: true,
          valueProperty: 'locationID',
          options: this.locations,
          func: (ref) => ref.name,
          onChange: (value, form, formConfig) => this.onAccessLocationChange(formConfig)
        },
        {
          containerClass: 'access',
          title: this.translate.instant('SHARED.TEAMS'),
          name: 'access_teams',
          type: 'selectmenu',
          placeholder: this.translate.instant('SHARED.All_Teams'),
          multiple: true,
          originalOrder: true,
          options: []
        },
        {
          containerClass: 'access',
          name: 'access_shifts',
          title: this.translate.instant('SHARED.Shift'),
          type: 'selectmenu',
          placeholder: this.translate.instant('OTable.All_Shifts'),
          multiple: true,
          originalOrder: true,
          options: []
        },
        {
          containerClass: 'access',
          name: 'access_roles',
          title: this.translate.instant('LAYOUT.Roles'),
          placeholder: this.translate.instant('SHARED.All_Roles'),
          type: 'selectmenu',
          multiple: true,
          valueProperty: 'roleID',
          options: 'this.roleService.roles',
          test: (ref) => !get(ref, 'disabledAt'),
          func: (role: any) => this.roleService.roleName(role)
        },
        {
          containerClass: 'access',
          name: 'access_permissions',
          title: this.translate.instant('MGMT_DETAILS.Permissions'),
          placeholder: this.translate.instant('SHARED.All_Permissions'),
          type: 'selectmenu',
          multiple: true,
          options: cloneDeep(this.permissionService.permissions),
          test: (ref) => {
            ref.description = this.translate.instant(ref.description);
            if (ref.id === 'corvex') {
              return get(this.userdataService.Permissions, 'corvex');
            } else if (ref.id === 'sadmin') {
              return get(this.userdataService.Permissions, 'sadmin') || get(this.userdataService.Permissions, 'corvex');
            } else {
              return true;
            }
          }
        },
        {
          containerClass: 'access',
          title: this.translate.instant('SHARED.Certifications'),
          name: 'access_certifications',
          placeholder: this.translate.instant('SHARED.All_Certifications'),
          type: 'selectmenu',
          multiple: true,
          valueProperty: 'certificationID',
          options: this.certificationsService.certifications,
          func: (certification) => this.certificationsService.certificationName(certification),
          test: (certification) => !get(certification, 'disabledAt')
        });

      if (this.subscriber.usesFeature(Feature.CONTENT_REVIEW)) {
        // when content review is enabled, add attributes for default
        // reviewers, number of required approvals, deadline, and re-review interval
        ret.push(
          {
            labelClass: 'body-copy-bold',
            title: 'CONTENT.Document_Review',
            tooltip: 'CONTENT.Flipswitch_Description',
            disabled,
            name: 'document_review',
            type: 'flipswitch',
            onText: this.translate.instant('SHARED.On'),
            offText: this.translate.instant('SHARED.Off'),
            value: 1,
            hideFieldsOnValue: {
              select: [{
                alwaysHide: true,
                hideClasses: ['review'],
                value: 0,
                // valueFunction: (val) => {
                //   return val.length === 0;
                // }
                showClassesFunc: (value) => {
                  const showClasses = [];
                  if (value) {
                    showClasses.push('review');
                  }

                  return showClasses;
                }
              }]
            }
          },
        {
          contentClass: 'review',
          title: 'CONTENT.Folder_Settings_Inherited',
          tooltip: 'CONTENT.Folder_Inherited_Description',
          name: 'settings_inherited',
          disabled,
          type: 'flipswitch',
          onText: this.translate.instant('SHARED.On'),
          offText: this.translate.instant('SHARED.Off'),
          value: 1,
        },
          {
            title: this.translate.instant('CONTENT.Document_Reviewers') + ": " + this.translate.instant('CONTENT.Number_Required'),
            type: 'number',
            containerClass: 'review',
            name: 'reviewer_count',
            disabled,
            required: true,
            min: 0,
            default: 0
          },
          {
            title: this.translate.instant('CONTENT.Default_Review_Time'),
            type: 'timepicker',
            name: 'review_duration',
            containerClass: 'review',
            disabled,
            required: false,
            searchable: false,
            originalOrder: true,
            min: 0,
            options: [
              {
                id: 'days',
                description: this.translate.instant('SHARED.Days'),
              },
              {
                id: 'weeks',
                description: this.translate.instant('SHARED.Weeks'),
              },
              {
                id: 'months',
                description: this.translate.instant('SHARED.Months'),
              },
              {
                id: 'years',
                description: this.translate.instant('SHARED.Years'),
              }
            ]
          },
          {
            title: this.translate.instant('CONTENT.Default_Review_Interval'),
            type: 'timepicker',
            name: 'review_interval',
            containerClass: 'review',
            disabled,
            required: false,
            searchable: false,
            originalOrder: true,
            min: 0,
            options: [
              {
                id: 'days',
                description: this.translate.instant('SHARED.Days'),
              },
              {
                id: 'weeks',
                description: this.translate.instant('SHARED.Weeks'),
              },
              {
                id: 'months',
                description: this.translate.instant('SHARED.Months'),
              },
              {
                id: 'years',
                description: this.translate.instant('SHARED.Years'),
              }
            ]
          },
      {
        containerClass: 'review type-selector',
        title: 'CONTENT.Document_Reviewers',
        type: 'subtitle',
        name: 'NONE'
      },
          {
            title: this.translate.instant('CONTENT.Document_Reviewers'),
            required: false,
            disabled,
            containerClass: 'review',
            name: 'reviewers',
            type: 'selectmenu',
            multiple: true,
            placeholder: this.translate.instant('SHARED.None'),
            valueProperty: 'userID',
            options: this.accounts,
            test: (ref) => {
              if (ref.disabledAt !== 0 || !ref.active) {
                return false;
              } else {
                return true;
              }
            },
            func: (ref) => this.userService.getFullname(ref.userID)
          },
        {
          containerClass: 'review',
          title: this.translate.instant('SHARED.TEAMS'),
          name: 'review_teams',
          disabled,
          type: 'selectmenu',
          placeholder: this.translate.instant('SHARED.All_Teams'),
          multiple: true,
          originalOrder: true,
          options: this.filterService.buildTeamMenu([], false).dropDownOptions
        },
        {
          containerClass: 'review',
          name: 'review_roles',
          title: this.translate.instant('LAYOUT.Roles'),
          placeholder: this.translate.instant('SHARED.All_Roles'),
          type: 'selectmenu',
          disabled,
          multiple: true,
          valueProperty: 'roleID',
          options: 'this.roleService.roles',
          test: (ref) => !get(ref, 'disabledAt'),
          func: (role: any) => this.roleService.roleName(role)
        },
        {
          containerClass: 'review',
          name: 'review_permissions',
          title: this.translate.instant('MGMT_DETAILS.Permissions'),
          placeholder: this.translate.instant('SHARED.All_Permissions'),
          type: 'selectmenu',
          disabled,
          multiple: true,
          options: cloneDeep(this.permissionService.permissions),
          test: (ref) => {
            ref.description = this.translate.instant(ref.description);
            if (ref.id === 'corvex') {
              return get(this.userdataService.Permissions, 'corvex');
            } else if (ref.id === 'sadmin') {
              return get(this.userdataService.Permissions, 'sadmin') || get(this.userdataService.Permissions, 'corvex');
            } else {
              return true;
            }
          }
        },
        {
          containerClass: 'review',
          title: this.translate.instant('SHARED.Certifications'),
          disabled,
          name: 'review_certifications',
          placeholder: this.translate.instant('SHARED.All_Certifications'),
          type: 'selectmenu',
          multiple: true,
          valueProperty: 'certificationID',
          options: this.certificationsService.certifications,
          func: (certification) => this.certificationsService.certificationName(certification),
          test: (certification) => !get(certification, 'disabledAt')
        },
        );
      }

      // If there are parents to this folder and there is inheritance, then lock out changes in this form

      return ret;
    }
  }

  public parseFormData(type: FolderDataType, formData: any) {
    const fieldNames = [];
    const fields = this.getAdditionFields(type);
    each(this.getAdditionFields(type), item => {
      if (item?.name) {
        fieldNames.push(item.name);
      }
    });

    each(fieldNames, (fieldName) => {
      if (has(formData, fieldName)) {
        try {
          formData[fieldName] = JSON.parse(formData[fieldName]);
        } catch (error) {}
      }
    });
  }

  public onAccessLocationChange(formConfig: any): void {
    const locationField = <any>find(formConfig.fields, {name: 'access_locations'});
    let locations = filter(map(locationField.getValue(), Number));

    if (isEmpty(locations)) {
      locations = map(this.locations, 'locationID');
    }

    this.updateAccessShiftsBy(locations, formConfig);
    this.updateAccessTeamsBy(locations, formConfig);
  }

  private updateAccessTeamsBy(locationsIds: number[], formConfig: any) {
    const fieldName = 'access_teams';
    const fieldId = `#${formConfig.prefix}${fieldName}`;
    const fieldConfig = <any>find(formConfig.fields, {name: fieldName});
    const selection: string[] | string = <string[] | string>fieldConfig.getValue();
    const fieldData: any = {};
    if (fieldConfig.multiple) {
      fieldData[fieldName] = isArray(selection) ? map(selection, Number) : [+selection];
    } else {
      fieldData[fieldName] = selection;
    }
    fieldConfig.options = this.filterService.buildTeamMenu(locationsIds, false).dropDownOptions;
    this.formBuilderService.replaceSelectOptionsWith(fieldId, formConfig, fieldConfig, fieldData);
  }

  private updateAccessShiftsBy(locationsIds: number[], formConfig: any) {
    const fieldName = 'access_shifts';
    const fieldId = `#${formConfig.prefix}${fieldName}`;
    const fieldConfig = <any>find(formConfig.fields, {name: fieldName});
    const selectedIds = fieldConfig.getValue();

    const locations = map(locationsIds, (locationId) => this.userService.getLocation(locationId));
    fieldConfig.options = this.shifts.getSelectMenuObjectsByLocations(locations);
    this.formBuilderService.replaceSelectOptionsWith(fieldId, formConfig, fieldConfig, {shifts: selectedIds});
  }

  private getParentIds(folderData: IFolder): SelectedProperty[] {
    let parentProperties: SelectedProperty[] = [];

    if (folderData?.parentID) {
      if (folderData?.parentAttributes?.selectedProperties) {
        parentProperties = folderData.parentAttributes.selectedProperties as SelectedProperty[];
      } else {
        const parentFolder = this.foldersDataService.getFolderByID(+folderData.parentID);
        parentProperties = [
          ...parentFolder?.attributes?.selectedProperties || [],
          ...parentFolder?.parentAttributes?.selectedProperties || []
        ] as SelectedProperty[];
      }
    }

    return parentProperties;
  }

}
