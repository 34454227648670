import { Permission } from '@services/permissions/permissions.service';
import { Module } from '@services/subscriber/subscriber.service';
import { IObjectStringKeyMap } from '@shared/models';

export enum ObjectState {
  Active = 'active',
  Archived = 'archived',
  Inactive = 'inactive',
  Deleted = 'deleted'
}

export enum ObjectPhase {
  Draft = 'draft',
  InReview = 'inReview',
  Rejected = 'rejected',
  Approved = 'approved',
  Pending = 'pending',
  Inactive = 'inactive',
  Active = 'active',
  Published = 'published',
  Superseded = "superseded",
  Deprecated = 'deprecated',
  Withdrawn = 'withdrawn',
  Archived = 'archived',
}

export enum ObjectTransition {
  Activate = 'activated',
  Deactivate = 'deactivated',
  Create = 'created',
  Retire = 'retired',
  StartReview = 'reviewStart',
  Approve = 'approved',
  Reject  = 'rejected',
  Schedule = 'pending',
  Publish = 'published',
  Supersede = 'superseded',
  Withdraw = 'withdrawn',
  Deprecate = 'deprecated',
  Archive = 'archived'
}

export enum ObjectLifecycle {
  Review = 'review',
  Basic = 'basic'
}

export interface ObjectPhaseRule {
  description: string;
  type: ObjectState;
  transitions: ObjectTransition[]
}

export interface ObjectTransitionRule {
  description: string;
  target: ObjectPhase
}

export interface ObjectPhaseRestriction {
  permissions: Permission[];
  // When these are actually editable, extend this with roles, certificates, users, teams
}

type ObjectPhaseKeyMap<T> = {
  [key in ObjectPhase]?: T;
}
type ObjectTransitionKeyMap<T> = {
  [key in ObjectTransition | 0]?: T;
}

export interface ObjectLifecycleCollection {
  usesRevision?: boolean;
  phases: ObjectPhaseKeyMap<ObjectPhaseRule>,
  transitions: ObjectTransitionKeyMap<ObjectTransitionRule>;
  restrictions?: IObjectStringKeyMap<ObjectPhaseRestriction>;
  modules?: Module[]
}

export interface UpdateObjectParams {
  objectUUID?: string;
  objectID?: number;
  mediaType?: string;
  description?: string;
  type?: string;
  subtype?: string;
  state?: string;
  isPublic?: number;
  folderID?: number;
}

export interface UpdateMultipleObjectParams {
  objects: { [objectID: number]: UpdateObjectParams; };
}

export interface ObjectHistoryItem {
  noteID: number;
  objectID: number;
  revision: string;
  userID: number;
  activity: {
    enum: [
      "created",
      "activated",
      "deactivated",
      "deleted",
      "retired",
      "reviewStart",
      "reviewEnd",
      "reviewResult",
      "reviewResultWithNote",
      "note",
      "approved",
      "rejected",
      "pending",
      "published",
      "withdrawn",
      "deprecated",
      "superseded",
      "archived",
      "moved"
    ]
  },
  relatedType: string;
  relatedItem: string;
  relatedData: object;
  time: number;
}
export interface ObjectNote {
  addedAt: number;
  addedBy: number;
  disabledAt: number;
  disabledBy: number;
  lastUpdate: number;
  noteID: number;
  objectID: number;
  revision: string;
  sentiment: null;
  shift: string;
  subtype: string;
  type: string;
  value: string;
}

export interface ObjectItem {
  contentCategory: number;
  createdAt: number;
  creatorID: number;
  creatorShift: number;
  description: string;
  disabledAt: number;
  disabledBy: number;
  isPublic: number;
  folderID: number;
  lastUpdate: number;
  mediaType: string;
  objectID: number;
  revision: string;
  translationOf: number;
  language: string;
  objectUUID: string;
  state: string;
  subtype: string;
  tags: number[];
  type: string;
  observationID?: number;
  observationUUID?: number;
  history?: ObjectHistoryItem[];
  notes?: ObjectNote[];
  monitorHealth?: number;
  health?: number;
}

