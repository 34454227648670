import { NGXLogger } from 'ngx-logger';
import { Injectable, Type } from '@angular/core';

import { ToggleComponent } from '@shared/components/toggle/toggle.component';
import { SliderComponent } from '@shared/components/slider/slider.component';
import { UserService } from '@services/user/user.service';
import { DynamicComponentCreatorService } from '@services/dynamicComponentCreator/dynamic-component-creator.service';
import { CertificationsService } from '@services/certifications/certifications.service';
import { GearService } from '@services/gear/gear.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { RolesService } from '@services/roles/roles.service';
import { Permission, PermissionsService } from '@services/permissions/permissions.service';
import { TeamsService } from '@services/teams/teams.service';
import { UtilsService } from '@services/utils/utils.service';
import { NativeElementService } from '@services/native-element/native-element.service';
import { Feature, Module, SubscriberService } from '@services/subscriber/subscriber.service';
import { ObjectsService } from '@services/objects/objects.service';
import { CommsService } from '@services/comms/comms.service';
import { FileUploadCallbacks, ImageUploaderService } from '@services/image-uploader/image-uploader.service';
import { CustomFormComponent } from './abstract-custom-form-field';
import { DatePickerComponent } from '@shared/components/date-picker/date-picker.component';

import 'select2';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import 'jquery-validation';
import { BrandService } from '@services/brand/brand.service';
import {
  assign,
  cloneDeep,
  each,
  filter,
  find,
  forEach,
  get,
  has,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isNull,
  isNumber,
  isObject,
  isUndefined,
  join,
  keys,
  lowerCase,
  map,
  merge,
  orderBy,
  pull,
  sortBy,
  sumBy,
  trim
} from 'lodash';

export interface ISelectMenuItem {
  id: string | number;
  description: string;
}

export interface FormFieldMedia {
  options?: any;
  events?: FileUploadCallbacks;
  imageInstance?: any;
  languageFieldsReference?: any[];
  startUpload?: () => void;
}

export interface FormField {
  title?: string;
  name?: string;
  tooltip?: string;
  notModule?: Module;
  module?: Module;
  checkBrand?: Module | Module[];
  feature?: Feature;
  type?: string;
  labelClass?: string;
  onText?: string;
  offText?: string;
  value?: any;
  class?: string;
  valueProperty?: string;
  textProperty?: string;
  containerClass?: string;
  required?: boolean;
  originalOrder?: boolean;
  canClear?: boolean;
  canDelete?: boolean;
  showTicks?: boolean;
  showTicksValues?: boolean;
  disabled?: boolean;
  tags?: boolean;
  autocomplete?: boolean;
  multiple?: boolean;
  break?: boolean;
  subtype?: string;
  inputtype?: string;
  addButton?: string;
  updateButton?: string;
  min?: number;
  maxlength?: number;
  minlength?: number;
  imageSize?: string;
  fromID?: string;
  size?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  caption?: string;
  equalTo?: string;
  radioClass?: string;
  currentThumbnail?: string;
  default?: any;
  role?: string;
  extensions?: string[];
  selectOptions?: { [key: string]: any };
  mediaConfig?: FormFieldMedia;
  enabledFieldNames?: string[];
  disabledFieldNames?: string[];
  inputs?: { [key: string]: any };
  outputs?: { [key: string]: (...params) => void };
  component?: Type<CustomFormComponent>;
  componentRef?: CustomFormComponent;
  canView?: Permission;
  options?: any;
  onClick?: (event?) => void;
  onChange?: (value: any, form?: JQuery<any>, config?: FormConfig) => void;
  test?: (raw: any) => boolean;
  func?: (value?: any) => any;
  valueFunc?: (value?: any) => any;
  getValue?: () => any;
  getLegend?: (value: number) => string;
  onRemoveField?: (field: FormField) => void;
  infoAction?: (field: FormField) => void;
  searchable?: boolean; // works only for single select
  autoGrow?: boolean; // works for textarea only
  noLabelInCustomElement?: boolean; // only for custom elements
  canExtend?: boolean; // when defined with addButton supports adding instances of the field to the form
  filter?: {
    getItemText?: (data) => string | number;
    getItemId?: (data) => string | number;
  }
}

export interface FormConfig {
  fields: FormField[];
  prefix: string;
  enableChangeDetectionMode?: boolean;
  enableAddChangeDetectionMode?: boolean;
  enableExtraChangeDetectionMode?: boolean;
  hidden?: { [key: string]: any };
  cancel?: string;
  class?: string;
  save?: string;
  del?: string;
  extraControl?: string;
  mod?: string;
  autocomplete?: boolean;
  canClear?: boolean;
  containers?: boolean;
  hideChangeDetectionPanel?: boolean;
  onResetEvent?: () => any;
}

// This reflects a height of 47 pixels, which seems to be the default from our designs.
const defaultTextareaHeight = 47;

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {

  private changedFields: any = {};
  private isNative = false;
  private currentFormId: string;
  private customElementDestroySubscription: Subject<string> = new Subject<string>();

  constructor(
    private logger: NGXLogger,
    private userService: UserService,
    private certificationsService: CertificationsService,
    private gearService: GearService,
    private dynamicComponentCreatorService: DynamicComponentCreatorService,
    private accountService: AccountsService,
    private userdataService: UserdataService,
    private roleService: RolesService,
    private permissionService: PermissionsService,
    private subscriberService: SubscriberService,
    private teamService: TeamsService,
    private utils: UtilsService,
    protected translate: TranslateService,
    private nativeElementService: NativeElementService,
    private objectsService: ObjectsService,
    private commsService: CommsService,
    private imageUploaderService: ImageUploaderService,
    private brandService: BrandService
  ) {
  }

  public init() {
    (<any>$).validator.setDefaults({
      required: true,
      normalizer: (value) => trim(value)
    });
  }

  public showForm(where, attrs: FormConfig, data) {
    const menus = [];
    const $form = $(where);
    if ($form.length) {
      this.currentFormId = where;
      this.changedFields[this.currentFormId] = {};
      if (attrs.enableChangeDetectionMode) {
        this.syncChangePanel(where);
      }
      // we found the element;  populate it
      $form.html('');
      if (attrs.hasOwnProperty('hidden')) {
        $.each(attrs.hidden, (index: any, field) => {
          $(`<input type='hidden'>`)
            .attr('name', index)
            .attr('value', field)
            .attr('id', attrs.prefix + index)
            .appendTo($form);
        });
      }

      if (this.isChangeDetectionPanelAvailable(attrs)) {
        $form.addClass('changes-panel');
      }

      // loop over the data
      each(attrs.fields, (field, index) => {
        if (field.notModule && this.subscriberService.usesModule(field.notModule)) {
          return;
        }
        if (field.module && !this.subscriberService.usesModule(field.module)) {
          return;
        }

        if (field.feature && !this.subscriberService.usesFeature(field.feature)) {
          return;
        }

        if (field.canView && !this.permissionService.canView(field.canView)) {
          return;
        }

        if (field.checkBrand && !this.brandService.canUseModule(field.checkBrand)) {
          return;
        }

        const fid = field.name ? attrs.prefix + field.name : '';
        let i;
        let c;
        if (field.type === 'hidden' && field.name && data && data[field.name]) {
          i = $(`<input type='hidden'>`).attr('name', field.name).attr('value', data[field.name]);
        } else {
          // if this is a regular field OR it is a text translation field and there are multiple languages, process the field
          if ((field.type !== 'textTranslations' && field.type !== 'textareaTranslations') || this.subscriberService.getLanguages(true).length) {
            c = this.buildFormFieldContainer(attrs, field, index, $form);
            i = this.buildFormField(attrs, field, data, $form);
            if (fid) {
              if (field.type === 'selectmenu' || field.type === 'timepicker2') {
                menus.push({id: fid, field});
              }
            }
          }
        }
        if (c) {
          if (i) {
            i.appendTo(c);
          }
          c.appendTo($form);
        } else if (i) {
          i.appendTo($form);
        }
      });
      // add submit buttons
      let width = -1;
      const grids = ['ui-grid-none', 'ui-grid-a', 'ui-grid-b', 'ui-grid-c', 'ui-grid-d'];
      let controls = '';
      const taller = ' button-taller';
      const classes = 'operation ui-btn button-styled';

      if (this.isChangeDetectionPanelAvailable(attrs)) {
        controls += `
          <div class="changes-count-title">
            <span class="changes-count-value">0 ${this.translate.instant('SHARED.Changes')}</span>
            <span>${this.translate.instant('SHARED.Have_Been_Made')}</span>
          </div>
        `;
      }

      if (attrs.cancel && !this.isChangeDetectionPanelAvailable(attrs)) {
        width++;
        // controls += "<div class='" + pos[count++] + "'>";
        controls += '<a class=\'bg-neutral ';
        let cancel = this.translate.instant(attrs.cancel);
        if (attrs.cancel && attrs.cancel.match(' ')) {
          cancel = cancel.replace(/ /, ' <br class=\'br-styled\'>');
          controls += classes;
        } else {
          controls += classes + taller;
        }

        controls += '\' id=\'' + attrs.prefix + 'Cancel\'>' + cancel + '</a>\n';
        // controls += "</div>\n";
      }

      if (attrs.extraControl) {
        width++;
        const extra = this.translate.instant(attrs.extraControl).replace(/ /, ' <br class=\'br-styled\'>');
        // controls += "<div class='" + pos[count++] + "'>";
        const controlChangeDetectionClass = attrs.enableChangeDetectionMode && attrs.enableExtraChangeDetectionMode ? 'extra-control-button disabled' : '';
        controls += `<a class="operation ui-btn button-styled ${controlChangeDetectionClass}" id="${attrs.prefix}ExtraControl">${extra}</a>`;
        // controls += </div>\n";
      }

      if (attrs.save) {
        width++;
        const save = this.translate.instant(attrs.save).replace(/ /, ' <br class=\'br-styled\'>');
        // controls += "<div class='" + pos[count++] + "'>";

        if (this.isChangeDetectionPanelAvailable(attrs)) {
          controls += '<a class=\'operation ui-btn button-styled save-button disabled\' id=\'' + attrs.prefix + 'Save\'>' + save + '</a>';
        } else {
          controls += '<a class=\'operation ui-btn button-styled\' id=\'' + attrs.prefix + 'Save\'>' + save + '</a>';
        }
        // controls += "</div>\n";
      }

      if (this.isChangeDetectionPanelAvailable(attrs)) {
        width++;
        controls += `<a class=\'bg-neutral ${classes + taller} cancel-button`;
        const cancel: string = this.translate.instant('SHARED.Cancel');
        controls += '\' id=\'' + attrs.prefix + 'Cancel\'>' + cancel + '</a>\n';

        setTimeout(() => {
          $(`#${attrs.prefix}Cancel`).off('click').on('click', () => {
            $(`${where}.hidden-panel`).removeClass('active-panel');
            attrs.onResetEvent && attrs.onResetEvent();
          });
        });
      }

      if (attrs.mod) {
        width++;
        const mod = attrs.mod.replace(/ /, ' <br class=\'br-styled\'>');
        // controls += "<div class='" + pos[count++] + "'>";
        controls += '<a class=\'operation ui-btn button-styled\' id=\'' + attrs.prefix + 'Modify\'>' + mod + '</a>';
        // controls += "</div>\n";
      }

      if (attrs.del) {
        width++;
        const del = this.translate.instant(attrs.del).replace(/ /, ' <br class=\'br-styled\'>');
        // controls += "<div class='" + pos[count++] + "'>";
        controls += '<a class=\'operation ui-btn button-styled button-styled-delete\' id=\'' + attrs.prefix + 'Delete\'>' + del + '</a>';
        // controls += </div>\n";
      }

      const isExtraControl = !!(attrs.cancel && (attrs.del || attrs.mod || attrs.extraControl || attrs.enableAddChangeDetectionMode));
      const additionControlPanelClass: string = attrs.enableChangeDetectionMode && !isExtraControl ? 'hidden-panel' : '';
      if (additionControlPanelClass) {
        $form.addClass(additionControlPanelClass);
      }

      controls = `<div class='ui-form-controls ` + grids[width] + `'>\n` + controls + `</div>\n`;
      this.initSelect2Fields(menus, data);
      $form.append(controls);

      // form is build - make sure jquery controls are initialized
      $form.trigger('create');
      $('.ui-icon-carat-d').addClass('ui-alt-icon');
    } else {
      this.logger.log('selector ' + JSON.stringify(where) + ' not in document!');
    }
  }

  public certificationName(certItem) {
    let ret = certItem.name;
    let desc = certItem.description;
    if (this.translate != undefined) {
      const ntranslations = certItem.ntranslations;
      const dtranslations = certItem.dtranslations;
      const lang = this.translate.getDefaultLang();
      if (ntranslations) {
        forEach(ntranslations, element => {
          if (element.language == lang) {
            ret = element.value;
          }
        });
      }
      if (dtranslations) {
        forEach(dtranslations, element => {
          if (element.language == lang) {
            desc = element.value;
          }
        });
      }
    }

    if (desc) {
      if (ret) {
        ret += ' - ';
      }
      ret += desc;
    }
    return ret;
  }

  public gearName(gearItem) {
    const name = this.userService.translateItem(gearItem, 'name');
    const description = this.userService.translateItem(gearItem, 'description');
    return join(filter([name, description, gearItem.subtype]), ' - ');
  }

  public getFormData(f) {
    const fData = {};
    $.each(f.serializeArray(), function () {
      if (fData[this.name] !== undefined) {
        if (!fData[this.name].push) {
          fData[this.name] = fData[this.name] ? [fData[this.name]] : [];
        }
        if (this.value) {
          fData[this.name].push(this.value);
        }
      } else {
        const value = this.value || '';
        const isMultipleField = f.find(`select[name="${this.name}"]`).attr('multiple');
        fData[this.name] = isMultipleField ? [value] : value;
      }
    });
    return fData;
  }

  public updateFields(attribs, fields, data, formId?: string) {
    const select2Fields = [];

    each(fields, (field) => {
      const formInstance = formId ? $(`#${formId}`) : null;
      const fieldContainerId = this.getFieldContainerId(attribs, field);
      const containerElement = this.buildFormFieldContainer(attribs, field, 0);
      const fieldElement = this.buildFormField(attribs, field, data, formInstance);

      if (containerElement) {
        const fieldId = this.getFieldId(attribs, field);

        fieldElement.appendTo(containerElement);
        $(`#${fieldContainerId}`).replaceWith(containerElement);

        if (fieldId && includes(['selectmenu', 'timepicker2'], field.type)) {
          select2Fields.push({id: fieldId, field});
        }
      }
    });

    this.initSelect2Fields(select2Fields, data);
  }

  public addFieldsTo(formConfig: FormConfig, fields, selector = $(`#${formConfig.prefix}Form`), renderToBottom = true) {
    const select2Fields = [];

    each(fields, (field) => {
      const containerElement = this.buildFormFieldContainer(formConfig, field, 0);

      if (containerElement && $(`#${containerElement.attr('id')}`).length === 0) {
        const fieldElement = this.buildFormField(formConfig, field, {});
        const fieldId = this.getFieldId(formConfig, field);

        fieldElement.appendTo(containerElement);
        renderToBottom ? containerElement.appendTo(selector) : containerElement.prependTo(selector);

        if (includes(['selectmenu', 'timepicker2'], field.type)) {
          select2Fields.push({id: fieldId, field});
        }
      }
    });

    this.initSelect2Fields(select2Fields, {});
  }

  public buildFormField(attribs, field: FormField, data: any = {}, $form?: any) {
    const formId: string = this.currentFormId;
    const fid = field.name ? attribs.prefix + field.name : '';
    const labelID = fid ? fid + '_label' : '';
    let fieldCurrentValue = data ? data[field.name] : '';
    let i;
    field.getValue = null;

    if (field.type === 'hidden' && field.name && data && data[field.name]) {
      i = $(`<input type='hidden'>`).attr('name', field.name).attr('value', data[field.name]);
    } else {
      if (field.type === 'button') {
        // okay - we have a button to embed in the form
        i = $('<button>');
        if (this.isNative) {
          i.attr('data-mini', 'true');
        }
        if (field.name) {
          i.attr('name', field.name);
        }

        if (fid) {
          i.attr('id', fid);
        }
        if (field.title) {
          i.append(this.translate.instant(field.title));
        }
        if (field.class) {
          i.addClass(field.class);
        }

        i.on('click', (event: any) => {
          if (field.onClick) {
            event.preventDefault();
            field.onClick();
          }
        });
      } else if (field.type === 'timepicker') {

        let options;
        let opts = field?.options;

        if (typeof (opts) === 'string') {
          /* jshint evil:true */
          // it is the name of an object; get a reference to that
          options = eval(opts);
        }

        if (typeof (opts) === 'object') {
          if (!Array.isArray(opts) && opts.hasOwnProperty('data')) {
            options = opts.data;
          } else {
            options = opts;
          }
        }

        // multiselect menu field
        i = $(`<fieldset>`);
        let valueProp = 'id';
        if (field.hasOwnProperty('valueProperty')) {
          valueProp = field.valueProperty;
        }
        let textProp = 'description';
        if (field.hasOwnProperty('textProperty')) {
          textProp = field.textProperty;
        }

        // this one is special.  The value for the field is a number of seconds.
        // translate this into the appropriate unit
        let current = 0;
        let currentDefUnit = '';
        if (field.name && data) {
          current = data[field.name];
          currentDefUnit = data[field.name + '_unit'];
        }

        let currentUnit = '';
        if (!options) {
          options = [
            {
              id: 'minutes',
              description: this.translate.instant('SHARED.Minutes'),
              divisor: 60
            },
            {
              id: 'hours',
              description: this.translate.instant('SHARED.Hours'),
              divisor: 3600
            },
            {
              id: 'days',
              description: this.translate.instant('SHARED.Days'),
              divisor: 86400
            },
            {
              id: 'weeks',
              description: this.translate.instant('SHARED.Weeks'),
              divisor: 604800
            }
          ];


          if (field.hasOwnProperty('subtype')) {
            if (field.subtype === 'minutes') {
              options = [
                {
                  id: 'seconds',
                  description: this.translate.instant('SHARED.Seconds'),
                  divisor: 1
                },
                {
                  id: 'minutes',
                  description: this.translate.instant('SHARED.Minutes'),
                  divisor: 60
                },
              ];
              currentUnit = 'seconds';

            } else if (field.subtype === 'minutesOnly') {
              options = [
                {
                  id: 'minutes',
                  description: this.translate.instant('SHARED.Minutes'),
                  divisor: 60
                },
              ];
              currentUnit = 'minutes';

            }
          }
        }
        if (!field.hasOwnProperty('required') || field.required === false) {
          currentUnit = '';
        } else {
          currentUnit = options[0]?.id;
        }
        if (current && currentDefUnit) {
          const currentOpt = find(options, { id: currentDefUnit });
          if (currentOpt?.divisor) {
            current = current / currentOpt.divisor;
          }
          currentUnit = currentDefUnit;
        } else if (current) {
          for (let u = options.length - 1; u >= 0; u--) {
            if (current >= options[u].divisor) {
              currentUnit = options[u].id;
              if (options[u]?.divisor) {
                current = current / options[u].divisor;
              }
              u = -1;
            }
          }
        }

        const timePickerModel: any = {
          timeInterval: current,
          type: currentUnit
        };
        // if the field is disabled, just emit the value
        let t;

        if (field.disabled) {
          t = $(`<input data-wrapper-class='controlgroup-textinput ui-btn' class='timeinterval-text disabled' disabled size='5' type='number'>`);

        } else {


        // okay - now put in the input field AND the unit field
        t = $(`<input data-wrapper-class='controlgroup-textinput ui-btn' class='timeinterval-text' size='5' type='number'>`);

        t.on('input', (event: Event) => {
          timePickerModel.timeInterval = $(event.target).val();

          fieldCurrentValue = timePickerModel;
          field.onChange && field.onChange(timePickerModel, $form, attribs);
          this.hideFieldsOnValue(field, attribs, timePickerModel);

          if (attribs.enableChangeDetectionMode) {
            this.markFieldAsChanged(formId, field.name, data[field.name], +this.utils.convertToSeconds(timePickerModel.timeInterval, data[field.name + '_unit']));
            this.markFieldAsChanged(formId, field.name + '_unit', data[field.name + '_unit'], timePickerModel.type);
          }
        });

      }
        if (fid) {
          t.attr('id', fid).attr('name', field.name);
        }
        if (current || (has(field, 'min') && field.min === 0)) {
          t.attr('value', current);
        }
        if (field.required) {
          t.attr('required', 'required');
        }

        if (field.type === 'timepicker') {
          if (field.hasOwnProperty('min')) {
            t.attr('min', field.min);
          }
          if (field.hasOwnProperty('max')) {
            t.attr('max', field.max);
          }
          if (field.hasOwnProperty('step')) {
            t.attr('step', field.step);
          }
        }
        // put this into the control group
        t.appendTo(i);
        // multiselect menu field
        const s: any = $('<select>');
        if (field.name) {
          s.attr('name', field.name + '_unit').attr('id', fid + '_unit');
        }
        if (field.hasOwnProperty('placeholder')) {
          s.attr('data-placeholder', field.placeholder);
        }
        if (!field.hasOwnProperty('placeholder') && (!field.hasOwnProperty('required') || field.required === false)) {
          $(`<option value=''>` + this.translate.instant('SHARED.--none') + `</option>`).appendTo(s);
        } else if (field.hasOwnProperty('required') && field.required === true) {
          s.attr('required', 'required');
        }
        $.each(options, function (item, ref) {
          const o = $('<option>');
          o.attr('value', ref.id);
          if (currentUnit === ref.id) {
            o.attr('selected', 'selected');
          }
          o.append(ref.description);
          o.appendTo(s);
        });
        s.appendTo(i);
        const sopts: any = {};
        if (field.type === 'timepicker') {
          sopts.minimumResultsForSearch = Infinity;
        }
        sopts.sorter = (data) => data;
        // if ( field.disabled ) {
        //   sopts.disabled = true;
        // }
        s.select2(sopts);

        s.on('change', (e) => {
          timePickerModel.type = $(e.target).val();

          fieldCurrentValue = timePickerModel;
          field.onChange && field.onChange(timePickerModel, $form, attribs);
          this.hideFieldsOnValue(field, attribs, timePickerModel);

          if (attribs.enableChangeDetectionMode) {
            this.markFieldAsChanged(formId, field.name, data[field.name], +this.utils.convertToSeconds(timePickerModel.timeInterval, data[field.name + '_unit']));
            this.markFieldAsChanged(formId, field.name + '_unit', data[field.name + '_unit'], timePickerModel.type);
          }
        });

        setTimeout(() => {
          this.hideFieldsOnValue(field, attribs, timePickerModel);
        });
      } else if (field.type === 'radio') {
        // single select radio field

        let opts = field.options;

        if (typeof (opts) === 'string') {
          /* jshint evil:true */
          // it is the name of an object; get a reference to that
          opts = eval(opts);
        }

        if (typeof (opts) === 'object') {
          if (!Array.isArray(opts) && opts.hasOwnProperty('data')) {
            opts = opts.data;
          }
        }

        const r = $(`<div>`);

        // tslint:disable-next-line: prefer-const
        const valueProp = get(field, 'valueProperty', 'id');
        // tslint:disable-next-line: prefer-const
        const textProp = get(field, 'textProperty', 'description');

        each(opts, (ref) => {
          // first - is there a test attribute?  It is a callback
          if (field.hasOwnProperty('test') && !field.test(ref)) {
            // the test function returned false; bail out
            return;
          }
          const o = $(`<ion-item class="form-item" lines="none">`);
          if (field.hasOwnProperty('radioClass')) {
            o.addClass(field.radioClass);
          }
          const inputHTMLElement: any = $(`<input id="${field.name}_${ref[valueProp]}" style="opacity: 0;">`);
          // tslint:disable-next-line:triple-equals
          let isSelected: boolean = ref[valueProp] == data[field.name];

          if (isUndefined(data[field.name]) && !isUndefined(field.default)) {
            isSelected = field.default === ref[valueProp];
          }

          if (isSelected) {
            inputHTMLElement.attr('name', field.name).attr('value', ref[valueProp]);
          }
          let t = '';
          if (field.hasOwnProperty('func') && typeof field.func === 'function') {
            // there is a function to create the value
            t = field.func(ref);
          } else {
            if (typeof (ref) === 'object') {
              t = ref[textProp];
            } else {
              t = ref;
            }
          }
          const b: any = $(`<ion-radio label-placement="end" type='radio' value='${ref[valueProp]}'>${this.translate.instant(t)}</ion-radio>`);
          inputHTMLElement.appendTo(b);

          setTimeout(() => {
            this.hideFieldsOnValue(field, attribs, data[field.name]);
          });

          if (field.hasOwnProperty('name')) {
            b.attr('name', field.name);

            if (isSelected) {
              b.attr('checked', true);
            }
          }
          o.append(b);
          o.appendTo(r);
        });
        const value = data[field.name] || field.default;
        const radioGroupElement: any = $(`<ion-radio-group value="${value}">${r.html()}</ion-radio-group>`);

        radioGroupElement.on('click', (event: any) => {
          const radioButtonElement = $(event.target).closest('ion-radio');
          const currentValue: string = <string>radioButtonElement.val();
          const currentName: string = radioButtonElement.attr('name');

          if (currentValue) {
            const formElement = $(`#${attribs.prefix}Form`);
            formElement.find(`input[name=${currentName}]`).removeAttr('name');
            formElement.find(`#${currentName}_${currentValue}`).val(currentValue).attr('name', currentName);

            fieldCurrentValue = currentValue;
            if (field.onChange) {
              field.onChange(currentValue, $form, attribs);
            }

            this.hideFieldsOnValue(field, attribs, currentValue);

            if (attribs.enableChangeDetectionMode) {
              const originValue: any = data[field.name];
              this.markFieldAsChanged(formId, field.name, originValue, currentValue);
            }
          }
        });

        i = $('<fieldset>');
        radioGroupElement.appendTo(i);
      } else if (field.type === 'checkbox') {

        let opts = field.options;

        const setCheckboxValues = (values: any[]) => {
          if (field.required) {
            const checkboxHandlerElement: any = $(`input[id="${field.name}-checkbox"]`);
            const newValue: string = values.length ? 'true' : '';
            checkboxHandlerElement.val(newValue).trigger('blur');
          }
        };

        if (typeof (opts) === 'function') {
          opts = field.options();
        }

        if (typeof (opts) === 'string') {
          /* jshint evil:true */
          // it is the name of an object; get a reference to that
          opts = eval(opts);
        }

        if (typeof (opts) === 'object') {
          if (!Array.isArray(opts) && opts.hasOwnProperty('data')) {
            opts = opts.data;
          }
        }

        // multiselect menu field
        i = $(`<fieldset>`);
        if (field.hasOwnProperty('role') && field.role === 'none') {
          // if we are asserting no role, then use a span as a wrapper
          i = $('<ion-row>');
        }
        let valueProp = 'id';
        if (field.hasOwnProperty('valueProperty')) {
          valueProp = field.valueProperty;
        }
        let textProp = 'description';
        if (field.hasOwnProperty('textProperty')) {
          textProp = field.textProperty;
        }
        if (field.class) {
          i.addClass(field.class);
        }

        const initCheckboxValues: any[] = [];
        $.each(opts, (item, ref) => {
          // first - is there a test attribute?  It is a callback
          if (field.hasOwnProperty('test') && !field.test(ref)) {
            // the test function returned false; bail out
            return true;
          }

          let t = '';
          if (field.hasOwnProperty('func') && typeof field.func === 'function') {
            // there is a function to create the value
            t = field.func(ref);
          } else {
            if (typeof (ref) === 'object') {
              t = ref[textProp];
            } else {
              t = ref;
            }
          }

          const o = $(`<ion-checkbox slot="start" label-placement="end">${this.translate.instant(t)}</ion-checkbox>`);

          if (field.name) {
            o.attr('name', field.name);
            o.attr('id', fid + ref[valueProp]);
          }
          if (typeof (ref) === 'object') {
            o.attr('value', ref[valueProp]);
          } else {
            o.attr('value', ref);
          }
          if (field.hasOwnProperty('radioClass')) {
            o.addClass(field.radioClass);
          }
          if (data && data[field.name]) {
            if (Array.isArray(data[field.name])) {
              let matched = false;
              each(data[field.name], (val) => {
                // tslint:disable-next-line: triple-equals
                if (val == ref[valueProp]) {
                  matched = true;
                }
              });
              if (matched) {
                o.attr('checked', 'checked');
              }
            } else if (typeof data[field.name] === 'object') {
              if (data[field.name].hasOwnProperty(ref[valueProp])) {
                o.attr('checked', 'checked');
              }
            } else if (typeof ref === 'object' && ref.hasOwnProperty(valueProp) && data[field.name] === ref[valueProp]) {
              o.attr('checked', 'checked');
            } else if (data[field.name] === ref) {
              o.attr('checked', 'checked');
            }
          }

          if (!isEmpty(field.default)) {
            if (includes(field.default, ref[valueProp]) || field.default === ref[valueProp]) {
              o.attr('checked', 'checked');
            }
          }

          if (o.attr('checked')) {
            initCheckboxValues.push(ref[valueProp]);
          }
          if (t) {
            const ionItemElement: any = $(`<div>`);
            o.appendTo(ionItemElement);
            const ionColElement: any = $(`<ion-col size="auto"><ion-item class="form-item" lines="none">${ionItemElement.html()}</ion-item></ion-col>`);

            ionColElement.on('click', (e) => {
              const currentCheckboxElement = $(e.target).closest('ion-checkbox');
              if (currentCheckboxElement && currentCheckboxElement.val()) {
                let checkboxValues: any[] = [];

                each($(`input[name="${field.name}"]`), (field) => {
                  const checkboxValue: any = $(field).val();

                  if (checkboxValue) {
                    checkboxValues.push(checkboxValue);
                  }
                });

                const currentElementValue: any = currentCheckboxElement.val();
                if (currentCheckboxElement.attr('aria-checked') === 'false') {
                  checkboxValues.push(currentElementValue);
                } else {
                  checkboxValues = pull(checkboxValues, currentElementValue);
                }

                fieldCurrentValue = checkboxValues;
                if (field.onChange) {
                  field.onChange(checkboxValues, $form, attribs);
                }

                this.hideFieldsOnValue(field, attribs, checkboxValues);
                setCheckboxValues(checkboxValues);

                let originalValue: any = data[field.name];
                if (isArray(checkboxValues) && !isArray(originalValue)) {
                  originalValue = filter([originalValue]);
                }

                if (attribs.enableChangeDetectionMode) {
                  checkboxValues = map(checkboxValues, (value) => isNaN(+value) ? value : +value);
                  this.markFieldAsChanged(formId, field.name, sortBy(originalValue), sortBy(checkboxValues));
                }
              }
            });

            ionColElement.appendTo(i);
          } else {
            // no text - just dump the checkbox
            o.appendTo(i);
          }
        });

        if (field.required) {
          i.append(`<input class="required-checkbox-handler" id="${field.name}-checkbox" required>`);
        }

        setTimeout(() => {
          this.hideFieldsOnValue(field, attribs, initCheckboxValues);
          setCheckboxValues(initCheckboxValues);
        });

      } else if (field.type === 'divider' || field.type === 'subtitle') {
        // I don't think we do anything
      } else if (field.type === 'image') {
        i = this.generateImageUploadAreaBlock(fid, data, field);
        i.prepend(this.generateImageContainer(fid, field));
      } else if (field.type === 'media' && field.extensions) {
        i = this.generateImageUploadAreaBlock(fid, data, field);
        this.generateMediaField(i, fid, data, field, attribs, (value) => {
          if (attribs.enableChangeDetectionMode) {
            const originValue: any = get(data, `${field.name}`) || '';
            this.markFieldAsChanged(formId, field.name, originValue, value);
          }
        });

        setTimeout(() => {
          this.hideFieldsOnValue(field, attribs, data[field.name]);
        });
      } else if (field.type === 'mediaTranslations' && field.extensions) {
        const langs = this.subscriberService.getLanguages(true);
        const translationBlockElement: any = $(`<div style="display: none" id="${field.name}translationContent" class="text-translations text-translations-content">`);
        const fieldsByLanguage = [];

        each(langs, (lang) => {
          const n = this.subscriberService.getLanguageName(lang);
          const p = $(`<div id="${field.name}_${lang}_translationContent_container" class="ui-field-contain"><label class='form-indent'>` + this.translate.instant(n) + `</label>`);
          const fieldByLanguage = Object.assign({}, cloneDeep(field), {
            name: `${field.name}_${lang}`
          });

          fieldByLanguage.mediaConfig.options.formData.language = lang;
          fieldByLanguage.mediaConfig.options.formData.translationOf = data.objectID;
          fieldsByLanguage.push(fieldByLanguage);

          const imageContainerId = `${fid}_${lang}`;
          const imageElement = this.generateImageUploadAreaBlock(imageContainerId, data, fieldByLanguage);
          this.generateMediaField(imageElement, imageContainerId, data, fieldByLanguage, attribs, (value = '') => {
            if (attribs.enableChangeDetectionMode) {
              const originValue: any = get(data, `${field.name}_${lang}`) || '';
              this.markFieldAsChanged(formId, field.name, originValue, value);
            }
          });

          p.append(imageElement);
          translationBlockElement.append(p);
        });

        field.mediaConfig.startUpload = () => {
          each(fieldsByLanguage, (fieldByLanguage) => {
            fieldByLanguage.mediaConfig.startUpload();
          });
        };

        field.mediaConfig.languageFieldsReference = fieldsByLanguage;

        const collapsibleItemElement: any = $(`<div id="${field.name}collapsibleItem" class="text-translations-content collapsible-item">${field.title}</div>`);

        i = $(`<div class="collapsible-block"></div>`);
        i.append(collapsibleItemElement);
        i.append(translationBlockElement);

        collapsibleItemElement.on('click', () => {
          const translationContentElement: any = $form.find(`#${field.name}translationContent`);
          const collapsibleItemElement: any = $form.find(`#${field.name}collapsibleItem`);
          const targetClass = 'active-block';

          if (collapsibleItemElement.hasClass(targetClass)) {
            collapsibleItemElement.removeClass(targetClass);
            translationContentElement.hide();
          } else {
            collapsibleItemElement.addClass(targetClass);
            translationContentElement.show();
          }
        });
      } else if (field.type === 'video' || field.type === 'object') {
        // special management category where there is an upload operation and a delete operation on things
        // that are already in the field if any
      } else if (field.type === 'textTranslations' || field.type === 'textareaTranslations') {
        /* a textTranslation field will present an input element for each
           language enabled for the current subscriber.
        */

        const langs = this.subscriberService.getLanguages(true);
        const translationBlockElement: any = $(`<div style="display: none" id="${field.name}translationContent" class="text-translations text-translations-content">`);

        // iterate over the alternate languages
        each(langs, (lang) => {
          let f;
          if (field.type === 'textTranslations') {
            f = $('<input>');
          } else {
            f = $('<textarea>');
          }

          f.attr('type', 'text');

          if (field.class) {
            f.addClass(field.class);
          }
          if (field.autoGrow) {
            f.addClass('auto-grow');
            f.attr('rows', 1);
          }

          $(f).on('input', (ev) => {
            const value: any = $(ev.target).val();

            if (field.autoGrow) {
              this.handleAutoGrow(ev, field);
            }

            fieldCurrentValue = value;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            field.onChange && field.onChange(value, $form, attribs);
            this.hideFieldsOnValue(field, attribs, value);

            if (attribs.enableChangeDetectionMode) {
              const originValue: any = data[field.name];
              this.markFieldAsChanged(formId, field.name, originValue, $(ev.target).val());
            }
          });
          if (!field.disabled) {
            if (attribs.hasOwnProperty('autocomplete') || field.hasOwnProperty('autocomplete')) {
              f.attr('autocomplete', (field.autocomplete || attribs.autocomplete));
            }
            if (field.inputtype) {
              f.attr('inputtype', field.inputtype);
            }
            if (field.size) {
              f.attr('size', field.size);
            }
            if (field.maxlength) {
              f.attr('maxlength', field.maxlength);
            }
            if (field.placeholder) {
              f.attr('placeholder', field.placeholder);
            }
          }
          const tName = `${field.name}_${lang}`;
          f.attr('name', tName);
          const lFid = `${fid}_${lang}`;
          f.attr('id', lFid);
          if (data && data.hasOwnProperty(tName)) {
            if (field.disabled) {
              if (field.func) {
                f.append(field.func(data[tName]));
              } else {
                f.append(data[tName]);
              }
              if (field.valueFunc) {
                $(`<input type='hidden'>`).attr('name', tName).attr('value', field.valueFunc(data[tName])).appendTo(i);
              } else {
                $(`<input type='hidden'>`).attr('name', tName).attr('value', data[tName]).appendTo(i);
              }
            } else {
              if (field.type === 'textTranslations') {
                f.attr('value', data[tName]);
              } else {
                f.append(data[tName]);
              }
            }
          } else {
            // there is no data for this field.  is it 'disabled'?  in that case we should at least emit the hidden field
            if (field.disabled) {
              if (field.func) {
                f.append(field.func());
              }
              if (field.valueFunc) {
                $(`<input type='hidden'>`).attr('name', tName).attr('value', field.valueFunc()).appendTo(i);
              } else {
                $(`<input type='hidden'>`).attr('name', tName).appendTo(i);
              }
            } else if (field.hasOwnProperty('default')) {
              if (field.type === 'textTranslations') {
                f.val(field.default);
              } else {
                f.append(field.default);
              }
            }
          }
          if (field.required) {
            f.attr('required', 'required');
          }
          if (field.equalTo) {
            f.attr('equalTo', `input[name=${field.equalTo}]`);
          }
          if (field.minlength) {
            f.attr('minlength', field.minlength);
          }
          const n = this.subscriberService.getLanguageName(lang);
          const p = $(`<div id="${field.name}_${lang}_translationContent_container" class="ui-field-contain"><label class='form-indent'>` + this.translate.instant(n) + `</label>`);
          p.append(f);
          translationBlockElement.append(p);
        });
        const collapsibleItemElement: any = $(`<div id="${field.name}collapsibleItem" class="text-translations-content collapsible-item">${field.title}</div>`);

        i = $(`<div class="collapsible-block"></div>`);
        i.append(collapsibleItemElement);
        i.append(translationBlockElement);

        collapsibleItemElement.on('click', () => {
          const translationContentElement: any = $form.find(`#${field.name}translationContent`);
          const collapsibleItemElement: any = $form.find(`#${field.name}collapsibleItem`);
          const targetClass = 'active-block';

          if (collapsibleItemElement.hasClass(targetClass)) {
            collapsibleItemElement.removeClass(targetClass);
            translationContentElement.hide();
          } else {
            collapsibleItemElement.addClass(targetClass);
            translationContentElement.show();
          }
        });
      } else if (field.type !== 'selectmenu') {
        // this is NOT a menu - just a regular 'text' input field
        // or a flip switch
        // or a range
        let isExtensible = false;
        let instanceCounter = 0;
        let numInstances = 1;
        if (field.canExtend && field.addButton) {
          i = $(`<span class='ui-plus-button'></span>`);
        }
        if (data[field.name] && isArray(data[field.name]) && data[field.name].length && field.canExtend) {
          // there are multiple values AND it is extensible.  We need to create multiple instances
          numInstances = data[field.name].length;
          isExtensible = true;
        }
        while (instanceCounter < numInstances) {
          const dataIndex = `${field.name}-${instanceCounter}`;
          const instanceData = isExtensible ? data[field.name][instanceCounter] ?? '' : data[field.name];

          let instance;
          if (field.disabled) {
            instance = $('<div>');
          } else {
            instance = $('<input>');
          }
          //  always add a data-index in case we need to delete a row
          instance.attr('data-index', dataIndex);
          if (field.type === 'textarea') {
            instance = $('<textarea>');

            if (field.autoGrow) {
              instance.addClass('auto-grow');

              setTimeout(() => {
                instance.trigger('input');
              });
            }

            $(instance).on('input', (ev) => {
              const value: any = $(ev.target).val();

              if (field.autoGrow) {
                this.handleAutoGrow(ev, field);
              }

              fieldCurrentValue = value;
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              field.onChange && field.onChange(value, $form, attribs);
              this.hideFieldsOnValue(field, attribs, value);

              if (attribs.enableChangeDetectionMode) {
                const originValue: any = instanceData || '';
                this.markFieldAsChanged(formId, $(instance).attr('data-index'), originValue, $(ev.target).val());
              }
            });

            setTimeout(() => {
              this.hideFieldsOnValue(field, attribs, instanceData);
            });
          }

          if (fid) {
            instance.attr('id', fid);
          }
          if (!field.disabled) {
            if (field.type !== 'range' && this.isNative) {
              instance.attr('data-mini', 'true');
            }
            if (field.name) {
              instance.attr('name', field.name);
            }
          } else {
            instance.addClass('ui-input-text');
            instance.addClass('form-panel-values');
          }
          if (field.type === 'flipswitch') {
            instance = this.dynamicComponentCreatorService.create(ToggleComponent, (componentInstance: ToggleComponent) => {
              componentInstance.fieldName = field.name;
              componentInstance.isChecked = isNumber(instanceData) ? instanceData === 1 : field.default;
              if (field.hasOwnProperty('value')) {
                componentInstance.enabledValue = field.value;
              }
              if (field.hasOwnProperty('onChange')) {
                componentInstance.onChange.subscribe((value) => {
                  field.onChange(value, attribs.prefix, attribs);
                });
              }
              componentInstance.onChange.subscribe((value: any) => {
                each(field.disabledFieldNames, (field: string) => {
                  const isBreakLine: boolean = get(find(attribs.fields, { name: field }), 'break', false);
                  const targetClass: string = isBreakLine ? '.ui-field-contain-break' : '.ui-field-contain';
                  let fieldContainerElement: any = $(`#${attribs.prefix}${field}_label`).closest(targetClass);

                  if (fieldContainerElement.length === 0) {
                    fieldContainerElement = $(`#${attribs.prefix}${field}`).closest(targetClass);
                  }
                  value ? fieldContainerElement.removeClass('disabled') : fieldContainerElement.addClass('disabled');

                  const selectElement: JQuery = fieldContainerElement.find('select');
                  if (selectElement.length && selectElement.prop('required')) {
                    value ? selectElement.prop('disabled', false) : selectElement.prop('disabled', true);
                  }

                  if (!value && fieldContainerElement.length) {
                    const targetElement: any = $(`#${attribs.prefix}Form [name='${field}']`);
                    const currentData: any = value || 0;

                    if (targetElement.attr('type') === 'checkbox') {
                      targetElement.prop('checked', !!currentData);
                    } else if (targetElement.hasClass('select2-hidden-accessible')) {
                      targetElement.val(currentData || null).trigger('change');
                    } else if (targetElement.hasClass('aux-input')) {
                      targetElement.parent().prop('checked', !!currentData);
                    } else {
                      targetElement.val(currentData);
                    }
                  }
                });

                each(field.enabledFieldNames, (field: string) => {
                  const isBreakLine: boolean = get(find(attribs.fields, { name: field }), 'break', false);
                  const targetClass: string = isBreakLine ? '.ui-field-contain-break' : '.ui-field-contain';
                  let fieldContainerElement: any = $(`#${attribs.prefix}${field}_label`).closest(targetClass);

                  if (fieldContainerElement.length === 0) {
                    fieldContainerElement = $(`#${attribs.prefix}${field}`).closest(targetClass);
                  }
                  value ? fieldContainerElement.addClass('disabled') : fieldContainerElement.removeClass('disabled');

                  const selectElement: JQuery = fieldContainerElement.find('select');
                  if (selectElement.length && selectElement.prop('required')) {
                    value ? selectElement.prop('disabled', true) : selectElement.prop('disabled', false);
                  }

                  if (value && fieldContainerElement.length) {
                    const targetElement: any = $(`#${attribs.prefix}Form [name='${field}']`);
                    const currentData: any = value || 0;

                    if (targetElement.attr('type') === 'checkbox') {
                      targetElement.prop('checked', !!currentData);
                    } else if (targetElement.hasClass('select2-hidden-accessible')) {
                      targetElement.val(currentData || null).trigger('change');
                    } else if (targetElement.hasClass('aux-input')) {
                      targetElement.parent().prop('checked', !!currentData);
                    } else {
                      targetElement.val(currentData);
                    }
                  }
                });

                setTimeout(() => {
                  this.hideFieldsOnValue(field, attribs, value ?? 0);
                });

                if (attribs.enableChangeDetectionMode) {
                  const originValue: any = instanceData || 0;

                  this.markFieldAsChanged(formId, field.name, originValue, value || 0);
                }
              });
              const value = componentInstance.getValue();
              componentInstance.onChange.emit(isUndefined(value) ? instanceData : value);
              field.getValue = () => componentInstance.getValue();
            });
            instance.addClass('form-date-flipswitch');
          } else if (field.type === 'dateRangePicker') {
            instance = this.dynamicComponentCreatorService.create(DatePickerComponent, (componentInstance: DatePickerComponent) => {
              componentInstance.config.types = map(field.options, 'id');
              componentInstance.config.value = instanceData || field.default;
              componentInstance.config.range = this.setDatePickerRange(field.name, data);
              componentInstance.fieldName = field.name;
              field.componentRef = componentInstance;

              setTimeout(() => {
                componentInstance.onChanged.subscribe((value) => {
                  if (field.hasOwnProperty('onChange')) {
                    field.onChange(value, attribs.prefix, attribs);
                  }

                  if (attribs.enableChangeDetectionMode) {
                    const originValue: any = instanceData;
                    this.markFieldAsChanged(formId, field.name, originValue, value.type);
                  }
                });
              });
            });
            instance.addClass('form-date-range-picker');
            let selectClass = 'ui-select select-type2';
            if (field.canDelete) {
              selectClass += ' removable';
            }
            const d = $(`<div class='${selectClass}'>`);
            instance.appendTo(d);
            instance = d;
          } else if (field.type === 'customElement' && field.component) {
            const customElement = this.dynamicComponentCreatorService.create(field.component, (componentInstance: CustomFormComponent, componentRef) => {
              if (field.required) {
                componentInstance.required = true;
              }

              if (field.inputs) {
                assign(componentInstance, field.inputs);
              }
              componentInstance.formValue = instanceData;
              field.componentRef = componentInstance;

              if (field.outputs) {
                each(field.outputs, (output, key) => {
                  componentInstance[key].subscribe((...params) => output(...params));
                });
              }

              componentInstance.onChanged.subscribe(() => {
                let formValue = componentInstance.formValue;
                $(`#${fid}-custom-element-handler`).val(JSON.stringify(formValue)).trigger('change');

                if (attribs.enableChangeDetectionMode) {
                  const originalData: any = instanceData;
                  if (isEmpty(originalData) && (isNull(formValue) || isUndefined(formValue))) {
                    formValue = originalData;
                  }

                  this.markFieldAsChanged(formId, field.name, originalData, formValue);
                }
              });

              const subscription = this.customElementDestroySubscription.subscribe((id: string) => {
                if (formId === id) {
                  componentRef.destroy();
                  subscription.unsubscribe();
                }
              });
            });
            instance = $('<div class="custom-element-container"></div>');
            customElement.appendTo(instance);
            const inputField = $(`<input class="hidden-form-input" name="${field.name}" id="${fid}-custom-element-handler"/>`);
            const value = instanceData || field?.inputs?.values;
            const defaultValue = field?.default || [];
            let fieldValue = value || defaultValue;

            if (field.required) {
              inputField.attr('required', 'required');
              fieldValue = value;
            }

            inputField.val(JSON.stringify(fieldValue)).appendTo(instance);
            instance.find('> div').addClass('custom-element-content');
          } else if (field.type === 'range') {
            instance = this.dynamicComponentCreatorService.create(SliderComponent, (componentInstance: SliderComponent) => {
              componentInstance.config = {
                min: field.min,
                max: field.max,
                showTicks: field.showTicks,
                showTicksValues: field.showTicksValues,
                getLegend: field.getLegend,
                value: instanceData || field.default
              };
              field.getValue = () => componentInstance.getValue();
              componentInstance.onChange.subscribe((value: number) => {
                field.onChange && field.onChange(value, $form, attribs);

                if (attribs.enableChangeDetectionMode) {
                  const originValue: any = instanceData;
                  this.markFieldAsChanged(formId, field.name, originValue, value);
                }
              });
            });
          } else if (field.type !== 'textarea') {
            instance.attr('type', field.type);
            if (field.type === 'number') {
              if (field.hasOwnProperty('min')) {
                instance.attr('min', field.min);
              }
              if (field.hasOwnProperty('max')) {
                instance.attr('max', field.max);
              }
              if (field.hasOwnProperty('step')) {
                instance.attr('step', field.step);
              }
            }

            $(instance).on('input', (ev: Event) => {
              let value: any = $(ev.target).val();

              fieldCurrentValue = value;
              if (field.onChange) {
                field.onChange(value, $form, attribs);
              }

              if (attribs.enableChangeDetectionMode) {
                const originValue: any = instanceData || '';

                if (isNumber(originValue) && value) {
                  value = +value;
                }

                this.markFieldAsChanged(formId, field.name, originValue, value);
              }
              this.hideFieldsOnValue(field, attribs, value);
            });

            setTimeout(() => {
              this.hideFieldsOnValue(field, attribs, instanceData);
            });
          }
          if (!field.disabled) {
            if (attribs.hasOwnProperty('autocomplete') || field.hasOwnProperty('autocomplete')) {
              instance.attr('autocomplete', (field.autocomplete || attribs.autocomplete));
            }
            if (attribs.canClear || field.canClear) {
              // i.attr('data-clear-btn', 'true');
            }
            if (field.inputtype) {
              instance.attr('inputtype', field.inputtype);
            }
            if (field.size) {
              instance.attr('size', field.size);
            }
            if (field.maxlength) {
              instance.attr('maxlength', field.maxlength);
            }
            if (field.placeholder) {
              instance.attr('placeholder', field.placeholder);
            }
          }
          if (data && data.hasOwnProperty(field.name)) {
            if (field.disabled) {
              if (field.type !== 'flipswitch') {
                if (field.func) {
                  instance.append(field.func(instanceData));
                } else {
                  instance.append(instanceData);
                }
                if (field.valueFunc) {
                  $(`<input type='hidden'>`).attr('name', field.name).attr('value', field.valueFunc(instanceData)).appendTo(instance);
                } else {
                  $(`<input type='hidden'>`).attr('name', field.name).attr('value', instanceData).appendTo(instance);
                }
              }
            } else {
              if (field.type !== 'flipswitch') {
                instance.val(instanceData);
                if (field.type === 'range') {
                  instance.attr('value', instanceData);
                }
              }
            }
          } else {
            // there is no data for this field.  is it 'disabled'?  in that case we should at least emit the hidden field
            if (field.disabled) {
              if (field.func) {
                instance.append(field.func());
              }
              if (field.valueFunc) {
                $(`<input type='hidden'>`).attr('name', field.name).attr('value', field.valueFunc()).appendTo(instance);
              } else {
                $(`<input type='hidden'>`).attr('name', field.name).appendTo(instance);
              }
            } else if (field.hasOwnProperty('default')) {
              instance.val(field.default);
            }
          }
          if (field.required) {
            instance.attr('required', 'required');
          }
          if (field.equalTo) {
            instance.attr('equalTo', `input[name=${field.equalTo}]`);
          }
          if (field.minlength) {
            instance.attr('minlength', field.minlength);
          }
          if (field.class) {
            instance.addClass(field.class);
          }

          if (!i) {
            i = instance;
          } else {
            i.append(instance);
          }

          instanceCounter++;

          if (isExtensible && ( instanceCounter < numInstances ) ) {
            // add a delete option for this row
            const d = this.deleteButton(field);
            i.append(d);
          }
        }
        if (field.addButton) {
          // we are supposed to append a button to this
          const b = $(`<button class='button-styled'>`);
          b.append(this.translate.instant(field.addButton));

          if (field.canExtend) {
            b.addClass('top-aligned');
          }

          b.on('click', (event: any) => {
            if (field.canExtend) {
              event.preventDefault();
              this.extendField(field, event);
            } else if (field.onClick) {
              event.preventDefault();
              field.onClick(event);
            }
          });
          i.append(b);
        }
      } else if (field.type === 'selectmenu') {

        let opts = field.options;

        if (typeof (opts) === 'function') {
          opts = () => field.options();
        }
        if (typeof (opts) === 'string') {
          /* jshint evil:true */
          // it is the name of an object; get a reference to that
          opts = eval(opts);
        }

        if (has(opts, 'data')) {
          this.utils.sortArray(opts.data, [
            {name: 'name', function: this.utils.toLowerCase},
            {name: 'description', function: this.utils.toLowerCase}
          ]);
        }

        if (typeof (opts) === 'object') {
          if (!Array.isArray(opts) && opts.hasOwnProperty('data')) {
            opts = opts.data;
          }
        }
        // multiselect menu field
        i = $('<select>');
        if (field.name) {
          i.attr('name', field.name).attr('id', fid);
        }
        if (field.hasOwnProperty('multiple') && field.multiple) {
          i.attr('multiple', 'multiple');
        }
        if (!field.hasOwnProperty('placeholder') && (!field.hasOwnProperty('required') || field.required === false)) {
          i.attr('data-placeholder', this.translate.instant('SHARED.--none'));
        } else if (field.hasOwnProperty('required') && field.required === true && !field.disabled) {
          i.attr('required', 'required');
        }
        // add an empty option if there is a placeholder and we are single select
        if (!field.hasOwnProperty('multiple') || !field.multiple) {
          if (field.hasOwnProperty('placeholder')) {
            i.append($(`<option>`));
          }
        }
        // make sure jquery mobile doesn't do anything to us
        if (field.hasOwnProperty('canClear')) {
          if (field.canClear) {
            i.attr('data-allow-clear', 'true');
          }
        } else if (attribs.canClear) {
          i.attr('data-allow-clear', 'true');
        }
        if (field.hasOwnProperty('placeholder')) {
          i.attr('data-placeholder', field.placeholder);
        }
        let valueProp = 'id';
        if (field.hasOwnProperty('valueProperty')) {
          valueProp = field.valueProperty;
        }
        let textProp = 'description';
        if (field.hasOwnProperty('textProperty')) {
          textProp = field.textProperty;
        }

        if (opts) {
          $.each(opts, function (item, ref) {
            if (ref.children && ref.children.length) {
              const element: any = $(`<optgroup label="${ref.text}">`);
              each(ref.children, (child) => {
                const fieldValue = data[field.name];
                const isSelected: boolean = isArray(fieldValue) ? includes(fieldValue, child.id) : fieldValue == child.id;
                $(`<option value="${child[valueProp]}" ${isSelected ? 'selected' : ''}>${child.text}</option>`).appendTo(element);
              });
              element.appendTo(i);
            } else {
              // first - is there a test attribute?  It is a callback
              if (field.hasOwnProperty('test') && !field.test(ref)) {
                // the test function returned false; bail out
                return true;
              }
              const o = $('<option>');

              if (ref.disabled) {
                o.prop('disabled', true);
              }

              const v = typeof (ref) === 'object' ? ref[valueProp] : ref;
              o.attr('value', v);
              if (data && has(data, field.name) && data[field.name] !== null) {
                if (Array.isArray(data[field.name])) {
                  let matched = false;
                  each(data[field.name], (val) => {
                    // tslint:disable-next-line: triple-equals
                    if (val == v) {
                      matched = true;
                    }
                  });
                  if (matched) {
                    o.attr('selected', 'selected');
                  }
                } else if (typeof data[field.name] === 'object') {
                  if (data[field.name].hasOwnProperty(ref[valueProp])) {
                    o.attr('selected', 'selected');
                  }
                  // tslint:disable-next-line: triple-equals
                } else if (typeof ref === 'object' && ref.hasOwnProperty(valueProp) && data[field.name] == ref[valueProp]) {
                  o.attr('selected', 'selected');
                  // tslint:disable-next-line: triple-equals
                } else if (data[field.name] == ref) {
                  o.attr('selected', 'selected');
                }
                // tslint:disable-next-line: triple-equals
              } else if (field.default !== undefined && field.default == v) {
                o.attr('selected', 'selected');
              }
              if (field.hasOwnProperty('func') && typeof field.func === 'function') {
                // there is a function to create the value
                o.append(field.func(ref));
              } else {
                if (typeof (ref) === 'object') {
                  if (textProp == 'description' && ref[textProp]) {
                    o.append(this.translate.instant(ref[textProp]));
                  } else {
                    o.append(ref[textProp]);
                  }
                } else {
                  o.append(ref);
                }
              }
              o.appendTo(i);
            }
          }.bind(this));
        }

        let selectClass = 'ui-select select-type2';
        if (field.canDelete || field.infoAction) {
          selectClass += ' actionable-select';
        }
        const d = $(`<div class='${selectClass}'>`);

        i.appendTo(d);
        i = d;

        setTimeout(() => {
          const fieldElement: any = $(`#${fid}`);

          if (fieldElement.is(':visible')) {
            this.hideFieldsOnValue(field, attribs, fieldElement.val());
          }

          const applyChangeDetection = (value: any) => {
            if (attribs.enableChangeDetectionMode) {
              let originValues: any = data[field.name];

              if (isArray(originValues)) {
                originValues = orderBy(map(filter(originValues), String));

                if (isNull(value)) {
                  value = [];
                }

                if (!isArray(value)) {
                  value = filter([value]);
                }

                value = orderBy(value);
              } else if (isUndefined(originValues)) {
                originValues = null;
              } else if (isObject(originValues) && isArray(value)) {
                originValues = keys(originValues);
              }

              if (isNumber(originValues)) {
                value = +value;
              }

              if (field.default && !originValues) {
                originValues = field.default;
              }

              this.markFieldAsChanged(formId, field.name, originValues, value);
            }
            fieldCurrentValue = value;
          };

          const resetSearch: any = (target: any) => {
            $(target).parent().find('.select2-search__field').val('').trigger('input');
          };

          fieldElement.off('select2:select').on('select2:select', (event: any) => {
            resetSearch(event.target);
            applyChangeDetection($(event.target).val());
          });

          fieldElement.off('select2:unselect').on('select2:unselect', (event: any) => {
            setTimeout(() => {
              applyChangeDetection($(event.target).val());
            });
          });

          fieldElement.off('select2:selecting').on('select2:selecting', (event: any) => {
            resetSearch(event.target);

            if (event.params.args.data.isTag) {
              event.preventDefault();
              const newOption: any = new Option(event.params.args.data.text, event.params.args.data.id, true, true);
              (<any>$(`#${fid}`).append(newOption).trigger('change')).select2('close');
              applyChangeDetection($(event.target).val());
            }
          });
        });

        $(d).find('select').on('change', (ev) => {
          const value: any = $(ev.target).val();

          fieldCurrentValue = value;
          if (field.onChange) {
            field.onChange(value, $form, attribs);
          }

          this.hideFieldsOnValue(field, attribs, value);
        });
      }
      if (has(field, 'caption')) {
        const n = $('<span></span>');
        n.append(i);
        n.append(`&nbsp;${this.translate.instant(field.caption)}`);
        i = n;
      }

      if (field.canDelete) {
        const removeElement = $('<div class="form-remove-icon"></div>');
        removeElement.on('click', (e) => {
          field.onRemoveField && field.onRemoveField(field);
          const containClass = field.break ? '.ui-field-contain-break' : '.ui-field-contain';
          $(e.target).closest(containClass).remove();
        });

        removeElement.appendTo(i);
      }

      if (!field.getValue) {
        field.getValue = () => fieldCurrentValue;
      }

      if (field.infoAction) {
        const infoElement = $('<img class="field-info-icon" src="assets/icons/General/icon-info-active.svg">');
        infoElement.on('click', (e) => {
          field.infoAction && field.infoAction(field);
        });

        infoElement.appendTo(i);
      }
    }
    return i;
  }

  public replaceSelectOptionsWith(id, formConfig, options, data): void {
    const fieldContainer: any = this.buildFormFieldContainer(formConfig, options, true);
    const newSelectOptions = this.buildFormField(formConfig, options, data);
    newSelectOptions.appendTo(fieldContainer);
    (<any>$(id)).select2('destroy');
    const selectorClass = options.break ? '.ui-field-contain-break' : '.ui-field-contain';
    const ref = $(id).closest(selectorClass);
    const wasHidden = ref.css('display') === 'none';
    ref.replaceWith(fieldContainer);
    const parentElement: any = $(id).parent();
    parentElement.css('position', 'relative');

    const sopts: any = {
      width: '100%',
      closeOnSelect: false,
      scrollAfterSelect: true,
      sorter: (data) => {
        if (options.originalOrder) {
          return data;
        } else {
          return sortBy(data, (sortItem: any) => {
            if (sortItem.children) {
              sortItem.children = sortBy(sortItem.children, (child) => lowerCase(child.text));
            }
            return lowerCase(sortItem.text);
          });
        }
      },
      dropdownParent: parentElement
    };

    this.disableSelect2SearchByField(sopts, options);

    (<any>$(id)).select2(sopts);
    if (wasHidden) {
      $(id).closest('.ui-field-contain').hide();
    }
  }

  public hideFieldsOnValue(field: any, attribs: any, value: any): void {
    if (field.hideFieldsOnValue) {

      const showField = (targetElement: JQuery<HTMLElement>) => {
        const fieldName: string = targetElement.find(':input').prop('name');
        const fieldConfig = find(attribs.fields, {name: fieldName});

        targetElement.show();

        if (get(fieldConfig, 'disabled') !== true) {
          targetElement.removeClass('disabled');
          targetElement.find(':input').prop('disabled', false);
        }
      };

      const updateFunc = (ref) => {
        const valueFunction: (value, attribs) => boolean = ref.valueFunction || (() => false);
        if (ref.hasOwnProperty('fieldNames')) {
          each(ref.fieldNames, (name: string) => {
            const targetElement: any = $(`#${attribs.prefix}${name}_label`).closest('.ui-field-contain');

            if (ref.value === value || valueFunction(value, attribs)) {
              targetElement.hide();
              targetElement.find(':input').prop('disabled', true);
            } else {
              showField(targetElement);
            }
          });
        } else if (ref.hasOwnProperty('hideClasses') || ref.hasOwnProperty('hideClassesFunc')) {
          const list = ref.hideClassesFunc ? ref.hideClassesFunc(value) : ref.hideClasses;
          each(list, (cname: string) => {
            const formPrefix: string = get(attribs, 'prefix');
            const formGroupTarget = $(`#${formPrefix}Form`).find(`.${cname}`);
            const targetElements = formGroupTarget.length ? formGroupTarget : $(`.${cname}`);

            if (ref.alwaysHide || ref.value === value || valueFunction(value, attribs)) {
              targetElements.hide();
              targetElements.find(':input').prop('disabled', true);
            } else {
              // only show these if there is no explicit show control
              if (!ref.showClasses && !ref.showClassesFunc) {
                each(targetElements, (targetElement) => {
                  showField($(targetElement));
                });
              }
            }
          });
          if (ref.hasOwnProperty('showClasses') || ref.hasOwnProperty('showClassesFunc')) {
            const showList = ref.showClassesFunc ? ref.showClassesFunc(value) : ref.showClasses;
            each(showList, (name) => {
              $(`.${name}`).show().find(':input').prop('disabled', false);
            });
          }

        }
      };

      if (field.hideFieldsOnValue.hasOwnProperty('select')) {
        // if there is a select property it is a list of values
        // evaluate the ones that do NOT match, then the one that does if any
        const matched = [];
        each(field.hideFieldsOnValue.select, (ref) => {
          if (ref.hasOwnProperty('valueFunction')) {
            if (!ref.valueFunction(value, attribs)) {
              // the value does not match this selector
              // and if we did not have a global show control
              if (!field.hideFieldsOnValue.showClasses) {
                updateFunc(ref);
              }
            } else {
              matched.push(ref);
            }
          } else {
            if (ref.value !== value) {
              updateFunc(ref);
            } else {
              matched.push(ref);
            }
          }
        });

        // we found one that matched - do that one now
        each(matched, (matchedRef) => {
          updateFunc(matchedRef);
        });
      } else {
        updateFunc(field.hideFieldsOnValue);
      }
    }
  }

  public destroyCustomElements(formId: string) {
    this.customElementDestroySubscription.next(formId);
  }

  public isFormChanged(formId: string): boolean {
    return keys(this.changedFields[formId]).length > 0;
  }

  public clearFormChanges(formId: string): void {
    this.changedFields[formId] = {};
    this.syncChangePanel(formId);
  }

  public markFieldAsChanged(formId: string, fieldName: string, originValue: any, newValue: any): void {
    this.changedFields[formId][fieldName] = newValue;

    if (isEqual(this.changedFields[formId][fieldName], originValue)) {
      delete this.changedFields[formId][fieldName];
    }

    this.syncChangePanel(formId);
  }

  public changeFieldValueByName(target: HTMLElement | any, name: string, value: string): void {
    const fieldElement = this.nativeElementService.getElementBy(target, `[name="${name}"]`);

    if (fieldElement && value) {
      fieldElement.value = value;
    }
  }

  public disableFieldByName(target: HTMLElement | any, name: string, value: boolean) {
    const fieldElement = this.nativeElementService.getElementBy(target, `[name="${name}"]`);

    if (fieldElement) {
      const parentFieldElement = fieldElement.closest('.ui-field-contain,.ui-field-contain-break');

      if (parentFieldElement && !parentFieldElement.hidden) {
        this.disableInputs(parentFieldElement, value);
        value ? parentFieldElement.classList.add('disabled') : parentFieldElement.classList.remove('disabled');
      }
    }
  }

  public setElementVisibilityBy(target: HTMLElement | any, selector: string, isVisible: boolean) {
    this.nativeElementService.setElementVisibilityBy(target, selector, isVisible);

    each(this.nativeElementService.getElementsBy(target, selector), (element) => {
      this.disableInputs(element, !isVisible);
    });
  }

  private initSelect2Fields(menus, data) {
    $.each(menus, (idx, ref) => {
      // timepicker fields are special - we need to find the last one
      const theID = ref.field.type === 'timepicker' ? `${ref.id}_unit` : ref.id;
      const sopts: any = {allowClear: false, width: '100%', tags: false};

      if (ref.field.hasOwnProperty('multiple') && ref.field.multiple === true) {
        sopts.closeOnSelect = false;
        sopts.scrollAfterSelect = true;
      } else {
        if ((!ref.field.hasOwnProperty('required') || !ref.field.required) && get(ref, 'field.canClear') !== false) {
          sopts.allowClear = true;
        }
      }

      this.disableSelect2SearchByField(sopts, ref.field);

      if (ref.field.hasOwnProperty('placeholder')) {
        sopts.placeholder = ref.field.placeholder;
      }

      if (ref.field.hasOwnProperty('selectOptions')) {
        merge(sopts, ref.field.selectOptions);
      }

      if (ref.field.originalOrder) {
        sopts.sorter = (data) => data;
      } else if (ref.field.sorter) {
        sopts.sorter = ref.field.sorter;
      }

      if (ref.field.tags) {
        sopts.tags = true;
        sopts.createTag = (params) => params.term ? {
          id: `${params.term}_custom_tag`,
          text: params.term,
          isTag: true
        } : null;
      }

      const parentElement: any = $(`#${theID}`).parent();
      parentElement.css('position', 'relative');
      sopts.dropdownParent = parentElement;

      const f: any = (<any>$('#' + theID)).select2(sopts);

      if (ref.field.hasOwnProperty('default') && (!data || !data[ref.field.name])) {
        f.val(ref.field.default);
      }
      const $searchfield = $($('#' + theID)).parent().find('.select2-search--inline input');
      $searchfield.attr('style', 'width: 90%');
    });
  }

  private getFieldId(attribs, field) {
    return field.name ? attribs.prefix + field.name : '';
  }

  private getFieldContainerId(attribs, field) {
    const fid = this.getFieldId(attribs, field);
    return fid ? `${fid}_container` : '';
  }

  private buildFormFieldContainer(attribs, field, index, $form?: any): any {
    // emit a container for this form field
    const fid = this.getFieldId(attribs, field);
    const divID = this.getFieldContainerId(attribs, field);
    const labelID = fid ? fid + '_label' : '';
    let c;

    if (attribs.containers) {
      if (field.break) {
        c = $(`<div id="${divID}" class="ui-field-contain-break">`);
      } else if (field.type === 'divider') {
        if (index) {
          c = $(`<div><hr class="form-divider"></div>`);
        } else {
          c = $(`<div class="form-divider-title">`);
        }
      } else if (field.type === 'subtitle') {
        c = $(`<div class="form-divider-title headline-small">`);
      } else {
        c = $(`<div id="${divID}" class="ui-field-contain">`);
      }
      if (field.containerClass) {
        c.addClass(field.containerClass);
      }

      if (field.disabled && field.type !== 'panel') {
        c.addClass('disabled');
      } else {
        c.removeClass('disabled');
      }
    }

    if (field.title && field.type && field.type !== 'button' || (field.type === 'button' && field.label)) {
      const t = field.label ? this.translate.instant(field.label) : this.translate.instant(field.title);

      let l;
      if (field.type === 'divider') {
        l = $(`<div class='form-divider-title headline-medium'><span>${t}</span></div>`);
      } else if (field.type === 'subtitle') {
        l = $('<span>' + t + '</span>');
      } else if (field.type === 'customElement') {
        if (field.noLabelInCustomElement) {
          l = $('<label>' + t + '</label>');
        } else {
          l = $(`<div class='form-divider-title headline-small'><span>${t}</span></div>`);
        }
      } else if (field.type === 'textTranslations' || field.type === 'textareaTranslations' || field.type === 'mediaTranslations') {
        l = $('<label class="body-copy-bold' + t + '</label>');
        c.addClass('text-translations-block');
      } else {
        l = $('<label>' + t + '</label>');
      }
      if (fid) {
        l.attr('id', labelID);
      }
      if (field.tooltip) {
        l.attr('data-tooltip', this.translate.instant(field.tooltip));
      }
      if (field.required) {
        $('<span class="required">*</span>').appendTo(l);
      }
      if (field.type === 'selectmenu') {
        l.addClass('select');
      }
      if (field.type === 'panel') {
        l.addClass('form-panel');
      }
      if (field.labelClass) {
        l.addClass(field.labelClass);
      }
      if (field.type === 'flipswitch') {
        l.addClass('flipswitch-label');
      }
      if (field.type === 'button') {
        l.addClass('buttonlabel');
      }
      l.appendTo(c);
      if ((field.type === 'divider' || field.type === 'subtitle') && field.hasOwnProperty('description')) {
        c.append($(`<p class='body-copy'>${this.translate.instant(field.description)}</p>`));
      }
      if (field.break) {
        $('<br>').appendTo(c);
      }
    }
    return c;
  }

  private syncChangePanel(formId: string): void {
    const floatPanel = $(`${formId}.hidden-panel`);
    const targetElement = $(`${formId}.changes-panel .ui-form-controls .save-button`);
    const extraButtonElement = $(`${formId}.changes-panel .ui-form-controls .extra-control-button`);
    const cancelElement = $(`${formId}.changes-panel .ui-form-controls .cancel-button`);
    const changedFields: number = keys(this.changedFields[formId]).length;

    if (this.isFormChanged(formId)) {
      if (floatPanel.length) {
        floatPanel.addClass('active-panel');
        targetElement.removeClass('disabled');
        extraButtonElement.removeClass('disabled');
      } else {
        targetElement.removeClass('disabled');
        extraButtonElement.removeClass('disabled');
      }
    } else {
      if (floatPanel.length) {
        floatPanel.removeClass('active-panel');
      } else {
        targetElement.addClass('disabled');
        extraButtonElement.addClass('disabled');
      }
    }

    if (changedFields) {
      cancelElement.text(this.translate.instant('SHARED.Undo_All_Changes'));
    } else {
      cancelElement.text(this.translate.instant('SHARED.Cancel'));
    }

    $(`${formId} .changes-count-value`).html(`${changedFields} ${this.translate.instant('SHARED.Changes')}`);
  }

  private disableInputs(target: HTMLElement | any, isDisabled: boolean) {
    this.nativeElementService.disableElementsBy(target, 'input', isDisabled);
    this.nativeElementService.disableElementsBy(target, 'select', isDisabled);
  }

  private generateImageUploadAreaBlock(fieldId: string, data: any, field) {
    const b = $(`<div class='image-upload-control' id='` + fieldId + `-container'></div>`);
    const container = $(`<div class='image-upload'></div>`);

    if (field.buttonClass) {
      b.addClass(field.buttonClass);
    }

    if (data && data[field.name]) {
      if (field.replaceButton) {
        b.html(field.replaceButton);
      }
    } else {
      if (field.addButton) {
        b.html(field.addButton);
      } else {
        b.html('Upload');
      }
    }
    b.appendTo(container);

    const requiredAttr = field.required ? 'required' : '';
    $(`<input class="hidden-form-input" value="${data[field.name] || ''}" id="${fieldId}-image-handler" ${requiredAttr}/>`).appendTo(container);

    return container;
  }

  private generateImageContainer(fieldId: string, field) {
    let img = `<img id='${fieldId}-thumbnail'`;
    img += field.currentThumbnail ? ` src='${field.currentThumbnail}?t=${moment().valueOf()}'` : ` style='display:none'`;

    if (field.imageSize) {
      img += ` width="${field.imageSize}"`;
    }
    img += ' />';

    return $(`<div class="image-block">${img}</div>`);
  }

  private generateObjectContainer(fieldId: string, value: any, baseType: string ='pdf') {
    const theIcon = this.objectsService.getIconByBaseType(baseType);
    let object = `<a id='${fieldId}-${baseType}' target='_blank'`;
    object += value ? ` href='${value}'` : ` style='display:none'`;
    object += `><img width="70px" src="${theIcon}"></a>`;

    return $(`<div class="object-block">${object}</div>`);
  }

  private setDatePickerRange(fieldName, data) {
    let startTime = 0;
    let endTime = 0;
    if (fieldName === 'timespan') {
      startTime = data?.selectors?.startTime || data?.startTime;
      endTime = data?.selectors?.endTime || data?.endTime;
    } else {
      startTime = get(data, `selectors.${fieldName}Start`);
      endTime = get(data, `selectors.${fieldName}End`);
    }
    return {startTime, endTime};
  }

  private generateAudioVideoContainer(fieldId: string, value: any, type: 'video' | 'audio') {
    let element = `<${type} id='${fieldId}-${type}' controls`;
    const src = value || '';

    if (type === 'video') {
      element += ' width="320" height="240"';
    }

    if (!src) {
      element += ` style='display:none'`;
    }
    element += `><source src="${src}"></${type}>`;

    return $(`<div class="${type}-block">${element}</div>`);
  }

  private generateMediaField(i, fid, data, field, attribs, onChange?: (value?: string) => void) {
    let currentMediaType: string;
    let currentGeneralType: string;
    let objectSrc = null;

    if (data[field.name]) {
      const currentObject: any = this.objectsService.getCachedObjectById(data[field.name]);

      if (currentObject) {
        currentMediaType = currentObject.mediaType;
        currentGeneralType = this.objectsService.getObjectType(currentMediaType);
        objectSrc = this.commsService.objectURI(data[field.name], false, true);
      }
    }

    if (includes(field.extensions, 'image')) {
      if (!field.currentThumbnail) {
        field.currentThumbnail = includes(currentMediaType, 'image/') ? this.commsService.objectURI(data[field.name], true, true) : null;
      }
      i.prepend(this.generateImageContainer(fid, field));
    }

    if (includes(field.extensions, 'pdf')) {
      const value = includes(currentMediaType, 'application/pdf') ? objectSrc : null;
      i.prepend(this.generateObjectContainer(fid, value, 'pdf'));
    }

    if (includes(field.extensions, 'other')) {
      let value = currentGeneralType === 'word' ? objectSrc : null;
      i.prepend(this.generateObjectContainer(fid, value, 'word'));
      value = currentGeneralType === 'excel' ? objectSrc : null;
      i.prepend(this.generateObjectContainer(fid, value, 'excel'));
      value = currentGeneralType === 'ppt' ? objectSrc : null;
      i.prepend(this.generateObjectContainer(fid, value, 'ppt'));
      value = currentGeneralType === 'csv' ? objectSrc : null;
      i.prepend(this.generateObjectContainer(fid, value, 'csv'));
      value = currentGeneralType === 'html' ? objectSrc : null;
      i.prepend(this.generateObjectContainer(fid, value, 'html'));
    }

    if (includes(field.extensions, 'video')) {
      const value = includes(currentMediaType, 'video/') ? objectSrc : null;
      i.prepend(this.generateAudioVideoContainer(fid, value, 'video'));
    }

    if (includes(field.extensions, 'audio')) {
      const value = includes(currentMediaType, 'audio/') ? objectSrc : null;
      i.prepend(this.generateAudioVideoContainer(fid, value, 'audio'));
    }

    const startUpload = (imageInstance): boolean => {
      let isUploading = false;

      if (imageInstance?.selectedFiles) {
        isUploading = true;
        imageInstance.startUpload();
      }

      return isUploading;
    };

    setTimeout(() => {
      const mediaConfig: FormFieldMedia = field.mediaConfig || {};
      let objectResponse: { [key: string]: any } = {};

      const callbacks = {
        onSuccess: (response: any) => {
          objectResponse = response;
          mediaConfig.events?.onSuccess && mediaConfig.events?.onSuccess(objectResponse);
          this.hideFieldsOnValue(field, attribs, data[field.name] || response.objectID);
        },
        onError: () => mediaConfig.events?.onError && mediaConfig.events.onError(),
        onRemove: (newImageInstance) => {
          mediaConfig.imageInstance = newImageInstance;
          mediaConfig.startUpload = () => startUpload(newImageInstance);
          mediaConfig.events?.onRemove && mediaConfig.events.onRemove(objectResponse.objectID || data[field.name]);
          onChange();
          this.hideFieldsOnValue(field, attribs, data[field.name]);
        },
        onSubmit: () => mediaConfig.events?.onSubmit && mediaConfig.events.onSubmit(),
        onSelect: (val) => onChange && onChange(val),
        onCancel: () => onChange && onChange()
      };

      const imageInstance = this.imageUploaderService.init(`#${fid}`, mediaConfig.options || {}, currentMediaType, callbacks);

      mediaConfig.startUpload = () => startUpload(imageInstance);
      mediaConfig.imageInstance = imageInstance;
    });
  }

  private isChangeDetectionPanelAvailable(attrs) {
    return attrs.enableChangeDetectionMode && !attrs.hideChangeDetectionPanel;
  }

  private disableSelect2SearchByField(options: any, field: FormField): void {
    const maxOptionsWithoutSearch = 7;
    const disableSearch = !field.hasOwnProperty('searchable') && this.getSelect2OptionCount(field?.options) <= maxOptionsWithoutSearch;

    if (field?.searchable === false || disableSearch) {
      options.minimumResultsForSearch = Infinity;
    }
  }

  private getSelect2OptionCount(options: any[] = []): number {
    options ??= [];
    const initOption = options[0] || {};
    let count = options.length;

    if (initOption.children) {
      count = sumBy(options, (option) => option?.children?.length || 0);
    }

    return count;
  }

  private handleAutoGrow(ev, field) {
    ev.currentTarget.style.height = 0;
    let scrollH = ev.currentTarget.scrollHeight;
    if (!scrollH) {
      scrollH = field?.size ?? defaultTextareaHeight;
    }
    ev.currentTarget.style.height = `${scrollH}px`;
  }

  private deleteButton(field) {
    const d = $(`<ion-icon class="delete-row" slot="icon-only" name="remove-circle">`);
    d.on('click', (event: any) => {
        event.preventDefault();
        this.deleteRow(field, event);
    });
    return d;
  }

  private deleteRow(field, event?) {
    // delete a row AND the delete button in an extensible field
    this.logger.debug(`deleting row in field ${field.name} using event ${JSON.stringify(event)}`);
    if (field.type === 'textarea' || field.type === 'text') {
      // this is simple; clone the previous sibling and inject it
      const prev = event.target.previousSibling;
      if (prev) {
        prev.remove();
      }
      event.target.remove();
    }
  }

  private extendField(field, event?) {
    // extend a field by adding another instance of it to the container
    this.logger.debug(`extending field ${field.name} using event ${JSON.stringify(event)}`);
    if (field.type === 'textarea' || field.type === 'text') {
      // this is simple; clone the previous sibling and inject it
      const prev = event.target.previousSibling;
      if (prev) {
        const newElement = $(prev).clone(true);
        if (newElement) {
          // we have a clone of the element.   We also need a delete button
          const d = this.deleteButton(field);
          const b = prev.insertAdjacentElement('afterEnd', d[0]);
          newElement[0].value = '';
          b.insertAdjacentElement('afterEnd', newElement[0]);
        }
      }
    }
  }
}
