import { Injectable } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';
import { IPropertyMeta } from '@services/assets/asset.interfaces';
import {
  CommsService,
  ISelectMenuItem,
  Module,
  Permission,
  PermissionsService,
  SubscriberService,
  UserdataService,
  UtilsService
} from '@services';
import {
  IProperty,
  IPropertyEvent,
  IPropertyEventDurationValue,
  IPropertyEventMeasurementValue,
  IPropertyEventRequestsParams,
  IPropertyEventValue,
  LimitType,
  PropertyCategory,
  PropertyMethod,
  PropertySource,
  PropertyStyle,
  PropertySubject,
  ThresholdType
} from './property-model.interfaces';

import * as _ from 'lodash';
import { cloneDeep, flatten, isArray, indexOf, each, filter, find, includes, intersection, isUndefined, map, some } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { MarkdownService } from 'ngx-markdown';
import { IncrementEntryFieldsModel } from '@modules/management/pages/details/check/models';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  private properties = {
    lastRequest: null,
    data: {} as IObjectStringKeyMap<IProperty>
  };

  constructor(
    private commsService: CommsService,
    private events: Events,
    private subscriberService: SubscriberService,
    private permissionService: PermissionsService,
    private userData: UserdataService,
    private translate: TranslateService,
    private utils: UtilsService,
    private markdownService: MarkdownService
  ) {}

  public refresh(): Promise<IObjectStringKeyMap<IProperty>> {
    const requestData: any = {
      cmd: 'getProperties',
      lastRequest: this.properties.lastRequest,
    };

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.updateCache(response);
      }

      return this.properties.data;
    });
  }

  public updateCache(data) {
    this.properties.data = data.result.properties;
    this.properties.lastRequest = data.result.timestamp;
    this.events.publish('ccs:propertiesUpdate', true);
  }

  public getPropertiesAsList(folderID: number = 0, includeInactive: boolean = false, includeDisabled: boolean = false, sifter?: (item: IProperty) => boolean ): IProperty[] {
    const theList: IProperty[] = [];

    each(this.properties.data, (item) => {
      if (!isUndefined(folderID) && (item.folderID !== folderID)) {
        return;
      }

      if (!includeInactive && !item.active) {
        return;
      }
      if (!includeDisabled && item.disabledAt) {
        return;
      }
      if (!isUndefined(sifter) && !sifter(item)) {
        return;
      }
      theList.push(item);
    });

    return theList;
  }

  public getPropertiesByIds(ids: IPropertyMeta[]): IProperty[] {
    return filter(map(ids, (id) => this.getPropertyById(id.propertyID)));
  }
  public getProperties(): IObjectStringKeyMap<IProperty> {
    return this.properties.data;
  }

  public getPropertyEvents(propertyID: number, subjectID: number, subject: PropertySubject, activity?: string): Promise<IPropertyEvent[]> {
    const requestData = {
      cmd: 'getPropertyEvents',
      propertyID,
      subjectID,
      subject,
      activity
    };
    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        return response?.result?.events || [];
      }
    });
  }

  public getPropertyById(id: number): IProperty {
    return cloneDeep(this.properties.data[id]);
  }

  public addProperty(data: any): Promise<any> {
    const requestData = {
      cmd: 'addProperty',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public async updateProperty(data: any): Promise<any> {
    const requestData = {
      cmd: 'updateProperty',
      ...data
    };
    const result = await this.commsService.sendMessage(requestData, false, false);
    await this.refresh();
    return result;
  }

  public deleteProperty(ids: number[] | number | string): Promise<any> {
    const requestData = {
      cmd: 'deleteProperty',
      properties: flatten([ids])
    };

    return this.commsService.sendMessage(requestData, false, false);
  }

  public capturePropertyEvents(params: IPropertyEventRequestsParams): Promise<any> {
    const requestData = {
      cmd: 'capturePropertyEvents',
      sendTime: Date.now(),
      ...params
    };

    return this.commsService.sendMessage(requestData, false, false);
  }

  public getLimitTypeList(): ISelectMenuItem[] {
    return [
      { id: LimitType.Healthy, description: 'PROPERTY.Healthy'},
      { id: LimitType.Strained, description: 'PROPERTY.Strained'},
    ];
  }
  public getThresholdTypeList(category?: PropertyCategory): ISelectMenuItem[] {
    const ret = [];
    if (category === PropertyCategory.InService) {
      ret.push(
        { id: ThresholdType.Selector, description: 'PROPERTY.Selector'},
      );
    } else {
    ret.push (
      { id: ThresholdType.Toggle, description: 'PROPERTY.Boolean'},
      { id: ThresholdType.Ascending, description: 'PROPERTY.Ascending'},
      { id: ThresholdType.Descending, description: 'PROPERTY.Descending'},
    );
    }
    return ret;
  }

  public getCalculationMethodList(limit?: PropertySource): ISelectMenuItem[] {
    const ret = [];
    if (!limit) {
      ret.push(
      { id: PropertyMethod.Latest, description: 'PROPERTY.Latest'},
      { id: PropertyMethod.Highest, description: 'PROPERTY.Highest'},
      { id: PropertyMethod.Lowest, description: 'PROPERTY.Lowest'},
      { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
      { id: PropertyMethod.Average, description: 'PROPERTY.Average'},
      );
    } else {
      if (limit === PropertySource.Derived) {
        ret.push(
          { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
        );
      } else if (limit === PropertySource.OverTime) {
        ret.push(
          { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
        );
      } else {
      ret.push(
        { id: PropertyMethod.Latest, description: 'PROPERTY.Latest'},
        { id: PropertyMethod.Highest, description: 'PROPERTY.Highest'},
        { id: PropertyMethod.Lowest, description: 'PROPERTY.Lowest'},
        { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
        { id: PropertyMethod.Average, description: 'PROPERTY.Average'},
      );
      }
    }
    return ret;
  }

  public getSourceList(limit?: string): ISelectMenuItem[] {
    const ret = [];
    if (!limit) {
      ret.push(
        { id: PropertySource.Manual, description: 'PROPERTY.Manual' },
        { id: PropertySource.Hybrid, description: 'PROPERTY.Hybrid' },
        { id: PropertySource.Derived, description: 'PROPERTY.Derived' },
        { id: PropertySource.OverTime, description: 'PROPERTY.OverTime' },
        { id: PropertySource.Pull, description: 'PROPERTY.Pull' },
        { id: PropertySource.Push, description: 'PROPERTY.Push' },
      );
    } else {
      if (limit.match(/^check:/)) {
        ret.push(
          { id: PropertySource.OverTime, description: 'PROPERTY.OverTime' },
        );
      } else if (limit.match(/^observation:/)) {
        ret.push(
          { id: PropertySource.Derived, description: 'PROPERTY.Derived' },
        );
      } else if (limit === PropertyCategory.Zone) {
        ret.push({ id: PropertySource.Push, description: 'PROPERTY.Push' });
      } else {
        ret.push(
          { id: PropertySource.Manual, description: 'PROPERTY.Manual' },
          { id: PropertySource.Hybrid, description: 'PROPERTY.Hybrid' },
          { id: PropertySource.Push, description: 'PROPERTY.Push' }
        );
      }
    }

    return ret;
  }
  public getStyleList(limit?: string): ISelectMenuItem[] {
    const ret = [];
    if (!limit) {
    ret.push (
      { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
      { id: PropertyStyle.Text, description: 'PROPERTY.Text' },
      { id: PropertyStyle.TextArea, description: 'PROPERTY.TextArea' },
      { id: PropertyStyle.Note, description: 'PROPERTY.Note' },
      { id: PropertyStyle.Flipswitch, description: 'PROPERTY.Flipswitch' },
      { id: PropertyStyle.Duration, description: 'PROPERTY.Duration' },
      { id: PropertyStyle.Timestamp, description: 'MGMT_DETAILS.Date' },
      { id: PropertyStyle.Measurement, description: 'PROPERTY.Measurement' },
      { id: PropertyStyle.Counter, description: 'PROPERTY.Counter' }
    );
    } else if (limit.match(/^check:/)) {
      ret.push (
        { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
      );
    } else if (limit.match(/^observation:/)) {
      ret.push (
        { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
      );
    } else if (limit === PropertyCategory.InService) {
      ret.push (
        { id: PropertyStyle.UpDown, description: 'PROPERTY.UpDown' },
        { id: PropertyStyle.UpStrainedDown, description: 'PROPERTY.UpStrainedDown' },
      );
    } else if (limit === PropertyCategory.Numeric) {
      ret.push (
        { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
        { id: PropertyStyle.Measurement, description: 'PROPERTY.Measurement' },
        // { id: PropertyStyle.Counter, description: 'PROPERTY.Counter' }
      );
    } else if (limit === PropertyCategory.Text) {
      ret.push (
        { id: PropertyStyle.Text, description: 'PROPERTY.Text' },
        { id: PropertyStyle.TextArea, description: 'PROPERTY.TextArea' },
        // { id: PropertyStyle.Note, description: 'PROPERTY.Note' }
      );
    }
    return ret;
  }

  public getCategoryList(value?: string[] | string): ISelectMenuItem[] {
    if (isUndefined(value)) {
      value = [];
    } else if ( !isArray(value)) {
      value = [ value ];
    }

    const cats = [];
    if (!value.length || indexOf(value, PropertySubject.Asset) > -1) {
      cats.push( { id: PropertyCategory.InService, description: 'PROPERTY.InService'} );
    }

    cats.push(
      { id: PropertyCategory.Numeric, description: 'PROPERTY.Numeric'},
      { id: PropertyCategory.Text, description: 'PROPERTY.Text'},
      { id: PropertyCategory.Timestamp, description: 'SHARED.Timestamp'}
    );

    if (this.subscriberService.usesModule(Module.CHECKS)) {
      cats.push(
      { id: PropertyCategory.CompletedCheck, description: 'PROPERTY.CompletedCheck'},
      { id: PropertyCategory.MissedCheck, description: 'PROPERTY.MissedCheck'},
      { id: PropertyCategory.SkippedCheck, description: 'PROPERTY.SkippedCheck'},
      { id: PropertyCategory.PassedCheck, description: 'PROPERTY.PassedCheck'},
      { id: PropertyCategory.FailedCheck, description: 'PROPERTY.FailedCheck'},
      );
    }

    if (this.subscriberService.usesModule(Module.OBSERVATIONS)) {
      cats.push(
      { id: PropertyCategory.OpenObservations, description: 'PROPERTY.OpenObservations'},
      { id: PropertyCategory.UnassignedObservations, description: 'PROPERTY.UnassignedObservations'});
    }

    cats.push({
      id: PropertyCategory.Zone,
      description: 'SHARED.Zone'
    });

    return cats;
  }

  public getSubjectList(): ISelectMenuItem[] {
    const subjects = [
      {
        id: PropertySubject.Content,
        description: 'CONTENT.Module_Name'
      },
      {
        id: PropertySubject.Asset,
        description: 'SHARED.Asset'
      },
      // {
      //   id: PropertySubject.Worker, // TODO [azyulikov] will be implemented in next release
      //   description: 'SHARED.User'
      // },
      // {
      //   id: PropertySubject.Zone,
      //   description: 'SHARED.Zone'
      // },
      // {
      //   id: PropertySubject.Location,
      //   description: 'SHARED.Location'
      // }
    ];
    return subjects;
  }

  public getCategoryName(category: PropertyCategory): string {
    let ret = 'SHARED.None';

    switch (category) {
      case PropertyCategory.Text: {
        ret = 'PROPERTY.Text';
        break;
      }
      case PropertyCategory.Numeric: {
        ret = 'PROPERTY.Numeric';
        break;
      }
      case PropertyCategory.InService: {
        ret = 'PROPERTY.InService';
        break;
      }
      case PropertyCategory.MissedCheck: {
        ret = 'PROPERTY.MissedCheck';
        break;
      }
      case PropertyCategory.SkippedCheck: {
        ret = 'PROPERTY.SkippedCheck';
        break;
      }
      case PropertyCategory.CompletedCheck: {
        ret = 'PROPERTY.CompletedCheck';
        break;
      }
      case PropertyCategory.FailedCheck: {
        ret = 'PROPERTY.FailedCheck';
        break;
      }
      case PropertyCategory.PassedCheck: {
        ret = 'PROPERTY.PassedCheck';
        break;
      }
      case PropertyCategory.OpenObservations: {
        ret = 'PROPERTY.OpenObservations';
        break;
      }
      case PropertyCategory.UnassignedObservations: {
        ret = 'PROPERTY.UnassignedObservations';
        break;
      }
      case PropertyCategory.Zone: {
        ret = 'PROPERTY.Zone';
        break;
      }
      case PropertyCategory.ReviewDate: {
        ret = 'PROPERTY.Review_Date';
        break;
      }
      case PropertyCategory.Timestamp: {
        ret = 'PROPERTY.Timestamp';
        break;
      }
      case PropertyCategory.Reviewers: {
        ret = 'PROPERTY.Reviewers';
        break;
      }
    }
    return ret;
  }

  public isEventHistoryAvailable(property: IProperty): boolean {
    return includes([PropertySource.Manual, PropertySource.Derived, PropertySource.Hybrid], property.source);
  }

  public canAddManualPropertyEvent(property: IProperty): boolean {
    return (this.canViewManualProperty(property) || this.isInService(property)) &&
      this.isUserAllowedToAddEntry(property) &&
      !!property?.manualInput?.addEvent?.enabled;
  }

  public canViewManualProperty(property: IProperty): boolean {
    return includes([PropertyCategory.Numeric, PropertyCategory.Text, PropertyCategory.Timestamp], property?.category) &&
      this.isManualSource(property);
  }

  public isManualSource(property: IProperty): boolean {
    return includes([PropertySource.Manual, PropertySource.Hybrid], property?.source);
  }

  public isUserAllowedToAddEntry(property: IProperty) {
    const { permissions, teams, users, types } = (property?.manualInput?.addEvent?.permissions || {});
    let isValid = false;

    if (users?.length && includes(users, this.userData.userID)) {
      isValid = true;
    }

    if (isValid && permissions?.length) {
      isValid = some(permissions, (permission) => {
        return this.permissionService.canView(permission);
      })
    }

    if (isValid && teams?.length) {
      isValid = intersection(teams, this.userData.teams).length > 0;
    }

    if (isValid && types?.length) {
      isValid = includes(types, this.userData.type);
    }

    return isValid || this.permissionService.canView(Permission.Corvex);
  }

  public isInService(property: IProperty): boolean {
    return this.isManualSource(property) && property?.category === PropertyCategory.InService;
  }

  public getManualEntryPropertyValue(valueObject: IPropertyEventValue) {
    if (!valueObject) {
      return '';
    }

    const style = valueObject.style;

    if (style === PropertyStyle.Measurement) {
      const measurementData = (valueObject as IPropertyEventMeasurementValue);
      return `${measurementData[measurementData.units]} ${this.getMeasurementTitle(measurementData.units)}`;
    } else if (style === PropertyStyle.Timestamp) {
      return this.utils.dateTimeFormat(+valueObject.value / 1000, null, false, true);
    } else if (style === PropertyStyle.Duration) {
      const durationData = (valueObject as IPropertyEventDurationValue);
      return `${durationData.value} ${durationData.value_unit}`;
    } else if (style === PropertyStyle.Flipswitch) {
      return (valueObject as IPropertyEventValue).value ? this.translate.instant('SHARED.Yes') : this.translate.instant('SHARED.No');
    } else if (style === PropertyStyle.TextArea) {
      return this.markdownService.parse(valueObject.value.toString());
    }

    return valueObject?.value ?? valueObject?.note ?? valueObject;
  }

  public getMeasurementTitle(unitId: string): string {
    const unit = find(IncrementEntryFieldsModel.UTILS, { id: unitId });
    return unit?.description ? this.translate.instant(unit?.description) : unitId;
  }
}
