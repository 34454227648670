import { IObjectStringKeyMap } from '@shared/models';
import { AccountTypes, Permission } from '@services';

export enum LimitType {
  Healthy = 'healthy',
  Strained = 'strained'
}

export enum ThresholdType {
  Toggle = 'boolean',
  Selector = 'selector',
  Ascending = 'ascendingNumeric',
  Descending = 'descendingNumeric'
}

export enum PropertyMethod {
  Latest = 'latest',
  Highest = 'highest',
  Lowest = 'lowest',
  Cumulative = 'cumulative',
  Average = 'average'
}

export interface IThresholdLimit {
  type: LimitType;
  limit: number;
}

export interface IThresholds {
  type: ThresholdType;
  limits: IThresholdLimit[];
}

export enum PropertyStyle {
  Numeric = 'number',
  Text = 'text',
  TextArea = 'textarea',
  Note = 'note',
  Flipswitch = 'flipswitch',
  Duration = 'duration',
  Timestamp = 'timestamp',
  Measurement = 'measurement',
  Counter = 'counter',
  UpDown = 'upDown',
  UpStrainedDown = 'upStrainedDown'
}

export enum PropertyCategory {
  Text = 'text',
  Numeric = 'number',
  InService = 'asset:inService',
  MissedCheck = 'check:missed',
  SkippedCheck = 'check:skipped',
  CompletedCheck = 'check:completed',
  FailedCheck = 'check:failed',
  PassedCheck = 'check:passed',
  OpenObservations = 'observation:open',
  UnassignedObservations = 'observation:unassigned',
  Zone = 'zone',
  Timestamp = 'timestamp',
  ReviewDate = 'review',
  Reviewers = 'reviewers',
  ViewCount = 'content:views'
}

export enum PropertySource {
  Manual = 'manual',
  Hybrid = 'hybrid',
  Derived = 'derived',
  OverTime = 'overTime',
  Pull = 'pull',
  Push = 'push'
}

export enum PropertySubject {
  Asset = 'asset',
  Worker = 'worker',
  Zone = 'zone',
  Location = 'location',
  Content = 'content'
}

export interface IPropertyEvent {
  time: number;
  userID: number;
  subject: PropertySubject;
  subjectID: string;
  activity: string;
  value: PropertyEventValue;
  id: number;
  propertyID: number;
}

export type PropertyEventValue = IPropertyEventValue | string;

export interface IPropertyEventValue {
  value: IObjectStringKeyMap<string> | string;
  style: PropertyStyle;
  note?: string;
}

export interface IPropertyEventMeasurementValue extends IPropertyEventValue {
  units: string;
}

export interface IPropertyEventDurationValue extends IPropertyEventValue {
  value: string;
  value_unit: string;
}

export interface IPropertyEventActivity {
  from: string;
  id: number;
  type: string;
}

export type PropertyEventActivity = IPropertyEventActivity | string;

export interface IPropertyHistoryEntry {
  time: number;
  userID: number;
  activity: string;
  notes: string;
}

export interface IProperty {
  propertyID: number;
  lastUpdate: number;
  addedBy: number;
  addedAt: number;
  active: number;
  healthRelated: number;
  thresholds: IThresholds;
  canAggregate: number;
  subjects: PropertySubject[];
  source: PropertySource;
  method: PropertyMethod;
  timespan: number;
  category: PropertyCategory;
  uniqueName: string;
  style: PropertyStyle;
  folderID: number;
  title: string;
  description: string;
  events: IPropertyEvent[];
  history: IPropertyHistoryEntry[];
  disabledAt: number;
  disabledBy: number;
  manualInput?: PropertyManualInput;
}

export interface PropertyManualInput {
  observations: {
    add: PropertyManualInputObservation;
    fix: PropertyManualInputObservation;
  },
  addEvent: PropertyManualInputEvent;
  label: {
    [language: string]: string;
  },
  settings?: PropertyManualSettings;
}

export interface PropertyManualInputBase {
  enabled: number;
  permissions: {
    types: AccountTypes[];
    permissions: Permission[];
    teams: number[];
    users: string[];
  }
}

export interface PropertyManualSettings {
  units: string;
  startValue: string;
  increment: string;
  fractionIncrement: string;
}

export interface PropertyManualInputObservation extends PropertyManualInputBase {
  required: string;
}

export interface PropertyManualInputEvent extends PropertyManualInputBase {
  note: 'disabled' | 'optional' | 'required';
}

export interface IPropertyEventRequestsParams {
  propertyID?: number;
  uniqueName?: string;
  subject?: PropertySubject;
  subjectID?: number;
  activity?: string;
  value?: string | IPropertyEventValue;
  events?: IPropertyEventRequestParams[];
}

export interface IPropertyEventRequestParams {
  value: string;
  subject: PropertySubject;
  subjectID: number;
  activity: string;
}
