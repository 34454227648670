import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { VersionCheckService } from './../versionCheck/version-check.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppVersionGuard implements CanActivate {

  constructor(private versionCheckService: VersionCheckService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
    // return this.versionCheckService.startListening();
  }

}
