import { Injectable } from '@angular/core';
import { Module, SubscriberService } from '@services/subscriber/subscriber.service';
import { environment } from '@env';
import { Title } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { each, get, has, indexOf } from 'lodash';

export enum Brands {
  CORVEX = 'corvex',
  HOP = 'hop',
  INTELIFORZ = 'inteliforz'
}

@Injectable({
  providedIn: 'root'
})

export class BrandService {

  private moduleMap = {
    [Brands.HOP] : [
      Module.ACCESS_TOKENS,
      Module.ACTIVE_LEARNING,
      Module.AWARENESS_MESSAGES,
      Module.CONSENT,
      Module.CONTENT,
      Module.EXPLORE,
      Module.EXTERNAL_DATA_MANAGEMENT,
      Module.FEEDBACK,
      Module.INQUIRIES,
      Module.LDAP,
      Module.LEARNING_TEAMS,
      Module.LOCATIONS,
      Module.LOCATION_HIERARCHY,
      Module.LOYALTY,
      Module.MESSAGING,
      Module.NOTIFICATIONS,
      Module.OBSERVATIONS,
      Module.OFFLINE_SUPPORT,
      Module.OPPORTUNITIES,
      Module.REPORTING,
      Module.SITE_AWARENESS ,
      Module.SSO,
      Module.TOPICS,
      Module.WORK_NOTES
    ],
    [Brands.INTELIFORZ]: [
      Module.ACCESS_TOKENS ,
      Module.ASSETS ,
      Module.ASSET_ISSUES,
      Module.ASSET_TRACKING,
      Module.AWARENESS_MESSAGES ,
      Module.BEHAVIOR ,
      Module.CERTIFICATIONS ,
      Module.CHECKS ,
      Module.COMPLIMENT ,
      Module.CONDITION ,
      Module.CONSENT ,
      Module.CONTENT ,
      Module.CULTURE ,
      Module.DISCLAIMERS ,
      Module.LDAP ,
      Module.LEADERBOARDS ,
      Module.LOCATIONS ,
      Module.LOCATION_HIERARCHY ,
      Module.LOGIN_FOOTER ,
      Module.LOYALTY ,
      Module.MESSAGING ,
      Module.MOBILE_REPORTS ,
      Module.NOTIFICATIONS ,
      Module.NO_LOCATION ,
      Module.OBSERVATIONS ,
      Module.OBSERVATION_WORKORDERS ,
      Module.PROPERTIES,
      Module.PPE ,
      Module.PROCESS_IMPROVEMENT ,
      Module.QUALITY ,
      Module.REPORTING ,
      Module.ROLES ,
      Module.SITE_AWARENESS ,
      Module.SSO ,
      Module.SUPERVISOR_DASHBOARD ,
      Module.WORKER_STATUS ,
      Module.WORK_NOTES
    ],
    [Brands.CORVEX] : [
      Module.ACCESS_TOKENS ,
      Module.ACTIVE_LEARNING ,
      Module.ASSETS ,
      Module.ASSET_ISSUES,
      Module.ASSET_TRACKING,
      Module.ASSET_STATUS,
      Module.AWARENESS_MESSAGES ,
      Module.BEHAVIOR ,
      Module.CALERA_CORE,
      Module.CERTIFICATIONS ,
      Module.CHECKS ,
      Module.COMPLIMENT ,
      Module.CONDITION ,
      Module.CONSENT ,
      Module.CONTENT ,
      Module.CONTENT_REVIEW,
      Module.CULTURE ,
      Module.DISCLAIMERS,
      Module.EXPLORE ,
      Module.EXTERNAL_DATA_MANAGEMENT ,
      Module.FEEDBACK ,
      Module.INQUIRIES ,
      Module.LDAP ,
      Module.LEADERBOARDS ,
      Module.LEARNING_TEAMS ,
      Module.LOCATIONS ,
      Module.LOCATION_HIERARCHY ,
      Module.LOGIN_FOOTER ,
      Module.LOYALTY ,
      Module.MESSAGING ,
      Module.MOBILE_REPORTS ,
      Module.NOTIFICATIONS ,
      Module.NO_LOCATION ,
      Module.OBSERVATIONS ,
      Module.OBSERVATION_MAINTAINX ,
      Module.OBSERVATION_WORKORDERS ,
      Module.OFFLINE_SUPPORT,
      Module.OPPORTUNITIES ,
      Module.PDCA ,
      Module.PPE ,
      Module.PROCESS_IMPROVEMENT ,
      Module.PROPERTIES,
      Module.PI_WAITING ,
      Module.PI_GENERAL ,
      Module.QUALITY ,
      Module.QUALITY_PRODUCTION ,
      Module.QUALITY_RECEIVING ,
      Module.QUALITY_RMA ,
      Module.RELAYS ,
      Module.REPORTING ,
      Module.ROLES ,
      Module.SITE_AWARENESS ,
      Module.SSO ,
      Module.SUPERVISOR_DASHBOARD ,
      Module.TOPICS ,
      Module.WORKER_PROXIMITY ,
      Module.WORKER_STATUS ,
      Module.WORK_NOTES ,
      Module.WORK_TEMP ,
      Module.WRISTBANDS
    ]
  };
  constructor(
    private subscriber: SubscriberService,
    private titleService: Title,
    private logger: NGXLogger
  ) { }

  public canUseModule(name: Module | Module[]): boolean {
    // look up the module list for the brand;
    const theBrand = this.branding();
    if (has(this.moduleMap, theBrand)) {
      if (!Array.isArray(name)) {
        name = [ name ];
      }
      let matched = false;
      each(name, item => {
        if (indexOf(this.moduleMap[theBrand], item) > -1) {
          matched = true;
        }
      });
      return matched;
    } else {
      return false;
    }
  }

  public brandInfo(): any {
    const rootElement: HTMLElement | any = document.documentElement;
    const theBrand = this.branding();

    let data: any = {
      brand: 'corvex',
      logo: '/assets/brand/corvex/logo.svg',
      headerLogo: '/assets/brand/corvex/header_logo.svg',
      loaderImg: '/assets/brand/corvex/loader_initialize_solid.gif',
      title: 'Corvex Connected Worker'
    };

    if (theBrand === 'hop') {
      data = {
        brand: 'hop',
        logo: '/assets/brand/hop/HOP-main.svg',
        headerLogo: '/assets/brand/hop/header_logo.svg',
        loaderImg: '/assets/brand/hop/gathering-data.gif',
        title: 'HOPtimize',
        backgrounds: ['HOP-slide-1.jpg', 'HOP-slide-2.jpg', 'HOP-slide-3.jpg', 'HOP-slide-4.jpg'],
        vars: {
          '--ccs-toolbar-background': '#414141',
          '--ccs-menu-background': '#343434',
          '--ccs-menu-child-background': '#232323'
        }
      };
    }
    if (theBrand === 'inteliforz') {
      data = {
        brand: 'inteliforz',
        logo: '/assets/brand/inteliforz/inteliforz-full.svg',
        headerLogo: '/assets/brand/inteliforz/inteliforz.svg',
        loaderImg: '/assets/brand/inteliforz/gathering-data.gif',
        backgrounds: ['slide-1.jpg', 'slide-2.jpg', 'slide-3.jpg'],
        title: 'Inteliforz Zone Manager',
        vars: {
          '--ccs-toolbar-background': '#414141',
          '--ccs-menu-background': '#343434',
          '--ccs-menu-child-background': '#232323'
        }
      };
    }
    if (rootElement && has(data, 'vars')) {
      each(data.vars, (value, name) => {
        rootElement.style.setProperty(name, value);
      });
    }

    if (has(data, 'title')) {
      this.titleService.setTitle(data.title);
    }

    return data;
  }

  public branding(): string {
    if (has(window, 'corvexBrand')) {
      return get(window, 'corvexBrand') as string;
    } else {
      return get(environment.data, 'brand', 'corvex');
    }
  }
}
