import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';

import { DateRangePickerComponent } from '@shared/components';
import { ICheck, ICheckGroup } from '@modules/management/pages/details/check/models';
import { FolderDataType } from '@modules/management/modules/folders/services/folders.service';
import { DeploymentPage } from '@modules/management/pages/details/check/pages/deployment/deployment.page';
import {
  FolderPickerComponent
} from '@modules/management/modules/folder-picker/components/folder-picker/folder-picker.component';
import {
  AccountsService,
  AssetsService,
  ChecksService,
  FormBuilderService,
  FormField,
  LoadingService,
  NativeElementService,
  Permission,
  PermissionsService,
  SettingsService,
  SubscriberService,
  TeamsService,
  UserdataService,
  UserService,
  UtilsService,
  ViewerService,
  ZoneService
} from '@services';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import {
  assign,
  each,
  filter,
  find,
  findIndex,
  flatten,
  get,
  has,
  includes,
  indexOf,
  intersection,
  isArray,
  isEmpty,
  isObject,
  isUndefined,
  keys,
  last,
  map,
  orderBy,
  pick,
  reject,
  set,
  split,
  times,
  uniq
} from 'lodash';
import { IObjectStringKeyMap } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class DeploymentFormService {

  public typeOptions = [
    {
      id: 'assets',
      description: this.translate.instant('SHARED.ASSETS')
    },
    {
      id: 'zones',
      description: this.translate.instant('SHARED.Zones')
    },
    {
      id: 'workers',
      description: this.translate.instant('SHARED.Workers')
    }
  ];
  private deploymentPage: DeploymentPage;
  private deploymentType: string;
  private reminderTriggers = [
    {
      id: 'expiring',
      description: this.translate.instant('MGMT_DETAILS.Remind_Expiring'),
      exclude: ['onDemand']
    },
    {
      id: 'missed',
      description: this.translate.instant('SHARED.Missed'),
      exclude: ['onDemand']
    },
    {
      id: 'completed',
      description: this.translate.instant('MGMT_DETAILS.Remind_Completed')
    },
    {
      id: 'negative',
      description: this.translate.instant('MGMT_DETAILS.Remind_Negative')
    },
  ];
  private presentationStyles = [
    {
      id: 'guided',
      description: this.translate.instant('MGMT_DETAILS.Guided')
    },
    {
      id: 'unordered',
      description: this.translate.instant('MGMT_DETAILS.Unordered'),
    }
  ];
  public fields = [
    {
      containerClass: 'type-selector',
      title: 'MGMT_DETAILS.Select_Deployment_Type',
      type: 'subtitle'
    },
    {
      containerClass: 'type-selector',
      title: 'SHARED.Deployment_Type',
      name: 'type',
      canClear: false,
      originalOrder: true,
      type: 'selectmenu',
      role: 'none',
      placeholder: this.translate.instant('MGMT_DETAILS.Select_Deployment_Type'),
      options: this.typeOptions,
      onChange: (type: string) => this.syncFieldsVisibilityByType(type)
    },
    {
      containerClass: 'deploy-field assets zones workers field-disable',
      required: false,
      inputtype: 'verbatim',
      name: 'disabled-type',
      title: 'SHARED.Deployment_Type',
      type: 'text',
      description: 'MGMT_DETAILS.Target_Description',
    },
    {
      containerClass: 'deploy-field assets zones workers',
      required: true,
      inputtype: 'verbatim',
      name: 'title',
      title: 'MGMT_DETAILS.Title',
      type: 'text'
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'MGMT_LIST.Deployment_Active',
      name: 'active',
      type: 'flipswitch',
      value: 1,
      default: 0,
      disabledFieldNames: ['availableImmediately']
    },
    {
      containerClass: 'deploy-field assets',
      title: 'MGMT_DETAILS.Asset_Target',
      type: 'subtitle',
      description: 'MGMT_DETAILS.Target_Description'
    },
    {
      containerClass: 'deploy-field zones',
      title: 'MGMT_DETAILS.Zone_Target',
      type: 'subtitle',
      description: 'MGMT_DETAILS.Target_Description'
    },
    {
      containerClass: 'deploy-field workers',
      title: 'MGMT_DETAILS.Worker_Target',
      type: 'subtitle',
      description: 'MGMT_DETAILS.Target_Description'
    },
    {
      containerClass: 'deploy-field assets zones workers target-field',
      title: this.translate.instant('SHARED.Locationss'),
      name: 'locations',
      type: 'selectmenu',
      multiple: true,
      placeholder: this.translate.instant('SHARED.All_Locations'),
      valueProperty: 'locationID',
      options: [],
      func: (ref) => ref.name,
      onChange: (locationIds: any[] = []) => {
        this.resetAssetsFolders();
        this.onLocationChange(locationIds);
      }
    },
    {
      containerClass: 'deploy-field workers target-field',
      title: 'SHARED.Team(s)',
      name: 'teams',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      required: false,
      searchable: true,
      canClear: true,
      originalOrder: true,
      valueProperty: 'groupID',
      options: [],
      test: (team: any) => !get(team, 'disabledAt') && team.groupID !== -1,
      func: (team: any) => this.teamsService.teamName(team),
      onChange: () => this.syncUsersField()
    },
    // {
    //   containerClass: 'deploy-field workers target-field',
    //   name: 'certifications',
    //   title: 'SHARED.Certification(s)',
    //   placeholder: this.translate.instant('SHARED.Any_Certification'),
    //   type: 'selectmenu',
    //   multiple: true,
    //   valueProperty: 'certificationID',
    //   options: this.certificationsService.certifications,
    //   test: (ref) => {
    //     return !get(ref, 'disabledAt');
    //   },
    //   func: (certification: any) => this.formBuilderService.certificationName(certification)
    // },
    // {
    //   containerClass: 'deploy-field workers target-field',
    //   name: 'roles',
    //   title: 'MGMT_DETAILS.Roles',
    //   type: 'selectmenu',
    //   placeholder: this.translate.instant('MGMT_DETAILS.Any_Role'),
    //   multiple: true,
    //   valueProperty: 'roleID',
    //   options: this.roleService.roles.data,
    //   test: (ref) => !get(ref, 'disabledAt'),
    //   func: (role: any) => this.roleService.roleName(role),
    //   onChange: () => this.syncUsersField()
    // },
    {
      containerClass: 'deploy-field workers target-field',
      title: 'SHARED.EDIT_Permissions',
      name: 'permissions',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.All_Permissions'),
      multiple: true,
      required: false,
      searchable: true,
      canClear: true,
      valueProperty: 'id',
      options: this.permissionsService.permissions.data,
      onChange: () => this.syncUsersField(),
      test: (ref) => this.filterPermission(ref)
    },
    {
      containerClass: 'deploy-field workers target-field',
      title: 'SHARED.User(s)',
      name: 'users',
      type: 'selectmenu',
      multiple: true,
      placeholder: this.translate.instant('SHARED.All_Users'),
      valueProperty: 'userID',
      options: [],
      func: (ref) => this.userService.getFullname(ref.userID),
      test: (ref) => !get(ref, 'disabledAt') && !includes([-1, -2], ref.userID),
      onChange: () => {
        const divideAssignmentField: any = find(this.fields, {name: 'divideAssignment'});

        if (divideAssignmentField && divideAssignmentField.getValue()) {
          const checkGroups = get(this.checkService.getCheck(this.deploymentPage.checkId), 'checkGroups') || [];
          each(checkGroups, (checkGroup, index: number) => {
            this.syncUsersField(true, ++index);
          });
        } else {
          this.syncUsersField(true);
        }
      }
    },
    {
      containerClass: 'deploy-field assets zones target-field',
      title: 'SHARED.Zone(s)',
      name: 'zones',
      type: 'selectmenu',
      canClear: false,
      required: false,
      multiple: true,
      placeholder: this.translate.instant('SHARED.Any_Zone'),
      options: [],
      onChange: () => this.resetAssetsFolders()
    },
    {
      containerClass: 'deploy-field assets target-field asset-folders',
      title: 'SHARED.Assets',
      name: 'assetsFolders',
      type: 'customElement',
      component: FolderPickerComponent,
      inputs: {
        type: FolderDataType.ASSET,
        getSourceItems: () => this.syncAssetsField()
      }
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: '',
      type: 'divider'
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'MGMT_DETAILS.Deployment_Assign_To_Title',
      type: 'subtitle',
      description: 'MGMT_DETAILS.Deployment_Assign_To_Description'
    },
    /*     {
          containerClass: 'deploy-field assets zones workers',
          title: 'MGMT_DETAILS.Divide_Assignment',
          name: 'divideAssignment',
          type: 'flipswitch',
          value: 1,
          default: 0,
          onChange: () => this.onDivideAssignmentGroupsChange()
        }, */
    {
      containerClass: 'deploy-field assets zones workers assign-field',
      title: 'SHARED.Team(s)',
      name: 'assignedTo_teams',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      required: false,
      searchable: true,
      originalOrder: true,
      canClear: true,
      valueProperty: 'groupID',
      options: [],
      test: (team: any) => !get(team, 'disabledAt'),
      func: (team: any) => this.teamsService.teamName(team),
      onChange: (ids) => {
        this.removeWorkerAssignAnyOption(ids, 'assignedTo_teams');
        this.syncUsersField(true);
      }
    },
    {
      containerClass: 'deploy-field assets zones workers assign-field',
      title: 'MGMT_DETAILS.Allow_Secondary_Teams',
      name: 'assignedTo_anyTeam',
      type: 'flipswitch',
      value: 1,
      default: 0,
      onChange: () => {
      }
    },
    {
      containerClass: 'deploy-field assets zones workers assign-field',
      title: 'SHARED.EDIT_Permissions',
      name: 'assignedTo_permissions',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Permissions'),
      multiple: true,
      required: false,
      searchable: true,
      canClear: true,
      valueProperty: 'id',
      options: this.permissionsService.permissions.data,
      onChange: (ids: string[]) => {
        this.removeWorkerAssignAnyOption(ids, 'assignedTo_permissions');
        this.syncUsersField(true);
      },
      test: (ref) => this.filterPermission(ref)
    },
    // {
    //   containerClass: 'deploy-field assets zones workers assign-field',
    //   name: 'assignedTo_certifications',
    //   title: 'SHARED.Certification(s)',
    //   placeholder: this.translate.instant('SHARED.Any_Certification'),
    //   type: 'selectmenu',
    //   multiple: true,
    //   valueProperty: 'certificationID',
    //   options: this.certificationsService.certifications,
    //   test: (ref) => {
    //     return !get(ref, 'disabledAt');
    //   },
    //   func: (certification: any) => this.formBuilderService.certificationName(certification)
    // },
    // {
    //   containerClass: 'deploy-field assets zones workers assign-field',
    //   name: 'assignedTo_roles',
    //   title: 'MGMT_DETAILS.Roles',
    //   type: 'selectmenu',
    //   placeholder: this.translate.instant('MGMT_DETAILS.Any_Role'),
    //   multiple: true,
    //   valueProperty: 'roleID',
    //   options: this.roleService.roles,
    //   test: (ref) => {
    //     return !get(ref, 'disabledAt');
    //   },
    //   func: (role: any) => this.roleService.roleName(role),
    //   onChange: () => this.syncUsersField(true)
    // },
    {
      containerClass: 'deploy-field assets zones workers assign-field',
      title: 'SHARED.User(s)',
      name: 'assignedTo_users',
      type: 'selectmenu',
      multiple: true,
      placeholder: this.translate.instant('SHARED.Any_User'),
      valueProperty: 'userID',
      originalOrder: true,
      options: [],
      func: (ref) => this.getUserName(ref),
      test: (ref) => !get(ref, 'disabledAt'),
      onChange: () => {
        this.syncRemindWho();
        this.onSendReminderChange();
      }
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'MGMT_DETAILS.Completed_Check_Required_Groups',
      name: 'requireAllGroups',
      type: 'flipswitch',
      value: 1,
      default: 0,
      onChange: () => {
      }
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: '',
      type: 'divider'
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'MGMT_DETAILS.Frequency',
      type: 'subtitle',
      description: 'MGMT_DETAILS.Frequency_Description'
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'MGMT_DETAILS.Frequency',
      name: 'frequency',
      type: 'selectmenu',
      required: true,
      originalOrder: true,
      canClear: false,
      default: 'daily',
      options: [
        {
          id: 'daily',
          description: this.translate.instant('SHARED.Daily')
        },
        {
          id: 'weekly',
          description: this.translate.instant('SHARED.Weekly')
        },
        {
          id: 'monthly',
          description: this.translate.instant('SHARED.Monthly')
        },
        {
          id: 'quarterly',
          description: this.translate.instant('SHARED.Quarterly')
        },
        {
          id: 'yearly',
          description: this.translate.instant('SHARED.Yearly')
        },
        {
          id: 'once',
          description: this.translate.instant('SHARED.Once')
        }
      ],
      onChange: () => this.onFrequencyChange(),
      hideFieldsOnValue: {
        select: [
          {
            hideClasses: ['at-login-response'],
            valueFunction: (frequency) => this.isAtLoginResponseInvalid({ frequency })
          }
        ]
      }
    },
    {
      name: 'frequencyOnceTime',
      type: 'customElement',
      component: DateRangePickerComponent,
      containerClass: 'deploy-field workers frequency-field once',
      inputs: {
        isTimePicker: true,
        useUTC: true
      },
      required: true
    },
    {
      title: 'MGMT_DETAILS.At_Login',
      name: 'atLogin',
      type: 'flipswitch',
      value: 1,
      default: 0,
      containerClass: 'deploy-field workers frequency-field weekly monthly quarterly yearly once',
      hideFieldsOnValue: {
        select: [
          {
            hideClasses: ['at-login-response'],
            valueFunction: (atLogin) => this.isAtLoginResponseInvalid({ atLogin })
          }
        ]
      }
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field daily',
      title: 'MGMT_DETAILS.Checks_Per_Day',
      name: 'checksPerDay',
      type: 'selectmenu',
      originalOrder: true,
      canClear: false,
      default: '1',
      options: this.getChecksCountOptions(),
      onChange: (value: string) => this.onTimesPerDayChange(value),
      hideFieldsOnValue: {
        select: [
          {
            hideClasses: ['at-login-response'],
            valueFunction: (checksPerDay) => this.isAtLoginResponseInvalid({ checksPerDay })
          }
        ]
      }
    },
    {
      containerClass: 'deploy-field workers frequency-field daily weekly monthly quarterly yearly once at-login-response',
      title: '',
      name: 'atLoginRequiredResponse',
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      break: true,
      options: [
        {
          id: 1,
          description: 'MGMT_DETAILS.At_Login_Response'
        }
      ]
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field daily',
      title: 'MGMT_DETAILS.Select_Days_To_Perform',
      name: 'daily',
      type: 'checkbox',
      role: 'none',
      required: true,
      class: 'inline-checkboxes',
      break: true,
      options: this.getDays()
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field daily',
      title: 'MGMT_DETAILS.Make_Available_When',
      name: 'startingTime',
      type: 'selectmenu',
      required: false,
      originalOrder: true,
      canClear: false,
      default: '0',
      options: this.getStartingTimes(),
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field daily',
      title: 'MGMT_DETAILS.Available_How_Long',
      name: 'duration',
      type: 'selectmenu',
      required: false,
      originalOrder: true,
      canClear: false,
      default: '0',
      options: this.getDurations(),
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field daily weekly monthly quarterly yearly',
      title: 'MGMT_DETAILS.Available_Immediately',
      name: 'availableImmediately',
      type: 'flipswitch',
      value: 1,
      default: 0,
      canView: Permission.SuperAdmin
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field weekly',
      title: 'MGMT_DETAILS.Select_Week_Day_To_Start',
      name: 'weekly',
      type: 'radio',
      class: 'inline-checkboxes',
      default: 'sun',
      break: true,
      options: this.getDays()
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field monthly',
      title: 'MGMT_DETAILS.Select_Time_Month',
      name: 'monthly',
      type: 'radio',
      class: 'inline-checkboxes',
      default: 'beginning',
      break: true,
      options: [
        {
          id: 'all',
          description: this.translate.instant('MGMT_DETAILS.Entire_Month')
        },
        {
          id: 'beginning',
          description: this.translate.instant('MGMT_DETAILS.Beginning_Of_Month')
        },
        {
          id: 'end',
          description: this.translate.instant('MGMT_DETAILS.End_Of_Month')
        }
      ]
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field quarterly',
      title: 'MGMT_DETAILS.Select_Time_Quarter',
      name: 'quarterly',
      type: 'radio',
      class: 'inline-checkboxes',
      default: 'first',
      break: true,
      options: [
        {
          id: 'all',
          description: this.translate.instant('MGMT_DETAILS.All_Quarter')
        },
        {
          id: 'first',
          description: this.translate.instant('MGMT_DETAILS.First_Month')
        },
        {
          id: 'second',
          description: this.translate.instant('MGMT_DETAILS.Second_Month')
        },
        {
          id: 'third',
          description: this.translate.instant('MGMT_DETAILS.Third_Month')
        }
      ]
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field yearly',
      title: 'MGMT_DETAILS.Select_Month_Perform',
      name: 'yearly',
      type: 'radio',
      class: 'inline-checkboxes',
      default: 'jan',
      break: true,
      options: this.getMonths()
    },
    {
      title: 'MGMT_DETAILS.Send_Reminder_Notifications',
      name: 'reminderNotification',
      type: 'flipswitch',
      value: 1,
      default: 0,
      containerClass: 'deploy-field assets zones workers frequency-field daily weekly monthly quarterly yearly onDemand once',
      onChange: () => this.onSendReminderChange()
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field daily weekly monthly quarterly yearly onDemand once',
      title: 'MGMT_DETAILS.Remind_What',
      name: 'remindWhat',
      type: 'checkbox',
      role: 'none',
      required: true,
      class: 'inline-checkboxes',
      break: true,
      options: this.reminderTriggers,
      onChange: (values, theForm) => this.onTriggerChange(values, theForm),
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field daily weekly monthly quarterly yearly onDemand once',
      title: 'MGMT_DETAILS.Remind_Who',
      name: 'remindWho',
      type: 'selectmenu',
      multiple: true,
      placeholder: this.translate.instant('MGMT_DETAILS.Select_Users'),
      valueProperty: 'userID',
      originalOrder: true,
      options: this.getNotificationList(this.getUserLocations()),
      func: (ref) => this.getUserName(ref),
      test: (ref) => {
        if (get(ref, 'disabledAt') || ref.type === 'observer' || ref.type === 'observation' || ref.type === 'reporter' || ref.type === 'viewer') {
          return false;
        } else {
          return true;
        }
      },
      onChange: (values) => null
    },
    {
      containerClass: 'deploy-field assets zones workers frequency-field daily weekly monthly quarterly yearly once',
      title: 'MGMT_DETAILS.Remind_When',
      name: 'remindWhen',
      type: 'selectmenu',
      required: false,
      originalOrder: true,
      canClear: false,
      default: '1',
      options: this.getDurations(true),
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: '',
      type: 'divider'
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'MGMT_DETAILS.Check_Presentation',
      type: 'subtitle',
      description: 'MGMT_DETAILS.Check_Presentation_Description'
    },
    // {
    //   containerClass: 'deploy-field assets zones workers', // TODO [azyulikov] add .presentation-type-field after the first iteration
    //   title: 'MGMT_DETAILS.Presentation_Type',
    //   name: 'presentationType',
    //   type: 'selectmenu',
    //   originalOrder: true,
    //   canClear: false,
    //   default: 'standard',
    //   options: [
    //     {
    //       id: 'standard',
    //       description: this.translate.instant('MGMT_DETAILS.Standard_Check')
    //     },
    //     {
    //       id: 'quick',
    //       description: this.translate.instant('MGMT_DETAILS.Quick_Check')
    //     }
    //   ],
    //   onChange: (values) => null
    // },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'MGMT_DETAILS.Presentation_Type',
      name: 'presentationStyle',
      canClear: false,
      type: 'selectmenu',
      originalOrder: true,
      default: 'guided',
      options: this.presentationStyles,
      onChange: () => null,
      infoAction: (field) => {
        const videoType = field.getValue() === 'guided' ? 'guided' : 'advanced';
        const url = `/assets/images/deploymentPresentationType/${videoType}-example.mp4`;

        this.viewerService.showByType(url, 'video/mp4');
      }
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'MGMT_DETAILS.Show_Check_History',
      name: 'showCheckHistory',
      type: 'flipswitch',
      value: 1,
      default: 0,
      onChange: () => this.onCheckHistoryChange()
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'MGMT_DETAILS.Previous_Checks',
      name: 'previousChecks',
      type: 'selectmenu',
      originalOrder: true,
      canClear: false,
      default: '7',
      options: times(7, (currentNumber: number) => {
        const id: string = (currentNumber + 1).toString();
        return {id, description: id};
      }),
      onChange: (values) => null
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: '',
      type: 'divider'
    },
    {
      containerClass: 'deploy-field assets zones workers',
      title: 'SHARED.Worker_Power_Title',
      type: 'subtitle',
      description: 'MGMT_DETAILS.Worker_Power_Description'
    },
    {
      containerClass: 'deploy-field assets zones workers completed-check-field',
      title: 'SHARED.Completed_Check',
      name: `completedCheck`,
      type: 'selectmenu',
      default: 0,
      canClear: false,
      options: times(6, (point: number) => {
        const description: string = this.translate.instant('MGMT_DETAILS.Points');
        return {id: point, description: `${point} ${description}`};
      }),
      onChange: (values) => null
    }
  ];

  private nativeElement: any;
  private readonly targetObjectMap: string[] = [
    'locations',
    'zones',
    'assets',
    'teams',
    'users',
    'permissions'
    // 'certifications',
    // 'roles',
  ];

  constructor(
    private logger: NGXLogger,
    private zoneService: ZoneService,
    private userService: UserService,
    private userDataService: UserdataService,
    private assetsService: AssetsService,
    private teamsService: TeamsService,
    private accountService: AccountsService,
    private translate: TranslateService,
    private formBuilderService: FormBuilderService,
    private settingsService: SettingsService,
    private checkService: ChecksService,
    private loadingService: LoadingService,
    private nativeElementService: NativeElementService,
    private subscriberService: SubscriberService,
    private permissionsService: PermissionsService,
    private utils: UtilsService,
    private viewerService: ViewerService
  ) {}

  public getFields(deploymentPage: DeploymentPage): any {
    this.deploymentPage = deploymentPage;
    this.nativeElement = (<any>this.deploymentPage).elementRef.nativeElement;

    this.addAssignmentGroups();
    this.updateFieldLocationData();
    this.checkFrequencyField();

    return this.fields;
  }

  public decodeData(deploymentData): any {
    const formData: any = {};

    this.decodeTarget(formData, deploymentData);
    this.decodeAssigment(formData, deploymentData);
    this.decodeFrequency(formData, deploymentData);

    formData.completedCheck = get(deploymentData, 'powerpoints');
    formData.previousChecks = get(deploymentData, 'showHistory');
    formData.requireAllGroups = get(deploymentData, 'requireAllGroups');
    formData.title = deploymentData.title;
    formData.active = deploymentData.active;
    formData.presentationStyle = deploymentData.presentationStyle;

    if (formData.previousChecks) {
      formData.showCheckHistory = 1;
    }

    this.onLocationChange(formData.locations);

    return formData;
  }

  public encodeData(formData): any {
    const formFieldKeys: string[] = [
      'title',
      'checkID',
      'target',
      'assignees',
      'frequency',
      'showHistory',
      'powerpoints',
      'requireAllGroups',
      'active',
      'presentationStyle'
    ];
    this.encodeAssigment(formData);
    this.encodeFrequency(formData);
    this.encodeTarget(formData);

    formData.showHistory = +formData.previousChecks || 0;
    formData.powerpoints = +formData.completedCheck || 0;
    formData.requireAllGroups = +formData.requireAllGroups || 0;
    formData.active = formData.active || 0;

    return pick(formData, formFieldKeys);
  }

  public setDeploymentType(type: string) {
    this.deploymentType = type;
  }

  public syncFieldsVisibilityByType(type?: string): void {
    this.deploymentType = type;
    this.formBuilderService.setElementVisibilityBy(this.nativeElement, '.deploy-field', false);

    if (type) {
      this.formBuilderService.setElementVisibilityBy(this.nativeElement, '.type-selector', false);
      this.formBuilderService.setElementVisibilityBy(this.nativeElement, `.deploy-field.${type}`, true);

      this.onDivideAssignmentGroupsChange();
      this.onFrequencyChange();
      // this.definePresentationPreviewControl(); // TODO [azyulikov] disabled for the first iteration
      this.defineCompletedCheckField();
      this.onCheckHistoryChange();
      this.onSendReminderChange(<string>this.getFieldValue('frequency'));
      this.checkRequiredAllGroupsField();
      this.onTimesPerDayChange(this.getFieldValue('checksPerDay'));

      if (type === 'zones') {
        this.updateZonesField();
      }

      if (type === 'workers') {
        this.prepareWorkerFields();
      }

      this.updateChecksPerDayFieldByType(type);
      this.formBuilderService.changeFieldValueByName(this.nativeElement, 'disabled-type', find(this.typeOptions, ['id', type]).description);
    }
    this.nativeElementService.setElementVisibilityBy(this.nativeElement, '.ui-form-controls', !!type);
  }

  private updateChecksPerDayFieldByType(type: string) {
    if (includes(['zones', 'assets'], type)) {
      const options = filter(this.getChecksCountOptions(), (option) => +option.id >= 0);
      this.updateFieldByName('checksPerDay', options);
    }
  }

  private getDays(): any[] {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const list = moment.weekdaysShort();
    const ret = [];
    each(list, (day, idx) => {
      ret.push({
        id: days[idx],
        description: day
      });
    });
    return ret;
  }

  private getMonths(): any[] {
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const monthList = moment.monthsShort();
    const ret = [];
    each(monthList, (month, idx) => {
      ret.push({
        id: months[idx],
        description: month
      });
    });
    return ret;
  }

  private encodeTarget(formData) {
    formData.target = {
      targetType: this.deploymentType
    };

    each(this.targetObjectMap, (key: string) => {
      formData.target[key] = formData[key] ? flatten([formData[key]]) : [];
    });

    this.makeArray(formData.target);

    if (formData.assetsFolders) {
      let assetsFolders: { assetID?: number; folderID?: number; }[] = [];

      try {
        assetsFolders = JSON.parse(formData.assetsFolders);
      } catch (e) {}

      formData.target.assets = map(filter(assetsFolders, 'assetID'), 'assetID');
      formData.target.folders = map(filter(assetsFolders, 'folderID'), 'folderID');
    }
    formData.target = JSON.stringify(formData.target);
  }

  private encodeAssigment(formData: IObjectStringKeyMap<any>): void {
    if (!has(formData, 'divideAssignment')) {
      formData.divideAssignment = 0;
    }
    const assignmentFieldKey = 'assignedTo_';

    each(formData, (fieldValue: any, fieldKey: string) => {
      if (includes(fieldKey, assignmentFieldKey)) {
        const splitSeparator: string = formData.divideAssignment ? `${assignmentFieldKey}group` : assignmentFieldKey;
        const splitFieldKey: string = last(split(fieldKey, splitSeparator));
        let targetKey = `assignees[0][${splitFieldKey}]`;

        if (formData.divideAssignment) {
          const [groupIndex, groupKey] = split(splitFieldKey, '_');
          targetKey = `assignees[${+groupIndex - 1}][${groupKey}]`;
        }
        if (splitFieldKey === 'anyTeam') {
          set(formData, targetKey, +fieldValue);
        } else {
          set(formData, targetKey, flatten([fieldValue]));
        }
      }
    });

    if (formData.divideAssignment) {
      const groups: ICheckGroup[] = get(this.checkService.getCheck(this.deploymentPage.checkId), 'checkGroups') || [];
      each(formData.assignees, (assignee: any, index: number) => {
        assignee.groupID = get(groups[index], 'groupID');
      });
    }

    if (isUndefined(formData.assignees)) {
      formData.assignees = [{}];
    }

    if (formData.assignees && formData.assignees.length) {
      each(formData.assignees, (item) => {
        if (this.deploymentType === 'workers') {
          each(['permissions', 'teams'], (field) => {
            if (item[field]) {
              item[field] = this.filterWorkerOptions(item[field]);
            }
          });
        }
        this.makeArray(item);
      });
      formData.assignees = JSON.stringify(formData.divideAssignment ? formData.assignees : formData.assignees[0]);
    }
  }

  private isAtLoginResponseInvalid({ frequency, atLogin, checksPerDay }: { frequency?: string, atLogin?: number, checksPerDay?: string }) {
    const frequencyValue: string = frequency || this.getFieldValue('frequency');
    const atLoginValue: number = atLogin || this.getFieldValue('atLogin', true);
    const checksPerDayValue: string = checksPerDay || this.getFieldValue('checksPerDay');

    return frequencyValue === 'onDemand' ||
      frequencyValue === 'daily' && checksPerDayValue !== '-1' ||
      !includes(['onDemand', 'daily'], frequencyValue) && atLoginValue !== 1;
  }

  private encodeFrequency(formData: any): void {
    formData.frequency = {
      type: formData.frequency,
      count: +formData.checksPerDay || 1,
      startingTime: 0,
      duration: 0,
      reminder: +formData.reminderNotification === 1,
      remindWho: formData.remindWho,
      remindWhat: formData.remindWhat,
      availableImmediately: +formData.availableImmediately || 0,
      remindWhen: +formData.remindWhen
    };

    if (formData.frequency.type === 'onDemand') {
      formData.frequency.remindWhen = 0;
    } else if (formData.frequency.type === 'once') {
      const frequencyOnceTime = formData.frequencyOnceTime ? JSON.parse(formData.frequencyOnceTime) : {};

      formData.frequency.availableAt = this.utils.toSeconds(get(frequencyOnceTime, 'startDate') || moment().valueOf());
      formData.frequency.availableUntil = this.utils.toSeconds(get(frequencyOnceTime, 'endDate') || moment().valueOf());
    }

    if (formData.frequency.type === 'daily') {
      if (formData.frequency.count === 1) {
        formData.frequency.startingTime = +formData.startingTime;
        formData.frequency.duration = +formData.duration;
      }
    }

    if (formData.frequency.type !== 'daily' && formData.frequency.type !== 'onDemand') {
      if (formData.atLogin) {
        formData.frequency.count = formData.atLoginRequiredResponse ? -2 : -1;
      }
    } else if (formData.frequency.type === 'daily') {
      if (+formData.checksPerDay === -1) {
        formData.frequency.count = formData.atLoginRequiredResponse ? -2 : -1;
      }
    }

    if (includes(['daily', 'weekly'], formData.frequency.type)) {
      formData.frequency.days = formData[formData.frequency.type];
      if (typeof (formData.frequency.days) !== 'object') {
        formData.frequency.days = [formData.frequency.days];
      }
    } else {
      formData.frequency.when = formData[formData.frequency.type];
    }

    formData.frequency = JSON.stringify(formData.frequency);
  }

  private makeArray(formFields) {
    each(formFields, (targetField, key) => {
      if (isArray(targetField)) {
        formFields[key] = map(targetField, (value) => isNaN(+value) ? value : +value);
      }
    });
  }

  private decodeTarget(formData: any, deploymentData: any) {
    const userLocations = this.getUserLocations();

    formData.type = get(deploymentData, 'target.targetType');
    assign(formData, pick(deploymentData.target, this.targetObjectMap));

    if (userLocations.length && get(formData, 'locations.length')) {
      formData.locations = intersection(userLocations, formData.locations);
    }

    formData.assetsFolders = [];
    each(deploymentData?.target?.assets, (assetID: number) => {
      formData.assetsFolders.push({ assetID });
    });
    each(deploymentData?.target?.folders, (folderID: number) => {
      formData.assetsFolders.push({ folderID });
    });
  }

  private decodeAssigment(formData: any, deploymentData: any) {
    let assignmentObject: any = get(deploymentData, 'assignedTo');

    if (has(assignmentObject, 'data')) {
      assignmentObject = JSON.parse(assignmentObject.data || null) || {};
    }

    if (assignmentObject) {
      if (isArray(assignmentObject)) {
        each(assignmentObject, (assignment, index: number) => {
          each(assignment, (ids: string[], key: string) => {
            formData[`assignedTo_group${index + 1}_${key}`] = ids;
          });
        });
        formData.divideAssignment = 1;
      } else if (isObject(assignmentObject)) {
        const requiredFieldKeys = ['permissions', 'teams', 'anyTeam'];
        const isWorker: boolean = this.deploymentType === 'workers';

        if (isWorker) {
          each(requiredFieldKeys, (fieldKey) => {
            if (!has(assignmentObject, fieldKey)) {
              if (fieldKey === 'anyTeam') {
                assignmentObject[fieldKey] = 0;
              } else {
                assignmentObject[fieldKey] = [];
              }
            }
          });
        }

        each(assignmentObject, (ids: string[], key: string) => {
          if (isWorker && includes(requiredFieldKeys, key) && ids.length === 0) {
            ids = ['-2'];
          }

          formData[`assignedTo_${key}`] = ids;
        });
      }
    }
  }

  private decodeFrequency(formData: any, deploymentData: any) {
    const frequency = get(deploymentData, 'frequency') || {};

    formData.frequency = frequency.type;
    formData.checksPerDay = frequency.count;
    formData.reminderNotification = frequency.reminder ? 1 : 0;
    formData.remindWhen = frequency.remindWhen;
    formData.remindWhat = frequency.remindWhat;
    formData.remindWho = frequency.remindWho;

    if (!formData.remindWho) {
      formData.remindWho = [-1];
    }

    if (includes(['daily', 'weekly'], formData.frequency)) {
      formData[formData.frequency] = frequency.days;
    } else if (formData.frequency === 'once') {
      const frequencyOnceTimeField = find(this.fields, {name: 'frequencyOnceTime'});

      formData.frequencyOnceTime = {
        startDate: this.utils.toMilliseconds(frequency.availableAt),
        endDate: this.utils.toMilliseconds(frequency.availableUntil)
      };
      (<any>frequencyOnceTimeField).inputs.dateRange = formData.frequencyOnceTime;
    } else {
      formData[formData.frequency] = frequency.when;
    }

    if (frequency.type === 'daily') {
      if (has(frequency, 'startingTime')) {
        formData.startingTime = frequency.startingTime;
        formData.duration = frequency.duration;
      } else {
        formData.startingTime = 0;
        formData.duration = 0;
      }
    }
    if (frequency.type !== 'onDemand') {
      if (frequency.count < 0) {
        formData.atLogin = 1;

        if (frequency.count === -2) {
          formData.atLoginRequiredResponse = 1;
        }
      } else {
        formData.atLogin = 0;
      }
    }
  }

  private addAssignmentGroups() {
    const groups: ICheckGroup[] = get(this.checkService.getCheck(this.deploymentPage.checkId), 'checkGroups') || [];
    const groupFields = map(groups, (group: ICheckGroup, index: number) => {
      index++;

      return [
        {
          containerClass: 'deploy-field assets zones workers assign-field-group start-group',
          title: group.title || `${this.translate.instant('MGMT_DETAILS.Group')} ${index}`,
          type: 'subtitle'
        },
        {
          containerClass: 'deploy-field assets zones workers assign-field-group',
          title: 'SHARED.Team(s)',
          name: `assignedTo_group${index}_teams`,
          type: 'selectmenu',
          placeholder: this.translate.instant('SHARED.Any_Team'),
          multiple: true,
          required: false,
          searchable: true,
          canClear: true,
          valueProperty: 'groupID',
          originalOrder: true,
          options: this.getTeamList(this.getUserLocations()),
          test: (team: any) => !get(team, 'disabledAt'),
          func: (team: any) => this.teamsService.teamName(team),
          onChange: () => this.syncUsersField(true, index)
        },
        {
          containerClass: 'deploy-field assets zones workers assign-field-group',
          title: 'SHARED.EDIT_Permissions',
          name: `assignedTo_group${index}_permissions`,
          type: 'selectmenu',
          placeholder: this.translate.instant('SHARED.Any_Permissions'),
          multiple: true,
          required: false,
          searchable: true,
          canClear: true,
          valueProperty: 'id',
          options: this.permissionsService.permissions.data,
          onChange: () => this.syncUsersField(true, index),
          test: (ref) => this.filterPermission(ref)
        },
        // {
        //   containerClass: 'deploy-field assets zones workers assign-field-group',
        //   name: `assignedTo_group${index}_certifications`,
        //   title: 'SHARED.Certification(s)',
        //   placeholder: this.translate.instant('SHARED.Any_Certification'),
        //   type: 'selectmenu',
        //   multiple: true,
        //   valueProperty: 'certificationID',
        //   options: this.certificationsService.certifications,
        //   test: (ref) => !get(ref, 'disabledAt'),
        //   func: (certification: any) => this.formBuilderService.certificationName(certification)
        // },
        // {
        //   containerClass: 'deploy-field assets zones workers assign-field-group',
        //   name: `assignedTo_group${index}_roles`,
        //   title: 'MGMT_DETAILS.Roles',
        //   type: 'selectmenu',
        //   placeholder: this.translate.instant('MGMT_DETAILS.Any_Role'),
        //   multiple: true,
        //   valueProperty: 'roleID',
        //   options: this.roleService.roles,
        //   test: (ref) => !get(ref, 'disabledAt'),
        //   func: (role: any) => this.roleService.roleName(role),
        //   onChange: () => this.syncUsersField(true, index)
        // },
        {
          containerClass: 'deploy-field assets zones workers assign-field-group end-group',
          title: 'SHARED.User(s)',
          name: `assignedTo_group${index}_users`,
          type: 'selectmenu',
          multiple: true,
          placeholder: this.translate.instant('SHARED.Any_User'),
          valueProperty: 'userID',
          originalOrder: true,
          options: this.getUserList(this.getUserLocations()),
          func: (ref) => this.getUserName(ref),
          test: (ref) => !get(ref, 'disabledAt'),
          onChange: (values) => null
        }
      ];
    });
    const index = findIndex(this.fields, {name: 'divideAssignment'}) + 1;

    if (groupFields.length) {
      this.fields = [
        ...this.fields.slice(0, index),
        ...flatten(groupFields),
        ...this.fields.slice(index)
      ];
    }
  }

  private checkRequiredAllGroupsField(): void {
    const checkGroups = get(this.checkService.getCheck(this.deploymentPage.checkId), 'checkGroups') || [];

    if (checkGroups.length < 2) {
      const formPrefix: string = (<any>this.deploymentPage).formConfig.prefix;
      const groupsFieldSelector = `#${formPrefix}requireAllGroups_container`;
      const divideAssignmentFieldSelector = `#${formPrefix}divideAssignment_container`;

      this.nativeElementService.setElementVisibilityBy(this.nativeElement, groupsFieldSelector, false);
      this.nativeElementService.setElementVisibilityBy(this.nativeElement, divideAssignmentFieldSelector, false);
    }
  }

  private onLocationChange(locationIds: any[] = []) {
    locationIds = isEmpty(locationIds) ? this.getUserLocations() : map(locationIds, Number);

    if (this.deploymentType === 'assets') {
      this.updateFieldByName('zones', this.zoneService.buildZoneList(locationIds, null, false));
    } else if (this.deploymentType === 'zones') {
      const items = this.zoneService.buildZoneList(locationIds, null, false);
      items.unshift({id: -1, description: this.translate.instant('SHARED.All_Zones')});
      this.updateFieldByName('zones', items);
    } else if (this.deploymentType === 'workers') {
      this.updateFieldByName('teams', this.getTeamList(locationIds));
      this.syncUsersField();
      this.syncRemindWho();
    }

    this.updateAssignFieldsData();
  }

  private async updateAssignFieldsData() {
    let locations: number[] = map(<any>this.getFieldValue('locations'), Number) || [];
    if (isEmpty(locations)) {
      locations = this.getUserLocations();
    }

    await this.loadingService.enable();
    const divideAssignmentField: any = find(this.fields, {name: 'divideAssignment'});
    if (divideAssignmentField && divideAssignmentField.getValue()) {
      this.updateAssignmentGroups();
    } else {
      this.updateFieldByName('teams', this.getTeamList(locations));
      this.updateFieldByName('assignedTo_teams', this.getTeamList(locations));
      this.syncUsersField(true);
    }
    await this.loadingService.disable();
  }

  private syncAssetsField() {
    const locations: number[] = map(<any>this.getFieldValue('locations'), Number) || [];
    let zones: any = this.getFieldValue('zones') || [];

    zones = map(zones, (zone: string) => {
      if (isNaN(+zone)) {
        return +last(split(zone, ':'));
      } else {
        return +zone;
      }
    });

    const options = filter(this.getAssetsByCurrentLocations(), (asset: any) => {
      const isValidByLocation: boolean = includes(locations, asset.location) || isEmpty(locations);
      const isValidByZone: boolean = includes(zones, asset.zone) || isEmpty(zones);

      return isValidByLocation && isValidByZone;
    });

    return options;
  }

  private resetAssetsFolders() {
    const assetFoldersField = find(this.fields, { name: 'assetsFolders' }) as FormField;
    const folderPickerComponent = assetFoldersField?.componentRef as FolderPickerComponent;

    if (folderPickerComponent) {
      folderPickerComponent.resetSelection();
    }
  }

  private syncUsersField(isAssignBlock: boolean = false, index?: number) {
    let fieldPrefix = 'assignedTo_';
    if (!isUndefined(index)) {
      fieldPrefix += `group${index}_`;
    }

    const fieldNames = {
      teams: isAssignBlock ? `${fieldPrefix}teams` : 'teams',
      permissions: isAssignBlock ? `${fieldPrefix}permissions` : 'permissions',
      users: isAssignBlock ? `${fieldPrefix}users` : 'users'
    };

    let locations: number[] = map(<any>this.getFieldValue('locations'), Number) || [];
    let teams: number[] = map(<any>this.getFieldValue(fieldNames.teams), Number) || [];

    if (isEmpty(locations)) {
      locations = this.getUserLocations();
    }

    if (includes(teams, -1)) {
      const userIds: number[] = map(<any>this.getFieldValue('users'), Number);
      const primaryGroups: number[] = uniq(map(userIds, (userId: number) => get(this.accountService.getByID(userId), 'primaryGroup')));
      teams = [...teams, ...primaryGroups];
    }

    let permissions: string[] = (<string[]>this.getFieldValue(fieldNames.permissions)) || [];

    if (this.deploymentType === 'workers') {
      permissions = <string[]>this.filterWorkerOptions(permissions);
      teams = <number[]>this.filterWorkerOptions(teams);
    }

    const users: any[] = filter(this.getUserList(locations), (user) => {
      const userPermissions: string[] = keys(user.permissions);
      const isPermissionEmpty: boolean = isEmpty(userPermissions) || isEmpty(permissions);
      const isValidByTeam: boolean = intersection(user.groups, teams).length > 0 || isEmpty(user.groups) || isEmpty(teams);
      const isValidByPermission: boolean = intersection(userPermissions, permissions).length > 0 || isPermissionEmpty;

      return isValidByTeam && isValidByPermission;
    });

    this.updateFieldByName(fieldNames.users, users);
  }

  private updateFieldByName(fieldName: string, options: any[]) {
    const fieldId = `#${(<any>this.deploymentPage).formConfig.prefix}${fieldName}`;
    const fieldConfig: any = find(this.fields, {name: fieldName});
    const fieldSelectedValue: any = this.getFieldValue(fieldName);

    fieldConfig.options = options;
    this.formBuilderService.replaceSelectOptionsWith(fieldId, (<any>this.deploymentPage).formConfig, fieldConfig, {[fieldName]: fieldSelectedValue});
  }

  private syncRemindWho() {
    // are there any explicit user assignments
    const explicit: boolean = (<string[]>this.getFieldValue('assignedTo_users') || []).length > 0;

    const options = this.getNotificationList(this.getUserLocations(), explicit);
    this.updateFieldByName('remindWho', options);
  }

  private getFieldValue(name: string, extractFromField: boolean = false): any {
    const prefix: string = get(this.deploymentPage, 'formConfig.prefix');
    const fieldId = `#${prefix}${name}`;
    if (extractFromField) {
      const remindWhat: any = find(this.fields, {name});
      if (remindWhat && remindWhat.getValue) {
        return remindWhat.getValue();
      }
    }
    return $(fieldId).val();
  }

  private onDivideAssignmentGroupsChange() {
    const divideAssignmentField: any = find(this.fields, {name: 'divideAssignment'});

    if (divideAssignmentField && divideAssignmentField.getValue) {
      const divideAssignmentValue: any = divideAssignmentField.getValue();

      this.formBuilderService.setElementVisibilityBy(this.nativeElement, '.assign-field', !divideAssignmentValue);
      this.formBuilderService.setElementVisibilityBy(this.nativeElement, '.assign-field-group', divideAssignmentValue);
      this.updateAssignFieldsData();
    } else {
      this.formBuilderService.setElementVisibilityBy(this.nativeElement, '.assign-field', true);
      this.formBuilderService.setElementVisibilityBy(this.nativeElement, '.assign-field-group', false);
      this.updateAssignFieldsData();
    }
  }

  private updateAssignmentGroups() {
    let locationIds: number[] = map(<any>this.getFieldValue('locations'), Number) || [];
    if (isEmpty(locationIds)) {
      locationIds = this.getUserLocations();
    }
    const teams: any[] = this.getTeamList(locationIds);
    const checkGroups = get(this.checkService.getCheck(this.deploymentPage.checkId), 'checkGroups') || [];

    each(checkGroups, (checkGroup, index: number) => {
      index++;
      this.updateFieldByName(`assignedTo_group${index}_teams`, teams);
      this.syncUsersField(true, index);
    });
  }

  private onFrequencyChange() {
    const value: string = <string>this.getFieldValue('frequency');
    this.formBuilderService.setElementVisibilityBy(this.nativeElement, '.frequency-field', false);
    this.formBuilderService.setElementVisibilityBy(this.nativeElement, `.${this.deploymentType}.frequency-field.${value}`, true);
    this.onSendReminderChange(value);
  }

  private definePresentationPreviewControl() {
    const buttonTitle: string = this.translate.instant('SHARED.Preview');
    this.nativeElement
      .querySelector('.presentation-type-field')
      .insertAdjacentHTML('beforeend', '<ion-button class="page-button" color="light">${buttonTitle}</ion-button>');

    this.nativeElement.querySelector('.presentation-type-field .page-button').addEventListener('click', () => {
    });
  }

  private defineCompletedCheckField() {
    const element = `
      <div class="completed-check-title-block">
        <div class="title">${this.translate.instant('MGMT_DETAILS.Action')}</div>
        <div class="title">${this.translate.instant('MGMT_DETAILS.Point_Value')}</div>
      </div>
    `;

    this.nativeElement
      .querySelector('.completed-check-field')
      .insertAdjacentHTML('beforebegin', element);
  }

  private onTimesPerDayChange(value: any = 1) {
    this.formBuilderService.disableFieldByName(this.nativeElement, 'startingTime', (+value !== 1));
    this.formBuilderService.disableFieldByName(this.nativeElement, 'duration', (+value !== 1));
    this.formBuilderService.disableFieldByName(this.nativeElement, 'availableImmediately', (+value !== 1));
  }

  private updateRemindWhatField(opts: any) {
    const theField: any = find(this.fields, {name: 'remindWhat'});

    if (theField && theField.getValue) {
      const otherField: any = find(this.fields, {name: 'reminderNotification'});
      let reminderValue = false;
      if (otherField && otherField.getValue) {
        reminderValue = otherField.getValue();
      }
      const data = {
        remindWhat: theField.getValue()
      };

      const field = <any>find((<any>this.deploymentPage).formConfig.fields, {name: 'remindWhat'});
      if (field) {
        field.options = opts;
        field.disabled = false;
        field.required = true;
      }
      if (!reminderValue) {
        field.disabled = true;
        field.required = false;
      }
      this.formBuilderService.updateFields((<any>this.deploymentPage).formConfig, [field], data);
    }
  }

  private onSendReminderChange(frequency?: string) {
    // rebuild the current field based upon the frequency
    if (frequency !== undefined) {
      const opts = [];
      each(this.reminderTriggers, ref => {
        if (has(ref, 'exclude') && indexOf(ref.exclude, frequency) > -1) {
          // skip this item
          this.logger.debug('leaving out ' + ref.id);
        } else {
          opts.push(ref);
        }
      });
      this.updateRemindWhatField(opts);
    }

    const theField: any = find(this.fields, {name: 'reminderNotification'});
    if (theField && theField.getValue) {
      const theValue = theField.getValue();
      this.formBuilderService.disableFieldByName(this.nativeElement, 'remindWhat', !theValue);
      this.formBuilderService.disableFieldByName(this.nativeElement, 'remindWhen', !theValue);
      this.formBuilderService.disableFieldByName(this.nativeElement, 'remindWho', !theValue);
      if (theValue) {
        // notifications are enabled, what about the expiring window?
        this.onTriggerChange();
      }
    }
  }

  private onTriggerChange(values: any = [], theForm?: any) {
    const sels = this.getFieldValue('remindWhat', true);
    if (indexOf(sels, 'expiring') > -1) {
      this.formBuilderService.disableFieldByName(this.nativeElement, 'remindWhen', false);
    } else {
      this.formBuilderService.disableFieldByName(this.nativeElement, 'remindWhen', true);
    }
  }

  private onCheckHistoryChange() {
    const divideAssignmentField: any = find(this.fields, {name: 'showCheckHistory'});
    if (divideAssignmentField && divideAssignmentField.getValue) {
      this.formBuilderService.disableFieldByName(this.nativeElement, 'previousChecks', !divideAssignmentField.getValue());
    }
  }

  private getUserList(locations: number[], includeSpecials: boolean = false): any[] {
    const users = this.accountService.getUserlist(locations);

    if (this.deploymentType === 'workers' || includeSpecials) {
      users.unshift(
        this.accountService.createAccountObject({
          userID: -1,
          firstname: this.translate.instant('MGMT_DETAILS.Self_Check')
        }),
        this.accountService.createAccountObject({
          userID: -2,
          firstname: this.translate.instant('MGMT_DETAILS.User_Supervisor')
        })
      );
    }

    return users;
  }

  private getNotificationList(locations: number[], includeSpecials: boolean = false): any[] {
    const users = this.accountService.getUserlist(locations);

    if (includeSpecials) {
      users.unshift(
        this.accountService.createAccountObject({
          userID: -1,
          firstname: this.translate.instant('MGMT_DETAILS.Assignees')
        }),
        this.accountService.createAccountObject({
          userID: -2,
          firstname: this.translate.instant('MGMT_DETAILS.Assignee_Supervisors')
        })
      );
    }
    return users;
  }

  private getTeamList(locations: number[]): any[] {
    const teams: any[] = orderBy(this.teamsService.getTeamList(locations), 'name');
    if (this.deploymentType === 'workers') {

      teams.unshift(
        {groupID: -2, name: this.translate.instant('SHARED.Any_Team')},
        {groupID: -1, name: this.translate.instant('MGMT_DETAILS.Workers_Primary_Team')}
      );
    }

    return teams;
  }

  private getUserName(userData: any): string {
    let name = this.userService.getFullname(userData.userID);
    if (userData.userID === -1 || (name === 'unknown' && userData.firstname)) {
      name = userData.firstname;
    }
    return name;
  }

  private getChecksCountOptions() {
    const options: any[] = times(24, (currentNumber: number) => {
      const id: string = (currentNumber + 1).toString();
      return {id, description: id};
    });

    options.unshift({id: -1, description: this.translate.instant('MGMT_DETAILS.At_Login')});

    return options;
  }

  private getStartingTimes() {
    const options: any[] = times(24, (currentNumber: number) => {
      let id: string = (currentNumber).toString();
      if (currentNumber === 0) {
        id = this.translate.instant('MGMT_DETAILS.Midnight');
      } else if (currentNumber === 12) {
        id = this.translate.instant('MGMT_DETAILS.Noon');
      }
      return {id: currentNumber, description: id};
    });

    return options;
  }

  public getDurations(addSuffix: boolean = false) {
    const options: any[] = times(23, (currentNumber: number) => {
      currentNumber++;
      let desc: string = (currentNumber).toString();
      if (currentNumber === 1) {
        if (addSuffix) {
          desc = this.translate.instant('MGMT_DETAILS.1_Hour_Before');
        } else {
          desc = this.translate.instant('SHARED.1_HOUR');
        }
      } else {
        if (addSuffix) {
          desc += ' ' + this.translate.instant('MGMT_DETAILS.Hours_Before');
        } else {
          desc += ' ' + this.translate.instant('SHARED.Hours');
        }
      }
      return {id: currentNumber, description: desc};
    });

    options.push({
      id: 0,
      description: '24 ' + this.translate.instant('SHARED.Hours')
    });

    return options;
  }

  private filterPermission(permission): boolean {
    permission.description = this.translate.instant(permission.description);

    if (permission.id === 'corvex') {
      return get(this.userDataService.Permissions, 'corvex');
    } else if (permission.id === 'sadmin') {
      return get(this.userDataService.Permissions, 'sadmin') || get(this.userDataService.Permissions, 'corvex');
    } else {
      return true;
    }
  }

  private updateZonesField(): void {
    const zonesField = find(this.fields, {name: 'zones'});

    if (zonesField) {
      const isAllZonesAdded = find(zonesField.options, { id: -1 });

      if (!isAllZonesAdded) {
        zonesField.originalOrder = true;
        zonesField.options.unshift({id: -1, description: this.translate.instant('SHARED.All_Zones')});
      }

      zonesField.required = true;
      zonesField.placeholder = this.translate.instant('SHARED.Choose_Zones');

      this.updateFieldByName('zones', zonesField.options);
    }
  }

  private updateFieldLocationData(): void {
    const locations: number[] = this.getUserLocations();

    const userTeamsOptions = this.getTeamList(locations);
    const userListOptions = this.getUserList(locations);
    this.setFieldOptions('locations', this.userService.getUserLocations(locations, false));
    this.setFieldOptions('teams', userTeamsOptions);
    this.setFieldOptions('assignedTo_teams', userTeamsOptions);
    this.setFieldOptions('users', userListOptions);
    this.setFieldOptions('assignedTo_users', userListOptions);
    this.setFieldOptions('zones', this.zoneService.getGroupedZonesByLocations(locations));
    this.syncRemindWho();
  }

  private setFieldOptions(name: string, options: any[]) {
    const field = find(this.fields, {name});

    if (field) {
      field.options = options;
    }
  }

  private getUserLocations(): number[] {
    const check: ICheck = this.checkService.getCheck(get(this.deploymentPage, 'checkId'));
    return get(check, 'locations.length') ? map(check.locations, Number) : this.userDataService.locations;
  }

  private getAssetsByCurrentLocations() {
    const locations: number[] = this.getUserLocations();
    return this.assetsService.getAssetsByLocations(locations);
  }

  private checkFrequencyField() {
    const check = this.checkService.getCheck(get(this.deploymentPage, 'checkId'));

    if (get(check, 'checkGroups.length') === 1) {
      const frequencyField = find(this.fields, {name: 'frequency'});

      if (frequencyField) {
        frequencyField.options.push({
          id: 'onDemand',
          description: this.translate.instant('MGMT_DETAILS.On_Demand_Option')
        });
      }
    }
  }

  private prepareWorkerFields() {
    const fields = [];
    const data = (<any>this.deploymentPage).getData();
    const fieldsMap: { [fieldKey: string]: string } = {
      assignedTo_teams: this.translate.instant('MGMT_DETAILS.Choose_Team'),
      assignedTo_permissions: this.translate.instant('MGMT_DETAILS.Choose_Permission'),
      assignedTo_users: this.translate.instant('SHARED.Any_User')
    };

    each(fieldsMap, (placeholder, name) => {
      const field = <any>find((<any>this.deploymentPage).formConfig.fields, {name});
      field.required = name === 'assignedTo_users' ? false : true;
      field.placeholder = placeholder;

      if (name === 'assignedTo_permissions') {
        const values = data.assignedTo_permissions || [];

        field.options = [
          {
            id: -2,
            description: this.translate.instant('SHARED.Any_Permission'),
            type: 'boolean',
            disabled: values.length && !includes(values, '-2')
          },
          ...orderBy(this.permissionsService.permissions.data, 'description')
        ];
        field.originalOrder = true;
      }
      fields.push(field);
    });
    this.formBuilderService.updateFields((<any>this.deploymentPage).formConfig, fields, data);
  }

  private removeWorkerAssignAnyOption(ids: string[], fieldName): void {
    const anyOptionId = '-2';

    if (this.deploymentType === 'workers') {
      const fieldId = `#${(<any>this.deploymentPage).formConfig.prefix}${fieldName}`;
      const optionElement = $(`${fieldId} option[value='${anyOptionId}']`);
      const selected = optionElement.data('data') || {};

      if (includes(ids, anyOptionId)) {
        if (ids.length > 1) {
          const values = <string[]>this.filterWorkerOptions(ids);
          $(fieldId).val(values).trigger('change');
          selected.disabled = true;
        }
      } else if (ids.length === 0) {
        optionElement.prop('disabled', false);
        selected.disabled = false;
        $(fieldId).trigger('select2:select');
      } else {
        selected.disabled = true;
      }
    }
  }

  private filterWorkerOptions(options: string[] | number[]): string[] | number[] {
    return <string[] | number[]>reject(options, (option) => +option === -2);
  }

}
